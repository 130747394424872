import { storage } from "utils/api.interfaces";
import axiosinterceptor from "../utils/api.interceptor";

export const logOut = (): Promise<any> => {
  return axiosinterceptor.get(`/v1/Security/Logout`).then((res) => {
    storage.clearToken();
    return res.data;
  });
};
export const getVotesExport = (): Promise<any> => {
  return axiosinterceptor
    .get(`/v1/FootballMatches/votes/report`)
    .then((res) => {
      return res.data;
    });
};

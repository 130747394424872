import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import BallotIcon from "@mui/icons-material/Ballot";
import { useNavigate } from "react-router-dom";
import {
  OrganizationIcon,
  MatchIcon,
  TeamIcon,
  UserIcon,
  LeagueIcon,
} from "./brandlibrary/MingleIcons";

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  top: "5px",
  right: "5px",
}));

const actions = [
  { icon: <LeagueIcon />, name: "Add League", path: "leagues" },
  {
    icon: <OrganizationIcon />,
    name: "Add Organization",
    path: "organizations",
  },
  { icon: <TeamIcon />, name: "Add Team", path: "teams" },
  { icon: <UserIcon />, name: "Add User", path: "users" },
  { icon: <MatchIcon />, name: "Add Match", path: "match" },
  { icon: <BallotIcon />, name: "Add Activity", path: "activity" },
];
const AddSpeedDial: React.FC<any> = ({}) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ position: "relative", marginLeft: "auto" }}>
      <StyledSpeedDial
        ariaLabel="SpeedDial playground example"
        icon={<SpeedDialIcon />}
        direction="down"
        color="success"
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => navigate(`/${action.path}/add`)}
          />
        ))}
      </StyledSpeedDial>
    </Box>
  );
};
export default AddSpeedDial;

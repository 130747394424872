import { Avatar, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import * as React from "react";
import { useOrganization } from "../hooks/useOrganization";
import { useLeague } from "../hooks/useLeague";

interface ButtonOrganizationProps {
  leagueId?: string;
}
const ButtonLeague: React.FC<ButtonOrganizationProps> = ({ leagueId }) => {
  const navigate = useNavigate();

  const { dataLeague } = useLeague(leagueId);
  return (
    <Button
      component={Link}
      color={"primary"}
      to={`/leagues/${dataLeague?.id}`}
      sx={{
        cursor: "pointer",
        fontSize: "12px",
        height: "42px",
        whiteSpace: "nowrap",
      }}
      size="small"
      startIcon={
        <Avatar
          sx={{
            backgroundColor: "#fff",
            marginRight: "6px",
            width: "35px",
            height: "35px",
          }}
          src={dataLeague?.logoUrl}
        ></Avatar>
      }
      // endIcon={<OrganizationIcon />}
      variant="outlined"
    >
      {dataLeague?.name}
    </Button>
  );
};

export default ButtonLeague;

import * as React from "react";

import {
  Avatar,
  Button,
  Card,
  CardActions,
  Checkbox,
  Chip,
  CircularProgress,
  IconButton,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";

import CardContent from "@mui/material/CardContent/CardContent";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import EditIcon from "@mui/icons-material/Edit";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
// @ts-ignore
import JWPlayer from "@jwplayer/jwplayerReact";
import ReactHlsPlayer from "@gumlet/react-hls-player";
import { useCallback, useEffect, useState } from "react";
import NoVideo from "images/test.jpg";
import VerifyGoal from "../components/VerifyGoal";
import useMiniGamesAttempts from "../hooks/useMiniGameAttempts";
import useMiniGamesAttempt, {
  postNoGoal,
  putGoalVerify,
} from "hooks/useMiniGameAttempt";
import useKeyPress from "../hooks/useKeyPress";
import ReactJson from "react-json-view";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import useTeam from "../hooks/useTeam";
import { BooleanCheck, Transition } from "../utils/common";
import { useFormik } from "formik";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { rowsPerpageOptions } from "../components/Pagination";
import { MiniGamesStatusEnum } from "../utils/api.interfaces";
import MiniGameStatus from "../components/MiniGamesStatus";

export interface NoGoalVerifyProps {
  attemptId: string;
  sessionId: string;
  validatorComment: string;
}
export interface GoalVerifyProps {
  attemptId: string;
  sessionId: string;
  score: {
    speed: number;
    accuracy: number;
    points: number;
    goalPosition: {
      x: number;
      y: number;
    };
    shotFrame: number;
  };
  validatorComment: string;
}
export default function MiniGamesAttemptVerify() {
  const navigate = useNavigate();
  const { sessionId, attemptId } = useParams();

  const { dataMiniGamesAttempt } = useMiniGamesAttempt({
    sessionId,
    attemptId,
  });
  const { dataMiniGamesAttempts } = useMiniGamesAttempts({
    sessionId: sessionId || "",
    page: "0",
    pageSize: rowsPerpageOptions[4].toString(),
  });
  console.log(dataMiniGamesAttempts);
  const extraDataAttempt = dataMiniGamesAttempts?.attempts.find(
    (id) => id.id === attemptId
  );
  console.log(extraDataAttempt);
  const [inputCurrentTime, setInputCurrentTime] = useState<any>(0);
  const [speedVideo, setSpeedVideo] = useState<any>(1);
  const [points, setPoints] = useState<any>(1);
  const [accuracy, setAccuracy] = useState<any>(1);
  const [balPosition, setBalPosition] = useState<any>({ x: "0", y: "0" });
  const [startTime, setStartTime] = useState<any>(0);
  const [startFrameNumber, setStartStartFrameNumber] = useState<any>(0);
  const [currentFrameNumber, setCurrentFrameNumber] = useState<any>(0);
  const [isGoal, setIsGoal] = useState<boolean>(true);
  const [startTimeWithSeek, setStartTimeWithSeek] = useState<boolean>(true);
  const [endTime, setEndTime] = useState<any>(0);
  const [endTimeWithSeek, setEndTimeWithSeek] = useState<boolean>(false);
  const [balSpeed, setBalSpeed] = useState<any>(0);

  const [newVerifyRequest, setNewVerifyRequest] = useState<boolean>(false);
  const [noGoalRequest, setNoGoalRequest] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [activeKey, setActiveKey] = React.useState("");
  const ArrowLeftPressed = useKeyPress("ArrowLeft");
  const ArrowRightPressed = useKeyPress("ArrowRight");
  const ShiftKeyPressed = useKeyPress("Shift");

  const playerRef = React.useRef() as React.RefObject<HTMLVideoElement>;
  const hlsConfig = {
    autoStartLoad: true,
    debug: true,
    playbackRate: 0.1,
  };
  const checkBuffer = () => {
    if (playerRef.current) {
      setInputCurrentTime(playerRef.current.currentTime.toFixed(2));
      setCurrentFrameNumber(
        Math.round(playerRef.current.currentTime / 0.03333)
      );
      return playerRef.current.currentTime;
    }
  };
  const calculateSpeed = () => {
    const elapsedTime = endTime - startTime;

    if (elapsedTime > 0) {
      setBalSpeed((Math.round((((11 / elapsedTime) * 3.6) / 10) * 2) * 10) / 2);
    }
  };
  const initGoal: GoalVerifyProps = {
    attemptId: attemptId || "",
    sessionId: sessionId || "",
    score: {
      speed: Number(balSpeed) || 0,
      accuracy: Number(accuracy) || 0,
      points: Number(points) || 0,
      goalPosition: {
        x: Number(balPosition.x) || 0,
        y: Number(balPosition.y) || 0,
      },
      shotFrame: Number(startFrameNumber) || 0,
    },
    validatorComment: "",
  };
  const PlayerButton: React.FC<any> = ({ seekSpeed, children }) => {
    const player = playerRef.current;
    return (
      <Button
        onClick={(e) => {
          if (player) {
            player?.pause();
            player.currentTime = player.currentTime + seekSpeed;
            if (startTimeWithSeek) {
              setStartTime(player.currentTime.toFixed(2));
            }
            if (endTimeWithSeek) {
              setEndTime(player.currentTime.toFixed(2));
            }
          }
        }}
        size="small"
        variant="text"
        color={
          endTimeWithSeek
            ? "secondary"
            : startTimeWithSeek
            ? "warning"
            : "primary"
        }
        sx={{
          color: !endTimeWithSeek && !startTimeWithSeek ? "#fff" : "",
        }}
      >
        {children}
      </Button>
    );
  };
  const formik = useFormik({
    initialValues: initGoal,
    enableReinitialize: true,
    onSubmit: (values) => {
      setIsLoading(true);
      console.log(values);
      if (isGoal) {
        putGoalVerify(values)
          .then((res) => {
            setIsLoading(false);
            setNewVerifyRequest(true);
          })
          .catch((e: any) => {
            setIsLoading(false);
          });
      } else {
        console.log(values);
        postNoGoal({
          attemptId: attemptId || "",
          sessionId: sessionId || "",
          validatorComment: values.validatorComment,
        })
          .then((res) => {
            setIsLoading(false);
            setNoGoalRequest(true);
          })
          .catch((e: any) => {
            setIsLoading(false);
          });
      }
    },
  });
  const handleIsGoalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    playerRef?.current?.pause();
    if (!BooleanCheck((event.target as HTMLInputElement).value)) {
      setStartTimeWithSeek(false);
      setEndTimeWithSeek(false);
    }
    setIsGoal(BooleanCheck((event.target as HTMLInputElement).value));
  };

  useEffect(() => {
    const speed = ShiftKeyPressed ? 0.1 : 0.033333333;
    const player = playerRef.current;

    if (player && ArrowLeftPressed) {
      console.log({ ArrowLeftPressed });
      console.log(speed);
      player?.pause();
      player.currentTime = player.currentTime - speed;
    }
  }, [ArrowLeftPressed]);
  useEffect(() => {
    const speed = ShiftKeyPressed ? 0.1 : 0.033333333;
    const player = playerRef.current;

    if (player && ArrowRightPressed) {
      console.log({ ArrowRightPressed });
      console.log(speed);
      player?.pause();
      player.currentTime = player.currentTime + speed;
    }
  }, [ArrowRightPressed]);
  useEffect(() => {
    setPoints(accuracy + balSpeed);
  }, [accuracy, balSpeed]);
  useEffect(() => {
    if (playerRef.current) {
      calculateSpeed();
      setStartStartFrameNumber(Math.round(startTime / 0.03333));
    }
  }, [startTime, endTime]);
  useEffect(() => {
    if (playerRef.current) {
      const player = playerRef.current;
      if (startTimeWithSeek) {
        setStartTime(player?.currentTime.toFixed(2));
      }
      if (endTimeWithSeek) {
        setEndTime(player?.currentTime.toFixed(2));
      }
    }
  }, [inputCurrentTime]);
  useEffect(() => {
    let interval: any;
    console.log(playerRef.current);
    function fireOnVideoStart() {
      // console.log("start");
      interval = setInterval(checkBuffer, 100);
    }
    function videoPlaying(e: any) {
      // console.log("playing");
      setIsPlaying(true);
    }
    function videoSeeked(e: any) {
      const player = playerRef.current;
      console.log("videoSeeked");
      setIsPlaying(false);
      if (playerRef?.current?.currentTime) {
        const currentTime = playerRef.current.currentTime.toFixed(2);
        setInputCurrentTime(currentTime);
        setCurrentFrameNumber(
          Math.round(playerRef.current.currentTime / 0.03333)
        );
      }
    }
    function fireOnVideoPause(e: any) {
      // console.log("pause");
      setIsPlaying(false);

      clearInterval(interval);
    }
    if (playerRef.current) {
      playerRef.current.addEventListener("play", fireOnVideoStart);
      playerRef.current.addEventListener("pause", fireOnVideoPause);
      playerRef.current.addEventListener("playing", videoPlaying);
      playerRef.current.addEventListener("seeked", videoSeeked);
      return () => {
        playerRef.current?.removeEventListener("play", fireOnVideoStart);
        playerRef.current?.removeEventListener("pause", fireOnVideoPause);
        playerRef.current?.removeEventListener("playing", videoPlaying);
        playerRef.current?.removeEventListener("seeked", videoSeeked);
      };
    }
  }, [playerRef.current]);
  const [isLoading, setIsLoading] = React.useState<Boolean>(false);

  return (
    <div style={{ padding: "20px" }}>
      <Button
        size="small"
        variant="outlined"
        onClick={() => navigate(-1)}
        startIcon={<ChevronLeftIcon />}
      >
        back
      </Button>
      <Typography
        component="h1"
        variant="h1"
        color="inherit"
        noWrap
        sx={{
          textAlign: "center",
          margin: "40px 0px 0",
        }}
      >
        Verify attempt round {extraDataAttempt?.round}
      </Typography>
      <Typography
        component="h3"
        variant="body1"
        color="inherit"
        noWrap
        gutterBottom
        sx={{
          textAlign: "center",
          margin: "0px 0 30px",
        }}
      >
        <MiniGameStatus status={extraDataAttempt?.penaltyStatus} />

        <strong>
          {extraDataAttempt?.user?.firstName} {extraDataAttempt?.user?.lastName}
        </strong>
        <br />
        {dataMiniGamesAttempt && (
          <>
            {new Date(dataMiniGamesAttempt?.dateTimeUtc).toLocaleDateString(
              [],
              {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }
            )}
          </>
        )}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          spacing={3}
          sx={{
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
            margin: "auto",
          }}
        >
          <Card sx={{ width: "100%", margin: "auto" }}>
            <CardContent>
              <Stack
                spacing={2}
                justifyContent="center"
                alignItems="space-between"
                direction="row"
                sx={{ width: "100%" }}
              >
                <Stack
                  spacing={0}
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: "80%", maxWidth: "1920px" }}
                >
                  <Stack
                    direction={"row"}
                    spacing={4}
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      width: "100%",
                      maxWidth: "1920px",
                      position: "relative",
                    }}
                  >
                    {dataMiniGamesAttempt?.attemptScore?.video?.videoUrl ? (
                      <>
                        <ReactHlsPlayer
                          playerRef={playerRef}
                          src={
                            dataMiniGamesAttempt?.attemptScore?.video
                              ?.videoUrl || ""
                          }
                          autoPlay={false}
                          controls={true}
                          muted={true}
                          // @ts-ignore
                          // hlsConfig={hlsConfig}
                          width="100%"
                          // height="auto"
                        />
                        <Stack
                          justifyContent={"space-between"}
                          direction="row"
                          alignItems={"center"}
                          style={{
                            position: "absolute",
                            right: "20px",
                            padding: "10px",
                            borderRadius: "10px",
                            top: "130px",
                            color: "white",
                            width: "145px",
                            backgroundColor: "rgba(0,0,0,0.5)",
                          }}
                        >
                          <Typography
                            variant="h1"
                            sx={{
                              textAlign: "left",
                              fontWeight: "bold",
                              color: "white",
                              fontSize: "2.5rem",
                            }}
                          >
                            {inputCurrentTime}
                          </Typography>
                          sec
                        </Stack>
                        <Stack
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          direction="row"
                          style={{
                            position: "absolute",
                            right: "20px",
                            padding: "10px",
                            borderRadius: "10px",
                            top: "200px",
                            color: "white",
                            width: "145px",
                            backgroundColor: "rgba(0,0,0,0.5)",
                          }}
                        >
                          <Typography
                            variant="h1"
                            sx={{
                              textAlign: "left",
                              fontWeight: "bold",
                              color: "white",
                              fontSize: "2.5rem",
                            }}
                          >
                            {currentFrameNumber}
                          </Typography>
                          fr
                        </Stack>
                      </>
                    ) : (
                      <>
                        <img
                          src={NoVideo}
                          alt="No video"
                          style={{
                            width: "70%",
                            height: "auto",
                            borderRadius: "10px",
                          }}
                        />
                        <Typography
                          variant="h1"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "2.5rem",
                          }}
                        >
                          No video
                        </Typography>
                      </>
                    )}
                  </Stack>
                  {dataMiniGamesAttempt?.attemptScore.video.videoUrl && (
                    <>
                      <Stack
                        spacing={2}
                        direction="row"
                        alignContent="center"
                        alignItems="center"
                        justifyContent={"center"}
                        sx={{
                          backgroundColor: "rgba(0,0,0,1)",
                          width: "100%",
                          padding: "10px",
                        }}
                      >
                        <PlayerButton seekSpeed={-0.1}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10 4.93952V19.0604L4.03292 12.2421C4.03102 12.2365 4.02877 12.2294 4.02632 12.2206C4.01175 12.1682 4 12.0906 4 12C4 11.9093 4.01175 11.8317 4.02632 11.7794C4.02877 11.7706 4.03102 11.7635 4.03292 11.7579L10 4.93952ZM4.03922 12.2581L4.03839 12.2564L4.03922 12.2581ZM10.5508 2.00105C10.0775 1.98143 9.71839 2.22441 9.49941 2.47463L2.49941 10.4733C2.09924 10.9306 2 11.5592 2 12C2 12.4407 2.09924 13.0694 2.49941 13.5267L9.49941 21.5253C9.71839 21.7756 10.0775 22.0185 10.5508 21.9989C11.0026 21.9802 11.3237 21.7307 11.5085 21.5162C11.8649 21.1026 12 20.5225 12 19.9987V4.0013C12 3.47743 11.8649 2.89732 11.5085 2.48374C11.3237 2.26929 11.0026 2.01978 10.5508 2.00105Z"
                              fill="currentColor"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M19 4.93952V19.0604L13.0329 12.2421C13.031 12.2365 13.0288 12.2294 13.0263 12.2206C13.0118 12.1682 13 12.0906 13 12C13 11.9093 13.0118 11.8317 13.0263 11.7794C13.0288 11.7706 13.031 11.7635 13.0329 11.7579L19 4.93952ZM13.0392 12.2581L13.0384 12.2564L13.0392 12.2581ZM19.5508 2.00105C19.0775 1.98143 18.7184 2.22441 18.4994 2.47463L11.4994 10.4733C11.0992 10.9306 11 11.5592 11 12C11 12.4407 11.0992 13.0694 11.4994 13.5267L18.4994 21.5253C18.7184 21.7756 19.0775 22.0185 19.5508 21.9989C20.0026 21.9802 20.3237 21.7307 20.5085 21.5162C20.8649 21.1026 21 20.5225 21 19.9987V4.0013C21 3.47743 20.8649 2.89732 20.5085 2.48374C20.3237 2.26929 20.0026 2.01978 19.5508 2.00105Z"
                              fill="currentColor"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10 4.93952V19.0604L4.03292 12.2421C4.03102 12.2365 4.02877 12.2294 4.02632 12.2206C4.01175 12.1682 4 12.0906 4 12C4 11.9093 4.01175 11.8317 4.02632 11.7794C4.02877 11.7706 4.03102 11.7635 4.03292 11.7579L10 4.93952ZM4.03922 12.2581L4.03839 12.2564L4.03922 12.2581ZM10.5508 2.00105C10.0775 1.98143 9.71839 2.22441 9.49941 2.47463L2.49941 10.4733C2.09924 10.9306 2 11.5592 2 12C2 12.4407 2.09924 13.0694 2.49941 13.5267L9.49941 21.5253C9.71839 21.7756 10.0775 22.0185 10.5508 21.9989C11.0026 21.9802 11.3237 21.7307 11.5085 21.5162C11.8649 21.1026 12 20.5225 12 19.9987V4.0013C12 3.47743 11.8649 2.89732 11.5085 2.48374C11.3237 2.26929 11.0026 2.01978 10.5508 2.00105Z"
                              fill="currentColor"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M19 4.93952V19.0604L13.0329 12.2421C13.031 12.2365 13.0288 12.2294 13.0263 12.2206C13.0118 12.1682 13 12.0906 13 12C13 11.9093 13.0118 11.8317 13.0263 11.7794C13.0288 11.7706 13.031 11.7635 13.0329 11.7579L19 4.93952ZM13.0392 12.2581L13.0384 12.2564L13.0392 12.2581ZM19.5508 2.00105C19.0775 1.98143 18.7184 2.22441 18.4994 2.47463L11.4994 10.4733C11.0992 10.9306 11 11.5592 11 12C11 12.4407 11.0992 13.0694 11.4994 13.5267L18.4994 21.5253C18.7184 21.7756 19.0775 22.0185 19.5508 21.9989C20.0026 21.9802 20.3237 21.7307 20.5085 21.5162C20.8649 21.1026 21 20.5225 21 19.9987V4.0013C21 3.47743 20.8649 2.89732 20.5085 2.48374C20.3237 2.26929 20.0026 2.01978 19.5508 2.00105Z"
                              fill="currentColor"
                            />
                          </svg>
                        </PlayerButton>

                        <PlayerButton seekSpeed={-0.033333333}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10 4.93952V19.0604L4.03292 12.2421C4.03102 12.2365 4.02877 12.2294 4.02632 12.2206C4.01175 12.1682 4 12.0906 4 12C4 11.9093 4.01175 11.8317 4.02632 11.7794C4.02877 11.7706 4.03102 11.7635 4.03292 11.7579L10 4.93952ZM4.03922 12.2581L4.03839 12.2564L4.03922 12.2581ZM10.5508 2.00105C10.0775 1.98143 9.71839 2.22441 9.49941 2.47463L2.49941 10.4733C2.09924 10.9306 2 11.5592 2 12C2 12.4407 2.09924 13.0694 2.49941 13.5267L9.49941 21.5253C9.71839 21.7756 10.0775 22.0185 10.5508 21.9989C11.0026 21.9802 11.3237 21.7307 11.5085 21.5162C11.8649 21.1026 12 20.5225 12 19.9987V4.0013C12 3.47743 11.8649 2.89732 11.5085 2.48374C11.3237 2.26929 11.0026 2.01978 10.5508 2.00105Z"
                              fill="currentColor"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M19 4.93952V19.0604L13.0329 12.2421C13.031 12.2365 13.0288 12.2294 13.0263 12.2206C13.0118 12.1682 13 12.0906 13 12C13 11.9093 13.0118 11.8317 13.0263 11.7794C13.0288 11.7706 13.031 11.7635 13.0329 11.7579L19 4.93952ZM13.0392 12.2581L13.0384 12.2564L13.0392 12.2581ZM19.5508 2.00105C19.0775 1.98143 18.7184 2.22441 18.4994 2.47463L11.4994 10.4733C11.0992 10.9306 11 11.5592 11 12C11 12.4407 11.0992 13.0694 11.4994 13.5267L18.4994 21.5253C18.7184 21.7756 19.0775 22.0185 19.5508 21.9989C20.0026 21.9802 20.3237 21.7307 20.5085 21.5162C20.8649 21.1026 21 20.5225 21 19.9987V4.0013C21 3.47743 20.8649 2.89732 20.5085 2.48374C20.3237 2.26929 20.0026 2.01978 19.5508 2.00105Z"
                              fill="currentColor"
                            />
                          </svg>
                        </PlayerButton>
                        {!isPlaying ? (
                          <Button
                            onClick={(e) => {
                              playerRef?.current?.play();
                            }}
                            size="small"
                            variant="text"
                            color="secondary"
                            sx={{ color: "#fff" }}
                          >
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7.00856 3.95206C7.00859 3.95208 7.00837 3.9525 7.0078 3.95327L7.00856 3.95206ZM7 4.12568V19.8743L18.9939 12.0429C18.9968 12.0339 19 12.0192 19 12C19 11.9808 18.9968 11.9661 18.9939 11.9572L7 4.12568ZM7.00856 20.048C7.00853 20.048 7.00825 20.0476 7.0078 20.0468L7.00856 20.048ZM5.88316 2.30314C6.44315 1.93181 7.20333 1.86984 7.85585 2.29591L20.1058 10.2946C20.7346 10.7052 21 11.3914 21 12C21 12.6086 20.7346 13.2949 20.1058 13.7054L7.85585 21.7041C7.20333 22.1302 6.44315 22.0682 5.88316 21.6969C5.33739 21.335 5 20.7031 5 19.9987V4.00132C5 3.29695 5.33739 2.66506 5.88316 2.30314Z"
                                fill="currentColor"
                              />
                            </svg>
                          </Button>
                        ) : (
                          <Button
                            onClick={(e) => {
                              playerRef?.current?.pause();
                            }}
                            size="small"
                            variant="text"
                            color="secondary"
                            sx={{ color: "#fff" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7.38934 4.38604C7.16737 4.68571 7 5.14698 7 5.7V18.3C7 18.853 7.16737 19.3143 7.38934 19.614C7.61144 19.9138 7.83843 20 8 20C8.16157 20 8.38856 19.9138 8.61066 19.614C8.83263 19.3143 9 18.853 9 18.3V5.7C9 5.14698 8.83263 4.68571 8.61066 4.38604C8.38856 4.08621 8.16157 4 8 4C7.83843 4 7.61144 4.08621 7.38934 4.38604ZM5.78223 3.19558C6.28399 2.51821 7.057 2 8 2C8.943 2 9.71601 2.51821 10.2178 3.19558C10.7197 3.87312 11 4.76185 11 5.7V18.3C11 19.2382 10.7197 20.1269 10.2178 20.8044C9.71601 21.4818 8.943 22 8 22C7.057 22 6.28399 21.4818 5.78223 20.8044C5.28035 20.1269 5 19.2382 5 18.3V5.7C5 4.76185 5.28035 3.87312 5.78223 3.19558Z"
                                fill="currentColor"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M15.3893 4.38604C15.1674 4.68571 15 5.14698 15 5.7V18.3C15 18.853 15.1674 19.3143 15.3893 19.614C15.6114 19.9138 15.8384 20 16 20C16.1616 20 16.3886 19.9138 16.6107 19.614C16.8326 19.3143 17 18.853 17 18.3V5.7C17 5.14698 16.8326 4.68571 16.6107 4.38604C16.3886 4.08621 16.1616 4 16 4C15.8384 4 15.6114 4.08621 15.3893 4.38604ZM13.7822 3.19558C14.284 2.51821 15.057 2 16 2C16.943 2 17.716 2.51821 18.2178 3.19558C18.7197 3.87312 19 4.76185 19 5.7V18.3C19 19.2382 18.7197 20.1269 18.2178 20.8044C17.716 21.4818 16.943 22 16 22C15.057 22 14.284 21.4818 13.7822 20.8044C13.2803 20.1269 13 19.2382 13 18.3V5.7C13 4.76185 13.2803 3.87312 13.7822 3.19558Z"
                                fill="currentColor"
                              />
                            </svg>
                          </Button>
                        )}

                        <PlayerButton seekSpeed={0.03333}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M14 4.93952V19.0604L19.9671 12.242C19.969 12.2365 19.9712 12.2294 19.9737 12.2206C19.9882 12.1682 20 12.0906 20 12C20 11.9093 19.9882 11.8317 19.9737 11.7794C19.9712 11.7706 19.969 11.7635 19.9671 11.7579L14 4.93952ZM19.9608 12.2581L19.9616 12.2564L19.9608 12.2581ZM13.4492 2.00105C13.9225 1.98143 14.2816 2.22441 14.5006 2.47463L21.5006 10.4733C21.9008 10.9306 22 11.5592 22 12C22 12.4407 21.9008 13.0694 21.5006 13.5266L14.5006 21.5253C14.2816 21.7756 13.9225 22.0185 13.4492 21.9989C12.9974 21.9802 12.6763 21.7307 12.4915 21.5162C12.1351 21.1026 12 20.5225 12 19.9987V4.0013C12 3.47743 12.1351 2.89732 12.4915 2.48374C12.6763 2.26929 12.9974 2.01978 13.4492 2.00105Z"
                              fill="currentColor"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5 4.93952V19.0604L10.9671 12.242C10.969 12.2365 10.9712 12.2294 10.9737 12.2206C10.9882 12.1682 11 12.0906 11 12C11 11.9093 10.9882 11.8317 10.9737 11.7794C10.9712 11.7706 10.969 11.7635 10.9671 11.7579L5 4.93952ZM10.9608 12.2581L10.9616 12.2564L10.9608 12.2581ZM4.44915 2.00105C4.92253 1.98143 5.28161 2.22441 5.50059 2.47463L12.5006 10.4733C12.9008 10.9306 13 11.5592 13 12C13 12.4407 12.9008 13.0694 12.5006 13.5266L5.50059 21.5253C5.28161 21.7756 4.92253 22.0185 4.44915 21.9989C3.99736 21.9802 3.67629 21.7307 3.4915 21.5162C3.1351 21.1026 3 20.5225 3 19.9987V4.0013C3 3.47743 3.1351 2.89732 3.4915 2.48374C3.67629 2.26929 3.99736 2.01978 4.44915 2.00105Z"
                              fill="currentColor"
                            />
                          </svg>
                        </PlayerButton>
                        <PlayerButton seekSpeed={0.1}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M14 4.93952V19.0604L19.9671 12.242C19.969 12.2365 19.9712 12.2294 19.9737 12.2206C19.9882 12.1682 20 12.0906 20 12C20 11.9093 19.9882 11.8317 19.9737 11.7794C19.9712 11.7706 19.969 11.7635 19.9671 11.7579L14 4.93952ZM19.9608 12.2581L19.9616 12.2564L19.9608 12.2581ZM13.4492 2.00105C13.9225 1.98143 14.2816 2.22441 14.5006 2.47463L21.5006 10.4733C21.9008 10.9306 22 11.5592 22 12C22 12.4407 21.9008 13.0694 21.5006 13.5266L14.5006 21.5253C14.2816 21.7756 13.9225 22.0185 13.4492 21.9989C12.9974 21.9802 12.6763 21.7307 12.4915 21.5162C12.1351 21.1026 12 20.5225 12 19.9987V4.0013C12 3.47743 12.1351 2.89732 12.4915 2.48374C12.6763 2.26929 12.9974 2.01978 13.4492 2.00105Z"
                              fill="currentColor"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5 4.93952V19.0604L10.9671 12.242C10.969 12.2365 10.9712 12.2294 10.9737 12.2206C10.9882 12.1682 11 12.0906 11 12C11 11.9093 10.9882 11.8317 10.9737 11.7794C10.9712 11.7706 10.969 11.7635 10.9671 11.7579L5 4.93952ZM10.9608 12.2581L10.9616 12.2564L10.9608 12.2581ZM4.44915 2.00105C4.92253 1.98143 5.28161 2.22441 5.50059 2.47463L12.5006 10.4733C12.9008 10.9306 13 11.5592 13 12C13 12.4407 12.9008 13.0694 12.5006 13.5266L5.50059 21.5253C5.28161 21.7756 4.92253 22.0185 4.44915 21.9989C3.99736 21.9802 3.67629 21.7307 3.4915 21.5162C3.1351 21.1026 3 20.5225 3 19.9987V4.0013C3 3.47743 3.1351 2.89732 3.4915 2.48374C3.67629 2.26929 3.99736 2.01978 4.44915 2.00105Z"
                              fill="currentColor"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M14 4.93952V19.0604L19.9671 12.242C19.969 12.2365 19.9712 12.2294 19.9737 12.2206C19.9882 12.1682 20 12.0906 20 12C20 11.9093 19.9882 11.8317 19.9737 11.7794C19.9712 11.7706 19.969 11.7635 19.9671 11.7579L14 4.93952ZM19.9608 12.2581L19.9616 12.2564L19.9608 12.2581ZM13.4492 2.00105C13.9225 1.98143 14.2816 2.22441 14.5006 2.47463L21.5006 10.4733C21.9008 10.9306 22 11.5592 22 12C22 12.4407 21.9008 13.0694 21.5006 13.5266L14.5006 21.5253C14.2816 21.7756 13.9225 22.0185 13.4492 21.9989C12.9974 21.9802 12.6763 21.7307 12.4915 21.5162C12.1351 21.1026 12 20.5225 12 19.9987V4.0013C12 3.47743 12.1351 2.89732 12.4915 2.48374C12.6763 2.26929 12.9974 2.01978 13.4492 2.00105Z"
                              fill="currentColor"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5 4.93952V19.0604L10.9671 12.242C10.969 12.2365 10.9712 12.2294 10.9737 12.2206C10.9882 12.1682 11 12.0906 11 12C11 11.9093 10.9882 11.8317 10.9737 11.7794C10.9712 11.7706 10.969 11.7635 10.9671 11.7579L5 4.93952ZM10.9608 12.2581L10.9616 12.2564L10.9608 12.2581ZM4.44915 2.00105C4.92253 1.98143 5.28161 2.22441 5.50059 2.47463L12.5006 10.4733C12.9008 10.9306 13 11.5592 13 12C13 12.4407 12.9008 13.0694 12.5006 13.5266L5.50059 21.5253C5.28161 21.7756 4.92253 22.0185 4.44915 21.9989C3.99736 21.9802 3.67629 21.7307 3.4915 21.5162C3.1351 21.1026 3 20.5225 3 19.9987V4.0013C3 3.47743 3.1351 2.89732 3.4915 2.48374C3.67629 2.26929 3.99736 2.01978 4.44915 2.00105Z"
                              fill="currentColor"
                            />
                          </svg>
                        </PlayerButton>
                      </Stack>
                      <Stack
                        spacing={1}
                        direction="row"
                        alignContent="center"
                        alignItems="center"
                        sx={{
                          marginTop: "10px",
                          textAlign: "center",
                        }}
                      >
                        ← → seek
                        <br />⇧ + ← → seek highspeed
                      </Stack>
                    </>
                  )}
                </Stack>
                <Stack
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: "20%",
                    minWidth: "350px",
                    maxWidth: "400px",
                  }}
                >
                  <RadioGroup
                    row
                    aria-labelledby="is a goal"
                    name="goal"
                    value={isGoal}
                    onChange={handleIsGoalChange}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Goal"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No Goal"
                    />
                  </RadioGroup>

                  <Stack
                    spacing={6}
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    sx={{
                      opacity: isGoal ? 1 : 0.1,
                      pointerEvents: isGoal ? "auto" : "none",
                    }}
                  >
                    {/*<Typography variant="h3" sx={{}}>*/}
                    {/*  {" "}*/}
                    {/*  Results*/}
                    {/*</Typography>*/}
                    <table
                      style={{
                        width: "100%",
                        padding: "20px",
                        borderRadius: "10px",
                        color: "#fff",
                        backgroundColor: "rgba(0,0,0,1)",
                      }}
                    >
                      <thead>
                        <tr>
                          <th></th>
                          <th>AI</th>
                          <th>Prev. Verif.</th>
                          <th>NEW!</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>Shot speed in km/u</td>
                          <td align="center">
                            <Typography variant="h5">
                              {" "}
                              {dataMiniGamesAttempt?.attemptScore?.speed}
                            </Typography>
                          </td>
                          <td align="center">
                            <Typography variant="h5">
                              {" "}
                              {dataMiniGamesAttempt?.verificationScore?.speed}
                            </Typography>
                          </td>
                          <td align="center">
                            <Typography
                              variant="h3"
                              sx={{ fontWeight: "700", color: "success.main" }}
                            >
                              {" "}
                              {balSpeed}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>Accuracy</td>
                          <td align="center">
                            <Typography variant="h5">
                              {" "}
                              {dataMiniGamesAttempt?.attemptScore?.accuracy}
                            </Typography>
                          </td>
                          <td align="center">
                            <Typography variant="h5">
                              {" "}
                              {
                                dataMiniGamesAttempt?.verificationScore
                                  ?.accuracy
                              }
                            </Typography>
                          </td>
                          <td align="center">
                            <Typography
                              variant="h3"
                              sx={{ fontWeight: "700", color: "success.main" }}
                            >
                              {accuracy}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>Points</td>
                          <td align="center">
                            <Typography variant="h5">
                              {dataMiniGamesAttempt?.attemptScore?.points}
                            </Typography>
                          </td>
                          <td align="center">
                            <Typography variant="h5">
                              {" "}
                              {dataMiniGamesAttempt?.verificationScore?.points}
                            </Typography>
                          </td>
                          <td align="center">
                            <Typography
                              variant="h3"
                              sx={{ fontWeight: "700", color: "success.main" }}
                            >
                              {points}
                            </Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/*<Typography variant="h3" sx={{}}>*/}
                    {/*  {" "}*/}
                    {/*  Calculate speed*/}
                    {/*</Typography>*/}
                    <Stack
                      spacing={1}
                      justifyContent="center"
                      alignItems="center"
                      direction="row"
                    >
                      <div style={{ position: "relative", width: "50%" }}>
                        <TextField
                          id="outlined-basic"
                          type="text"
                          label="Start time"
                          size="small"
                          variant="outlined"
                          name={"startTime"}
                          value={startTime}
                          onChange={(e) => {
                            setStartTime(e.target.value);
                          }}
                        />
                        <Stack
                          spacing={2}
                          justifyContent="space-between"
                          alignItems="center"
                          direction="row"
                          sx={{ marginTop: "8px" }}
                        >
                          <Button
                            onClick={(e) => {
                              if (playerRef.current) {
                                setStartTime(inputCurrentTime);
                              }
                            }}
                            size="small"
                            variant="outlined"
                            color="secondary"
                          >
                            Set
                          </Button>{" "}
                          <Button
                            onClick={(e) => {
                              if (playerRef.current) {
                                playerRef.current.currentTime = startTime;
                              }
                            }}
                            size="small"
                            variant="outlined"
                            color="secondary"
                          >
                            Go to
                          </Button>
                        </Stack>
                        <Checkbox
                          color="warning"
                          title={"Set Start time with seek buttons"}
                          sx={{ position: "absolute", top: "0", right: "0" }}
                          checked={startTimeWithSeek}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setStartTimeWithSeek(e.currentTarget.checked);
                            setEndTimeWithSeek(false);
                          }}
                        />
                      </div>

                      <div style={{ position: "relative", width: "50%" }}>
                        <TextField
                          id="outlined-basic"
                          type="text"
                          size="small"
                          label="End time"
                          variant="outlined"
                          name={"endTime"}
                          value={endTime}
                          onChange={(e) => {
                            setEndTime(e.target.value);
                          }}
                        />
                        <Checkbox
                          color="secondary"
                          title={"Set End time with seek buttons"}
                          sx={{ position: "absolute", top: "0", right: "0" }}
                          checked={endTimeWithSeek}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setEndTimeWithSeek(e.currentTarget.checked);
                            setStartTimeWithSeek(false);
                          }}
                        />
                        <Stack
                          spacing={2}
                          justifyContent="space-between"
                          alignItems="center"
                          direction="row"
                          sx={{ marginTop: "8px" }}
                        >
                          <Button
                            onClick={(e) => {
                              if (playerRef.current) {
                                setEndTime(inputCurrentTime);
                              }
                            }}
                            size="small"
                            variant="outlined"
                            color="secondary"
                          >
                            Set
                          </Button>{" "}
                          <Button
                            onClick={(e) => {
                              if (playerRef.current) {
                                playerRef.current.currentTime = endTime;
                              }
                            }}
                            size="small"
                            variant="outlined"
                            color="secondary"
                          >
                            Go to
                          </Button>
                        </Stack>
                      </div>
                    </Stack>

                    <VerifyGoal
                      setAccuracyLevelProp={({ x, y }) => {
                        setBalPosition({ x, y });
                      }}
                      setAccuracyScore={(accuracyScore) =>
                        setAccuracy(accuracyScore)
                      }
                    />
                  </Stack>

                  <TextField
                    id="validatorComment"
                    name="validatorComment"
                    label="Message"
                    multiline
                    rows={3}
                    sx={{ width: "100%" }}
                    value={formik.values.validatorComment}
                    onChange={formik.handleChange}
                  />
                  <Button
                    onClick={(e) => {
                      if (playerRef.current) {
                        playerRef.current.currentTime = startTime;
                      }
                    }}
                    fullWidth={true}
                    size="medium"
                    variant="contained"
                    color="secondary"
                    type={"submit"}
                    endIcon={
                      isLoading && (
                        <CircularProgress
                          size={15}
                          sx={{ color: "#68F3CB" }}
                          color="success"
                        />
                      )
                    }
                  >
                    {isGoal ? "Mark as verified" : "Mark as no goal"}
                  </Button>
                </Stack>
              </Stack>
            </CardContent>
            <CardActions>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="space-between"
                spacing={2}
                sx={{ width: "100%", padding: 2 }}
              >
                <ReactJson
                  src={dataMiniGamesAttempt as Object}
                  collapsed={true}
                />
              </Stack>
              <Button
                size="small"
                variant="outlined"
                onClick={() => navigate(-1)}
                startIcon={<ChevronLeftIcon />}
              >
                back
              </Button>
            </CardActions>
          </Card>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "space-between",
              justifyContent: "space-between",
              alignContent: "space-between",
              width: "100%",
              maxWidth: "850px",
            }}
          ></Stack>
        </Stack>
      </form>
      <Dialog
        open={newVerifyRequest}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setNewVerifyRequest(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: { backgroundColor: "#fff", minWidth: "400px" },
        }}
      >
        <DialogTitle variant="h4">
          {"Session successfully submitted to verify!"}
        </DialogTitle>

        <DialogActions>
          <Button
            size="small"
            onClick={() => {
              setNewVerifyRequest(false);
            }}
          >
            close
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="outlined"
            onClick={() => navigate(`/minigames/${sessionId}`)}
          >
            Go to Session page page
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={noGoalRequest}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setNewVerifyRequest(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: { backgroundColor: "#fff", minWidth: "400px" },
        }}
      >
        <DialogTitle variant="h4">
          {"Session successfully marked as no goal!"}
        </DialogTitle>

        <DialogActions>
          <Button
            size="small"
            onClick={() => {
              setNoGoalRequest(false);
            }}
          >
            close
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="outlined"
            onClick={() => navigate(`/minigames/${sessionId}`)}
          >
            Go to Session page page
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import {
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import * as React from "react";
import { useOrganization } from "../hooks/useOrganization";
import { useLeague } from "../hooks/useLeague";
import { useFormik } from "formik";
import {
  getTeamBoost,
  getTeamBoostPerUser,
  postTeamBoostGift,
  putArchiveTeam,
  putBulkArchiveTeam,
  putTeamBoostUnassign,
  putUnArchiveTeam,
} from "../hooks/useTeam";
import { IBoostStatus, ITeam, ITeamBoost } from "utils/api.interfaces";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import ArchiveIcon from "@mui/icons-material/Archive";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/nl";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import StarIcon from "@mui/icons-material/Star";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonBoosterUser from "./ButtonBoosterUser";
import ButtonBoostRemove from "./ButtonBoostRemove";
import { nanoid } from "nanoid";
interface ButtonButtonBoostTeamProps {
  teamId: string;
  userId: string;
  refetch: () => Promise<any>;
}
const ButtonBoostContainer: React.FC<ButtonButtonBoostTeamProps> = ({
  userId,
  teamId,
  refetch,
}) => {
  const [isLoading, setIsLoading] = React.useState<Boolean>(false);
  const [boosts, setBoosts] = React.useState<ITeamBoost[] | null>(null);
  const [teamBoostId, setTeamBoostId] = React.useState<any | null>(null);
  React.useEffect(() => {
    if (teamId) {
      getTeamBoostPerUser(userId, teamId).then((res: any) => {
        setBoosts(res);
      });
    }
  }, [teamId]);

  return (
    <>
      {boosts?.map((boost: ITeamBoost) => {
        return (
          <>
            <Stack
              key={nanoid(6)}
              direction="row"
              justifyContent="end"
              alignItems="center"
              alignContent="center"
            >
              <ButtonBoosterUser
                userId={boost.userId}
                expiresAtUtc={boost.expiresAtUtc}
                renew={boost.willRenew}
                variant="outlined"
              />
              <ButtonBoostRemove
                teamBoostId={boost.teamBoostId}
                refetch={refetch}
              />
            </Stack>
            <hr />
          </>
        );
      })}
    </>
  );
};

export default ButtonBoostContainer;

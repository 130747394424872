import * as React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormLabel,
  IconButton,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  ActivityAudience,
  IActivityAdd,
  IOrganization,
  ITeam,
  PublishState,
  ThemeColor,
} from "utils/api.interfaces";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { Transition } from "utils/common";
import FormControlLabel from "@mui/material/FormControlLabel";
import useTeams from "hooks/useTeams";

import AutocompleteDebounce from "components/AutocompleteDebounce";
import Radio from "@mui/material/Radio";
import useOrganizations from "../hooks/useOrganizations";
import ActivityFields from "../components/ActivityFields";
import ButtonTeam from "../components/ButtonTeam";
import ButtonOrganization from "../components/ButtonOrganization";
import { useTheme } from "@mui/material/styles";
import { ErrorMessageSlide } from "../components/ErrorMessageSlide";
import ActivityImageVideo from "../components/ActivityImageVideo";

import { postActivity } from "../hooks/useActivity";

export default function MingleActivityAdd() {
  // const [activityAudience, setActivityAudience] = React.useState<string>("all");

  const validationSchema = yup.object({
    content: yup.object({
      nl: yup.object({
        title: yup.string().required("Title is required"),
        // contentTitle: yup.string().required("Content title is required"),
        // imageUrl: yup.string().required("ImageUrl is required"),
      }),
      en: yup.object({
        title: yup.string().required("Title is required"),
        // contentTitle: yup.string().required("Content title is required"),
        // imageUrl: yup.string().required("ImageUrl is required"),
      }),
    }),
    activityAudience: yup.string(),
    // teamId: yup.string().when(["activityAudience"], {
    //   is: (activityAudience: string) => activityAudience === "team",
    //   then: yup.string().required("Please select a team"),
    // }),
    //
    // organizationId: yup.string().when("activityAudience", {
    //   is: (activityAudience: string) => activityAudience === "club",
    //   then: yup.string().required("Please select a club"),
    // }),
  });

  const navigate = useNavigate();
  const [newActivity, setNewActivity] = React.useState<boolean>(false);
  const [nlUseVideo, setNlUseVideo] = React.useState<boolean>(false);
  const [enUseVideo, setEnUseVideo] = React.useState<boolean>(false);
  const [teamFilter, setTeamFilter] = React.useState<string>("");
  const [customTeamFilter, setCustomTeamFilter] = React.useState<string>("");
  // const [useSameImage, setUseSameImage] = React.useState<boolean>(true);

  const { dataTeams } = useTeams({
    OrganizationIdFilter: "",
    LeagueIdFilter: "",
    TeamNameFilter: teamFilter,
    page: "0",
    pageSize: "999",
  });

  const [activityTeamIds, setActivityTeamIds] = React.useState<string[]>([]);
  const [activityOrganizationIds, setActivityOrganizationIds] = React.useState<
    string[]
  >([]);
  const [editOrganizationAsABulk, setEditOrganizationAsABulk] =
    React.useState<boolean>(false);
  const [editTeamAsABulk, setEditTeamAsABulk] = React.useState<boolean>(false);

  const handleAudienceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActivityOrganizationIds([]);
    setActivityTeamIds([]);

    formik.setFieldValue(
      "activityAudience",
      (event.target as HTMLInputElement).value
    );
  };
  const theme = useTheme();
  const [organizationFilter, setOrganizationFilter] =
    React.useState<string>("");
  const { dataOrganizations } = useOrganizations({
    NameFilter: organizationFilter,
    LeagueId: "",
    page: "0",
    pageSize: "99",
  });

  const handleOrganizationChange = (
    event: React.ChangeEvent<unknown>,
    organization: IOrganization
  ) => {
    if (organization) {
      setActivityOrganizationIds((activityOrganizationIds) => [
        ...activityOrganizationIds,
        organization.id,
      ]);
    }
  };
  const deleteOrganizationActivity = (organizationId: string) => {
    if (organizationId) {
      setActivityOrganizationIds(
        activityOrganizationIds.filter((item) => item !== organizationId)
      );
    }
  };
  const handleTeamChange = (event: React.ChangeEvent<unknown>, team: ITeam) => {
    if (team) {
      setActivityTeamIds((activityTeamIds) => [...activityTeamIds, team.id]);
    }
  };
  const handleTeamInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event?.target.value
      .replace(/(\r\n|\n|\r)/gm, "")
      .replace(/ /g, "");

    setActivityTeamIds((activityTeamIds) => inputValue.split(","));
  };
  const handleOrganizationInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event?.target.value
      .replace(/(\r\n|\n|\r)/gm, "")
      .replace(/ /g, "");
    setActivityOrganizationIds((activityTeamIds) => inputValue.split(","));
  };
  const deleteTeamActivity = (teamId: string) => {
    if (teamId) {
      setActivityTeamIds(activityTeamIds.filter((item) => item !== teamId));
    }
  };
  const [showSlideError, setShowSlideError] = React.useState(false);
  const [resetFields, setResetFields] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<Boolean>(false);
  const [isSameImageVideoContent, setIsSameImageVideoContent] =
    React.useState<boolean>(true);
  const initActivity: IActivityAdd = {
    teams: [],
    organizations: [],
    shareWithFollowers: false,
    pinned: false,
    activityAudience: ActivityAudience.All,
    publishState: PublishState.Draft,
    sendPublishNotification: false,

    content: {
      nl: {
        title: "",
        contentTitle: "",
        contentBody: "",
        pushNotificationContentTitle: "",
        pushNotificationContentBody: "",
        button: {
          text: "",
          url: "",
          style: ThemeColor.Primary,
        },

        // mediaContent: {
        //   type: "",
        //   imageName: "",
        //   VideoUrl: "",
        //   ThumbnailUrl: "",
        // },
      },
      en: {
        title: "",
        contentTitle: "",
        contentBody: "",
        pushNotificationContentTitle: "",
        pushNotificationContentBody: "",
        button: {
          text: "",
          url: "",
          style: ThemeColor.Primary,
        },

        // mediaContent: {
        //   type: "",
        //   imageName: "",
        //   VideoUrl: "",
        //   ThumbnailUrl: "",
        // },
      },
    },
  };

  const formik = useFormik({
    initialValues: initActivity,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values: IActivityAdd) => {
      setIsLoading(true);

      postActivity(values)
        .then((res) => {
          setNewActivity(res.id);
          setIsLoading(false);
        })
        .catch((e: any) => {
          setShowSlideError(true);
          setIsLoading(false);
        });
    },
  });

  React.useEffect(() => {
    if (resetFields) {
      formik.setFieldValue("content.nl.mediaContent", "");
      formik.setFieldValue("content.en.mediaContent", "");
    }
  }, [resetFields]);
  React.useEffect(() => {
    formik.setFieldValue("organizations", activityOrganizationIds);
    formik.setFieldValue("teams", activityTeamIds);
  }, [activityOrganizationIds, activityTeamIds]);

  React.useEffect(() => {
    if (!formik.isValid && formik.submitCount !== 0 && formik.isSubmitting) {
      if (!formik.isValid) {
        window.scrollTo(0, 0);
      }
    }
  }, [formik.submitCount, formik.isValid, formik.errors, formik.isSubmitting]);
  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        color="inherit"
        noWrap
        gutterBottom
        sx={{
          textAlign: "center",
          margin: "40px 0px",
        }}
      >
        Add new activity
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          spacing={3}
          sx={{
            margin: "auto",
            opacity: newActivity || isLoading ? 0.4 : 1,
            pointerEvents: isLoading ? "none" : "auto",
          }}
        >
          <Stack>
            <Card sx={{ width: "100%", maxWidth: "850px", margin: " auto" }}>
              <CardContent>
                <Stack direction="column" spacing={4}>
                  <FormControl
                    error={
                      formik.touched.activityAudience &&
                      Boolean(formik.errors.activityAudience)
                    }
                  >
                    <FormLabel id="demo-controlled-radio-buttons-group">
                      This activity is for the following audience
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      // name="controlled-radio-buttons-group"
                      // value={activityAudience}
                      onChange={handleAudienceChange}
                      id="activityAudience"
                      name="activityAudience"
                      // label="activityAudience"
                      // type="activityAudience"
                      value={formik.values.activityAudience}
                      // onChange={formik.handleChange}
                    >
                      <FormControlLabel
                        value={ActivityAudience.All}
                        control={<Radio />}
                        label="For everybody"
                      />
                      <FormControlLabel
                        value={ActivityAudience.Organization}
                        control={<Radio />}
                        label="For specific organization(s)"
                      />
                      <FormControlLabel
                        value={ActivityAudience.Team}
                        control={<Radio />}
                        label="For specific team(s)"
                      />
                    </RadioGroup>
                  </FormControl>
                  {dataOrganizations &&
                    formik.values.activityAudience === "organization" && (
                      <>
                        <Stack direction="row" spacing={1} flexWrap="wrap">
                          <AutocompleteDebounce
                            handleChange={handleOrganizationChange}
                            data={dataOrganizations.organizations}
                            onFilterChange={(value) =>
                              setOrganizationFilter(value)
                            }
                            labelTitle="Add a organization"
                            formikErrors={formik.errors.organizations}
                            formikTouched={formik.touched.organizations}
                          />
                          <FormControlLabel
                            sx={{
                              margin: " 20px auto",
                            }}
                            control={
                              <Switch
                                checked={editOrganizationAsABulk}
                                inputProps={{ "aria-label": "controlled" }}
                                onChange={(e) => {
                                  e.target.checked
                                    ? setEditOrganizationAsABulk(true)
                                    : setEditOrganizationAsABulk(false);
                                }}
                              />
                            }
                            label="Edit raw value (to add organizations as a bulk)"
                          />
                        </Stack>
                        {editOrganizationAsABulk && (
                          <TextField
                            fullWidth
                            id={`organizationsBulk`}
                            name="organizationsBulk"
                            label="Organization raw comma seperated values"
                            value={activityOrganizationIds.join()}
                            multiline
                            minRows={6}
                            maxRows={400}
                            onChange={handleOrganizationInputChange}
                          />
                        )}
                        <Stack direction="row" spacing={0}>
                          {activityOrganizationIds.map((organizationId) => {
                            return (
                              <>
                                <ButtonOrganization
                                  organizationId={organizationId}
                                />
                                <IconButton
                                  aria-label="delete"
                                  color="error"
                                  onClick={(e) => {
                                    deleteOrganizationActivity(organizationId);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </>
                            );
                          })}
                        </Stack>
                      </>
                    )}
                  {dataTeams && formik.values.activityAudience === "team" && (
                    <>
                      <Stack direction="row" spacing={1} flexWrap="wrap">
                        <AutocompleteDebounce
                          handleChange={handleTeamChange}
                          data={dataTeams.teams}
                          onFilterChange={(value) => setTeamFilter(value)}
                          labelTitle="Add a team"
                          formikErrors={formik.errors.teams}
                          formikTouched={formik.touched.teams}
                        />
                        <FormControlLabel
                          sx={{
                            margin: " 20px auto",
                          }}
                          control={
                            <Switch
                              checked={editTeamAsABulk}
                              inputProps={{ "aria-label": "controlled" }}
                              onChange={(e) => {
                                e.target.checked
                                  ? setEditTeamAsABulk(true)
                                  : setEditTeamAsABulk(false);
                              }}
                            />
                          }
                          label="Edit raw value (to add teams as a bulk)"
                        />
                      </Stack>
                      {editTeamAsABulk && (
                        <TextField
                          fullWidth
                          id={`teamsBulk`}
                          name="teamsBulk"
                          label="Teams raw comma seperated values"
                          value={activityTeamIds.join()}
                          multiline
                          minRows={6}
                          maxRows={400}
                          onChange={handleTeamInputChange}
                        />
                      )}
                      <Stack direction="row" spacing={1} flexWrap="wrap">
                        {activityTeamIds.map((teamId) => {
                          return (
                            <div style={{ marginBottom: "10px" }}>
                              <ButtonTeam variant="outlined" teamId={teamId} />
                              <IconButton
                                color="error"
                                aria-label="delete"
                                onClick={(e) => {
                                  deleteTeamActivity(teamId);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          );
                        })}
                      </Stack>
                      <FormControlLabel
                        sx={{
                          margin: " 20px auto",
                        }}
                        control={
                          <Switch
                            checked={formik.values.shareWithFollowers}
                            inputProps={{ "aria-label": "controlled" }}
                            value={formik.values.shareWithFollowers}
                            onChange={(e) => {
                              e.target.checked
                                ? formik.setFieldValue(
                                    "shareWithFollowers",
                                    true
                                  )
                                : formik.setFieldValue(
                                    "shareWithFollowers",
                                    false
                                  );
                            }}
                          />
                        }
                        label="Activity is shared with followers"
                      />
                    </>
                  )}

                  <hr />
                  <FormControlLabel
                    sx={{
                      margin: " 20px auto",
                    }}
                    control={
                      <Switch
                        checked={formik.values.pinned}
                        inputProps={{ "aria-label": "controlled" }}
                        value={formik.values.pinned}
                        onChange={(e) => {
                          e.target.checked
                            ? formik.setFieldValue("pinned", true)
                            : formik.setFieldValue("pinned", false);
                        }}
                      />
                    }
                    label="Activity is pinned in the feed"
                  />
                  <FormControlLabel
                    sx={{
                      margin: " 20px auto",
                    }}
                    control={
                      <Switch
                        checked={
                          formik.values.publishState === PublishState.Published
                        }
                        inputProps={{ "aria-label": "controlled" }}
                        value={formik.values.pinned}
                        onChange={(e) => {
                          e.target.checked
                            ? formik.setFieldValue(
                                "publishState",
                                PublishState.Published
                              )
                            : formik.setFieldValue(
                                "publishState",
                                PublishState.Draft
                              );
                        }}
                      />
                    }
                    label="Activity is published"
                  />

                  <FormControlLabel
                    sx={{
                      margin: " 20px auto 0",
                    }}
                    control={
                      <Switch
                        checked={formik.values.sendPublishNotification}
                        inputProps={{ "aria-label": "controlled" }}
                        value={formik.values.sendPublishNotification}
                        onChange={(e) => {
                          e.target.checked
                            ? formik.setFieldValue(
                                "sendPublishNotification",
                                true
                              )
                            : formik.setFieldValue(
                                "sendPublishNotification",
                                false
                              );
                        }}
                      />
                    }
                    label="Send push notification "
                  />
                  {formik.values.sendPublishNotification && (
                    <Typography
                      variant="body2"
                      color="error"
                      sx={{
                        transform: "translate(58px,-34px)",
                        maxWidth: "280px",
                      }}
                    >
                      Please check if the audience above is set correctly, with
                      great power comes great responsibility ;-)
                    </Typography>
                  )}

                  <Stack direction="row" spacing={4} sx={{ width: "100%" }}>
                    <ActivityFields formik={formik} lang="nl" />
                    <ActivityFields formik={formik} lang="en" />
                  </Stack>

                  <FormControlLabel
                    sx={{
                      margin: " 20px auto 0",
                    }}
                    control={
                      <Switch
                        checked={isSameImageVideoContent}
                        inputProps={{ "aria-label": "controlled" }}
                        onChange={(e) => {
                          setResetFields(true);

                          e.target.checked
                            ? setIsSameImageVideoContent(true)
                            : setIsSameImageVideoContent(false);
                        }}
                      />
                    }
                    label="Use same image or video for NL & EN?"
                  />
                  <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                    <ActivityImageVideo
                      resetFields={resetFields}
                      isVideoActivity={(useVideo: boolean) => {
                        setNlUseVideo(useVideo);
                        setResetFields(true);
                      }}
                      onUploadedVideoFileChange={(video: any) => {
                        setResetFields(false);
                        if (video && video?.outputs?.length > 0) {
                          formik.setFieldValue(
                            "content.nl.mediaContent.type",
                            `video`
                          );
                          formik.setFieldValue(
                            "content.nl.mediaContent.VideoUrl",
                            `${video?.outputContainer.url}/${video?.outputs[0].filePath}`
                          );
                          formik.setFieldValue(
                            "content.nl.mediaContent.sas",
                            `${video?.outputContainer.sas}`
                          );
                          if (isSameImageVideoContent) {
                            formik.setFieldValue(
                              "content.en.mediaContent.type",
                              `video`
                            );
                            formik.setFieldValue(
                              "content.en.mediaContent.VideoUrl",
                              `${video?.outputContainer.url}/${video?.outputs[0].filePath}`
                            );
                            formik.setFieldValue(
                              "content.en.mediaContent.sas",
                              `${video?.outputContainer.sas}`
                            );
                          }
                        }
                      }}
                      onUploadedImageChange={(img: any) => {
                        setResetFields(false);
                        if (!nlUseVideo) {
                          formik.setFieldValue(
                            "content.nl.mediaContent.type",
                            `image`
                          );
                          formik.setFieldValue(
                            "content.nl.mediaContent.imageName",
                            img.fileName
                          );
                          if (isSameImageVideoContent) {
                            formik.setFieldValue(
                              "content.en.mediaContent.type",
                              `image`
                            );
                            formik.setFieldValue(
                              "content.en.mediaContent.imageName",
                              img.fileName
                            );
                          }
                        }
                        if (nlUseVideo) {
                          formik.setFieldValue(
                            "content.nl.mediaContent.ThumbnailUrl",
                            img.fileUrl
                          );
                          if (isSameImageVideoContent) {
                            formik.setFieldValue(
                              "content.en.mediaContent.ThumbnailUrl",
                              img.fileUrl
                            );
                          }
                        }
                      }}
                    />
                    {!isSameImageVideoContent && (
                      <ActivityImageVideo
                        resetFields={resetFields}
                        isVideoActivity={(useVideo: boolean) => {
                          setEnUseVideo(useVideo);
                          setResetFields(true);
                        }}
                        onUploadedVideoFileChange={(video: any) => {
                          setResetFields(false);
                          if (video && video?.outputs?.length > 0) {
                            formik.setFieldValue(
                              "content.en.mediaContent.type",
                              `video`
                            );
                            formik.setFieldValue(
                              "content.en.mediaContent.VideoUrl",
                              `${video?.outputContainer.url}/${video?.outputs[0].filePath}`
                            );
                            formik.setFieldValue(
                              "content.en.mediaContent.sas",
                              `${video?.outputContainer.sas}`
                            );
                          }
                        }}
                        onUploadedImageChange={(img: any) => {
                          setResetFields(false);
                          if (!enUseVideo) {
                            formik.setFieldValue(
                              "content.en.mediaContent.type",
                              `image`
                            );
                            formik.setFieldValue(
                              "content.en.mediaContent.imageName",
                              img.fileName
                            );
                          }
                          if (enUseVideo) {
                            formik.setFieldValue(
                              "content.en.mediaContent.ThumbnailUrl",
                              img.fileUrl
                            );
                          }
                        }}
                      />
                    )}
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Stack>

          <Button
            color="secondary"
            variant="contained"
            fullWidth
            type="submit"
            endIcon={
              isLoading && (
                <IconButton aria-label="" edge="end">
                  <CircularProgress
                    size={15}
                    sx={{ color: "#68F3CB" }}
                    color="success"
                  />
                </IconButton>
              )
            }
          >
            Add Activity
          </Button>
          <ErrorMessageSlide showError={showSlideError} />
        </Stack>

        <Dialog
          open={!!newActivity}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            setNewActivity(false);
          }}
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{
            sx: { backgroundColor: "#fff", minWidth: "400px" },
          }}
        >
          <DialogTitle variant="h4">
            {"Activity successfully added!"}
          </DialogTitle>

          <DialogActions>
            <Button
              size="small"
              onClick={() => {
                setNewActivity(false);
              }}
            >
              close
            </Button>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={() => navigate(`/activity`)}
            >
              Go to activities page
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </>
  );
}

import { Avatar, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import * as React from "react";
import { ITeam, ITeamInMatch, IUser } from "../utils/api.interfaces";
import useTeam from "../hooks/useTeam";
import useUser from "../hooks/useUser";

interface ButtonUserProps {
  variant?: "outlined" | "contained";
  expiresAtUtc: string;
  renew?: boolean;
  userId?: string;
}
const ButtonBoosterUser: React.FC<ButtonUserProps> = ({
  variant = "contained",
  expiresAtUtc,
  renew,
  userId,
}) => {
  const navigate = useNavigate();

  const { isLoading, data } = useUser(userId);
  // console.log(data);
  return (
    <Button
      component={Link}
      color={"primary"}
      to={`/users/${userId}`}
      sx={{
        cursor: "pointer",
        fontSize: "12px",
        borderColor: "transparent",
        whiteSpace: "nowrap",
        "&:hover": {
          borderColor: "transparent",
        },
      }}
      size="small"
      startIcon={
        <Avatar
          sx={{
            backgroundColor: "#fff",
            marginRight: "6px",
            width: "35px",
            height: "35px",
          }}
          src={data?.profile?.imageUrl}
        ></Avatar>
      }
      // endIcon={<TeamIcon />}
      variant={variant}
    >
      {data?.profile.firstName}
      {data?.profile.lastName}
      <br />
      {expiresAtUtc && (
        <>
          {new Date(expiresAtUtc).toLocaleDateString([], {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </>
      )}
      <br />
      renews? {renew ? <>yes</> : <>no</>}
    </Button>
  );
};

export default ButtonBoosterUser;

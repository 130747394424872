import * as React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Avatar, Button, CardActions, Stack } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import useUser from "hooks/useUser";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { UserIcon } from "components/brandlibrary/MingleIcons";
import DialogDeleteUser from "../components/DialogDeleteUser";

import { Delete } from "@mui/icons-material";
import ButtonTeam from "../components/ButtonTeam";
import ButtonOrganization from "../components/ButtonOrganization";
import { ITeam, PremiumLevel, TeamType } from "../utils/api.interfaces";

export default function User() {
  const { userId } = useParams();

  const navigate = useNavigate();
  const { isLoading, data } = useUser(userId);

  const [showDeleteDialog, setShowDeleteDialog] =
    React.useState<boolean>(false);
  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        color="inherit"
        noWrap
        gutterBottom
        sx={{
          textAlign: "center",
          margin: "40px 0px",
        }}
      >
        {`Pre User details for:  ${data?.profile?.firstName} ${data?.profile?.lastName}`}
      </Typography>
      <Stack
        spacing={3}
        sx={{
          flexDirection: "column",
          maxWidth: "850px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          margin: "auto",
        }}
      >
        <Card sx={{ width: "100%", maxWidth: "850px", margin: "auto" }}>
          <CardContent>
            <Typography
              variant="h1"
              color="text.secondary"
              align="center"
              gutterBottom
            >
              {data?.profile?.firstName} {data?.profile?.lastName}
            </Typography>
            <div
              style={{
                position: "relative",
                width: 175,
                height: 175,
                margin: "auto",
              }}
            >
              <Avatar
                src={data?.profile?.imageUrl}
                sx={{ width: 175, height: 175, margin: "auto" }}
              />
              {data?.isGhost && (
                <Typography
                  component="h1"
                  variant="h1"
                  sx={{
                    position: "absolute",
                    top: "83px",
                    left: "83px",
                  }}
                >
                  👻
                </Typography>
              )}
            </div>
            <TableContainer>
              <Table aria-label="user details table">
                <TableBody>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      colSpan={2}
                    ></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      E-mail address:
                    </TableCell>
                    <TableCell align="right">{data?.email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Birthday:
                    </TableCell>
                    <TableCell align="right">
                      {new Date(String(data?.dateOfBirth)).toLocaleDateString(
                        "nl-NL"
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Verified:
                    </TableCell>
                    <TableCell align="right">{data?.isVerified}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      AccountRole:
                    </TableCell>
                    <TableCell align="right">{data?.accountRole}</TableCell>
                  </TableRow>{" "}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Premium Level:
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="h3">
                        {data?.premiumLevel === PremiumLevel.Elite
                          ? "🤩"
                          : data?.premiumLevel === PremiumLevel.Pro
                          ? "😎"
                          : data?.premiumLevel === PremiumLevel.Basic
                          ? "😐"
                          : "🤷"}
                      </Typography>
                      <Typography variant="body2">
                        {data?.premiumLevel ? data?.premiumLevel : "Unknown "}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Preferred Position:
                    </TableCell>
                    <TableCell align="right">
                      {data?.profile?.preferredPosition}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Privacy:
                    </TableCell>
                    <TableCell align="right">
                      {data?.profile?.privacy}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      User id:
                    </TableCell>
                    <TableCell align="right">{data?.id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      User is ghost:
                    </TableCell>
                    <TableCell align="right">
                      {data?.isGhost?.toString()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Team(s):
                    </TableCell>
                    <TableCell align="right">
                      <Stack
                        direction="row"
                        spacing={1}
                        flexWrap="wrap"
                        justifyContent="flex-end"
                      >
                        {data?.teams?.map((team, index) => {
                          return (
                            <Stack
                              style={{
                                marginBottom: "8px",
                                border: "1px solid #056CF1",
                                borderRadius: "30px",
                                paddingRight: "20px",
                              }}
                              spacing={1}
                              direction="row"
                              alignContent="center"
                              alignItems="center"
                              key={team?.team?.id}
                            >
                              <ButtonTeam team={team?.team as ITeam} />

                              <Typography variant="body2">
                                {team?.userRole}
                              </Typography>
                            </Stack>
                          );
                        })}
                      </Stack>
                    </TableCell>
                  </TableRow>
                  {/*<TableRow>*/}
                  {/*  <TableCell colSpan={2}>*/}
                  {/*    <Stack direction="column" spacing={2}>*/}
                  {/*      <Typography*/}
                  {/*        variant="h4"*/}
                  {/*        color="text.secondary"*/}
                  {/*        gutterBottom*/}
                  {/*      >*/}
                  {/*        Export user data*/}
                  {/*      </Typography>*/}
                  {/*      <ButtonExportData variant="team" id={data?.id} />*/}
                  {/*    </Stack>*/}
                  {/*  </TableCell>*/}
                  {/*</TableRow>*/}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>

          <CardActions>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="space-between"
              spacing={2}
              sx={{ width: "100%", padding: 2 }}
            >
              <Button
                component={Link}
                size="small"
                variant="contained"
                color="success"
                to={`/users/add/`}
                startIcon={<UserIcon />}
              >
                add new user
              </Button>
              <Button
                component={Link}
                size="small"
                variant="outlined"
                color="secondary"
                to={`/users/edit/${data?.id}`}
                startIcon={<EditIcon />}
              >
                edit
              </Button>
            </Stack>
          </CardActions>
        </Card>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "space-between",
            justifyContent: "space-between",
            alignContent: "space-between",
            width: "100%",
            maxWidth: "850px",
          }}
        >
          <Button
            size="small"
            variant="outlined"
            onClick={() => navigate(-1)}
            startIcon={<ChevronLeftIcon />}
          >
            back
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="error"
            onClick={() => {
              setShowDeleteDialog(true);
            }}
            startIcon={<Delete />}
            sx={{ whiteSpace: "nowrap" }}
          >
            delete
          </Button>
        </Stack>
        <DialogDeleteUser
          id={data?.id}
          isOpen={showDeleteDialog}
          onIsOpenChange={(value) => {
            setShowDeleteDialog(value);
          }}
        />
      </Stack>
    </>
  );
}

import * as React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { baseWebApiUrl } from "utils/common";

export default function NotLoggedIn() {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      spacing={2}
      sx={{ width: "100%", height: "100vh" }}
    >
      <Typography variant="h1"> You've succesfully logged out</Typography>
      <Button
        href={`${baseWebApiUrl}/v1/Security/login`}
        variant="contained"
        color="secondary"
      >
        Click here to login again
      </Button>
    </Stack>
  );
}

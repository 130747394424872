import React from "react";
import { useQuery } from "@tanstack/react-query";
import { filterTeamsParams } from "utils/common";
import { ISearchTeamsParams, ITeamsApiCall } from "utils/api.interfaces";
import { useAuth } from "./useAuth";
import axiosinterceptor from "../utils/api.interceptor";

const fetchTeams = (
  params: ISearchTeamsParams,
  token: string
): Promise<ITeamsApiCall> => {
  console.log(filterTeamsParams(params));
  return axiosinterceptor
    .get(`/v1/Teams`, {
      // .get(`/v1/Teams`, {
      params: filterTeamsParams(params),
    })
    .then<ITeamsApiCall>((res) => res.data);
};
export default function useTeams(params: ISearchTeamsParams) {
  const { token } = useAuth();
  const queryTeams = useQuery(
    ["teams", params],
    () => fetchTeams(params, token),
    {
      keepPreviousData: true,
    }
  );

  return {
    ...queryTeams,
    dataTeams: queryTeams.data,
    teamsIsLoading: queryTeams.isLoading,
  };
}

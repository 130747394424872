import React from "react";
import { useQuery } from "@tanstack/react-query";
import { filterMatchParams, filterMiniGamesParams } from "utils/common";
import {
  IMatchesApiCall,
  IMiniGamesApiCall,
  ISearchMatchesParams,
  ISearchMiniGamesParams,
} from "utils/api.interfaces";
import axiosinterceptor from "../utils/api.interceptor";

const fetchMiniGames = (
  params: ISearchMiniGamesParams
): Promise<IMiniGamesApiCall> => {
  return axiosinterceptor
    .get(`/v1/MiniGames/sessions`, {
      params: filterMiniGamesParams(params),
    })
    .then<IMiniGamesApiCall>((res) => res.data);
};
export default function useMiniGames(params: ISearchMiniGamesParams) {
  const queryMiniGames = useQuery(
    ["minigames", params],
    () => fetchMiniGames(params),
    {
      keepPreviousData: false,
    }
  );

  // const sortedByDate = queryMatches.data?.matches.sort(
  //   (obj1, obj2) =>
  //     // @ts-ignore
  //     new Date(obj1.scheduledAtUtc) - new Date(obj2.scheduledAtUtc)
  // );

  return {
    ...queryMiniGames,
    dataMiniGames: queryMiniGames.data,
    miniGamesIsLoading: queryMiniGames.isLoading,
  };
}

import { Avatar, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import * as React from "react";
import { ITeam, ITeamInMatch } from "../utils/api.interfaces";
import useTeam from "../hooks/useTeam";

interface ButtonClubProps {
  variant?: "outlined" | "contained";
  teamId?: string;
  team?: ITeam | ITeamInMatch;
}
const ButtonTeam: React.FC<ButtonClubProps> = ({
  variant = "contained",
  team,
  teamId,
}) => {
  const navigate = useNavigate();
  console.log(team);
  let dataTeam = useTeam(teamId).dataTeam;

  return (
    <Button
      component={Link}
      color={"secondary"}
      to={`/teams/${teamId ? dataTeam?.id : team?.id}`}
      sx={{
        cursor: "pointer",
        fontSize: "12px",
        height: "42px",
        whiteSpace: "nowrap",
      }}
      size="small"
      startIcon={
        <Avatar
          sx={{
            backgroundColor: "#fff",
            marginRight: "6px",
            width: "35px",
            height: "35px",
          }}
          src={teamId ? dataTeam?.logoUrl : team?.logoUrl}
        ></Avatar>
      }
      // endIcon={<TeamIcon />}
      variant={variant}
    >
      {teamId ? dataTeam?.name : team?.name || "no team name"}
    </Button>
  );
};

export default ButtonTeam;

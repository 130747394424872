import { Container } from "@mui/material";
import * as React from "react";
import { Outlet } from "react-router-dom";

export default function UsersContainer() {
  return (
    <Container maxWidth="lg">
      <Outlet />
    </Container>
  );
}

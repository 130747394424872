import * as React from "react";
import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Skeleton,
  Stack,
  TableRow,
} from "@mui/material";
import { IFilterPagingParams, PremiumLevel } from "utils/api.interfaces";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { rowsPerpageOptions } from "components/Pagination";
import { StyledTableCell } from "utils/common";
import useUsers from "hooks/useUsers";
import FilterAndPaging from "components/FilterAndPaging";
import { UserIcon } from "../components/brandlibrary/MingleIcons";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import ButtonOverviewDetailLink from "../components/ButtonOverviewDetailLink";
import ActionMenu from "../components/ActionMenu";
import { nanoid } from "nanoid";

export default function Users() {
  const theme = useTheme();
  const navigate = useNavigate();

  let [queryParams, setQueryParams] = useSearchParams();
  const initialSearchParams: IFilterPagingParams = {
    page: queryParams.get("page") || "0",
    filter: queryParams.get("filter") || "",
    pageSize: queryParams.get("pageSize") || rowsPerpageOptions[2].toString(),
  };
  const [searchParams, setSearchParams] =
    useState<IFilterPagingParams>(initialSearchParams);

  const { isLoading, data } = useUsers(searchParams);
  const handleParamsChange = (updatedParam: any) => {
    const newParams: any = {
      ...Object.fromEntries(new URLSearchParams(queryParams.toString())),
      ...updatedParam,
    };
    setSearchParams(newParams);
  };
  React.useEffect(() => {
    setQueryParams(searchParams);
  }, [searchParams]);

  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        color="inherit"
        noWrap
        gutterBottom
        sx={{
          textAlign: "center",
          margin: "40px 0px",
        }}
      >
        Users list & search
      </Typography>
      {!isLoading ? (
        <>
          <Button
            component={Link}
            size="small"
            variant="contained"
            color="success"
            to={`/users/add/`}
            startIcon={<UserIcon />}
          >
            add new user
          </Button>
          <FilterAndPaging
            data={data}
            filterPagingParams={initialSearchParams}
            onParamsChange={handleParamsChange}
          >
            <TableRow>
              <StyledTableCell colSpan={7} width="100%">
                <Grid container>
                  {data?.users?.map((row) => (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      key={"user" + row.id}
                      sx={{
                        borderRight: `1px solid ${theme.palette.secondary.main}`,
                        padding: "10px",
                        "&:hover": {
                          backgroundColor: theme.palette.secondary.light,
                        },
                        "&:nth-of-type(n+1)": {
                          borderBottom: `1px solid ${theme.palette.secondary.main}`,
                        },
                        "&:nth-of-type(3n)": {
                          borderRight: "none",
                        },
                      }}
                    >
                      <Stack direction="row" spacing={1}>
                        <Link
                          to={`/users/${row.id}`}
                          style={{ position: "relative" }}
                        >
                          <Avatar
                            alt={`${row.firstName} ${row.lastName}`}
                            src={row.profileImageUrl}
                            sx={{ width: 50, height: 50 }}
                          />
                          {row?.isGhost && (
                            <Typography
                              component="h1"
                              variant="h1"
                              sx={{
                                position: "absolute",
                                top: "5px",
                                left: "35px",
                              }}
                            >
                              👻
                            </Typography>
                          )}
                        </Link>
                        <div style={{ position: "relative" }}>
                          <ButtonOverviewDetailLink
                            link={`/users/${row.id}`}
                            title={`${row.firstName} ${row.lastName}`}
                          />

                          <Typography
                            variant="body2"
                            sx={{
                              width: "225px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {row.email}
                          </Typography>
                          <Typography
                            variant="h2"
                            sx={{
                              position: "absolute",
                              top: "0px",
                              right: "0px",
                            }}
                            title={
                              row.premiumLevel
                                ? row.premiumLevel
                                : "Unknown premium level"
                            }
                          >
                            {row.premiumLevel === PremiumLevel.Elite
                              ? "🤩"
                              : row.premiumLevel === PremiumLevel.Pro
                              ? "😎"
                              : row.premiumLevel === PremiumLevel.Basic
                              ? "😐"
                              : "🤷"}
                          </Typography>
                        </div>
                        <ActionMenu
                          itemId={row?.id}
                          handleNewClick={(e) => {
                            navigate(`/users/add/`);
                          }}
                          handleEditClick={(e, id) => {
                            navigate(`/users/edit/${id}`);
                          }}
                          showDeleteButton={false}
                        />
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </StyledTableCell>
            </TableRow>
          </FilterAndPaging>
        </>
      ) : (
        <Box sx={{ marginTop: "70px", width: "100%" }}>
          {Array(Number(initialSearchParams.pageSize))
            .fill(1)
            .map((el, i) => (
              <Skeleton animation="wave" height={60} key={nanoid(6)} />
            ))}
        </Box>
      )}
    </>
  );
}

import * as React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { getCountries, ILeagueAdd, ISeason } from "utils/api.interfaces";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Transition } from "utils/common";
import { useNavigate } from "react-router-dom";
import UploadImage from "../components/UploadImage";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTheme } from "@mui/material/styles";
import Select from "@mui/material/Select";
import { ErrorMessageSlide } from "../components/ErrorMessageSlide";
import { postLeague, postLeagueImage, useLeagues } from "../hooks/useLeague";
import League from "./League";
import AutocompleteDebounce from "../components/AutocompleteDebounce";
import useOrganizations from "../hooks/useOrganizations";
import { postOrganizationImage } from "../hooks/useOrganization";
import DateFromToFilter from "../components/DateFromToFilter";
import dayjs from "dayjs";
import { rowsPerpageOptions } from "../components/Pagination";
import SeasonFieldsNew from "../components/SeasonFieldsNew";
import { Delete } from "@mui/icons-material";

export default function LeagueAdd() {
  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
  });

  const initLeague: ILeagueAdd = {
    name: "",
    logoUrl: "",
    sportType: "football",
    countryCode: "NL",
    isGhost: true,
    isHighlighted: false,
    isDefault: false,
    seasons: [
      {
        name: "string",
        startsAtUtc: "2023-06-22T13:49:57.085Z",
        endsAtUtc: "2023-06-22T13:49:57.086Z",
        isActive: true,
      },
    ],
  };

  const navigate = useNavigate();
  const [showSlideError, setShowSlideError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<Boolean>(false);
  const [newLeague, setNewLeague] = React.useState<string | undefined>(
    undefined
  );
  const [acceptedFiles, setAcceptedFiles] = React.useState<File[]>([]);

  const [seasons, setSeasons] = React.useState<ISeason[]>([]);
  React.useEffect(() => {
    console.log(seasons);
    formik.setFieldValue("seasons", seasons);
  }, [seasons]);

  const theme = useTheme();
  const formik = useFormik({
    initialValues: initLeague,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      if (acceptedFiles && acceptedFiles?.length > 0) {
        postLeagueImage(acceptedFiles)
          .then((res) => {
            postLeague({
              ...values,
              logoUrl: res.fileUrl,
            })
              .then((res) => {
                setIsLoading(false);
                setNewLeague(res.id);
              })
              .catch((e: any) => {
                setShowSlideError(true);
                setIsLoading(false);
              });
          })
          .catch((e: any) => {
            setShowSlideError(true);
            setIsLoading(false);
          });
      } else {
        postLeague({
          ...values,
        })
          .then((res) => {
            setIsLoading(false);
            setNewLeague(res.id);
          })
          .catch((e: any) => {
            setShowSlideError(true);
            setIsLoading(false);
          });
      }
    },
  });

  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        color="inherit"
        noWrap
        gutterBottom
        sx={{
          textAlign: "center",
          margin: "40px 0px",
        }}
      >
        Add new league
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          spacing={3}
          sx={{
            maxWidth: "500px",
            margin: "auto",
            opacity: newLeague || isLoading ? 0.4 : 1,
            pointerEvents: isLoading ? "none" : "auto",
          }}
        >
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" shrink={true}>
              Sport Type
            </InputLabel>
            <Select
              labelId="sportType"
              id="sportType"
              name="sportType"
              value={formik.values.sportType}
              label="sportType"
              onChange={formik.handleChange}
            >
              <MenuItem value="football">Football</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" shrink={true}>
              Country code
            </InputLabel>

            <Select
              labelId="countryCode"
              id="countryCode"
              name="countryCode"
              value={formik.values.countryCode}
              label="countryCode"
              onChange={formik.handleChange}
            >
              {getCountries().map((country: any) => (
                <MenuItem key={country.code} value={country.code}>
                  {country.name} ({country.code})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            sx={{
              margin: " 20px auto",
            }}
            control={
              <Switch
                checked={formik.values.isGhost}
                inputProps={{ "aria-label": "controlled" }}
                value={formik.values.isGhost}
                onChange={(e) => {
                  e.target.checked
                    ? formik.setFieldValue("isGhost", true)
                    : formik.setFieldValue("isGhost", false);
                }}
              />
            }
            label="League is a ghost league?"
          />
          <FormControlLabel
            sx={{
              margin: " 20px auto",
            }}
            control={
              <Switch
                checked={formik.values.isHighlighted}
                inputProps={{ "aria-label": "controlled" }}
                value={formik.values.isHighlighted}
                onChange={(e) => {
                  e.target.checked
                    ? formik.setFieldValue("isHighlighted", true)
                    : formik.setFieldValue("isHighlighted", false);
                }}
              />
            }
            label="League is a highlighted league (shown on top of results/suggestions)?"
          />
          <FormControlLabel
            sx={{
              margin: " 20px auto",
            }}
            control={
              <Switch
                checked={formik.values.isDefault}
                inputProps={{ "aria-label": "controlled" }}
                value={formik.values.isDefault}
                onChange={(e) => {
                  e.target.checked
                    ? formik.setFieldValue("isDefault", true)
                    : formik.setFieldValue("isDefault", false);
                }}
              />
            }
            label="League is a default league?"
          />
          <UploadImage
            maxFiles={1}
            onAcceptedFilesChange={(files: File[]) => {
              setAcceptedFiles(files);
            }}
          />
          <h3>Seasson(s)</h3>
          {seasons?.map((season, index) => (
            <div key={index + "season"}>
              <strong>{season?.name}</strong>
              <br />
              <Stack
                direction="row"
                justifyItems="center"
                alignContent="center"
                alignItems="center"
                gap={3}
                sx={{ width: "100%" }}
              >
                <div>
                  {new Date(season?.startsAtUtc).toLocaleDateString([], {
                    weekday: "short",
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })}
                </div>
                -
                <div>
                  {new Date(season?.endsAtUtc).toLocaleDateString([], {
                    weekday: "short",
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })}
                </div>
                <Button
                  color="error"
                  variant="outlined"
                  size={"small"}
                  sx={{ marginLeft: "auto" }}
                  onClick={(e) => {
                    let newSeasons = seasons ? [...seasons] : [];
                    newSeasons.splice(index, 1);
                    console.log(newSeasons);
                    setSeasons(newSeasons);
                  }}
                >
                  <Delete />
                </Button>
              </Stack>
              <hr />
            </div>
          ))}

          <SeasonFieldsNew
            onFieldChanges={(startsAtUtc, endsAtUtc) =>
              console.log(startsAtUtc, endsAtUtc)
            }
            onFieldSubmit={(name, startsAtUtc, endsAtUtc) =>
              setSeasons([...seasons, { name, startsAtUtc, endsAtUtc }])
            }
          />
          <Button
            color="secondary"
            variant="contained"
            fullWidth
            type="submit"
            endIcon={
              isLoading && (
                <IconButton aria-label="" edge="end">
                  <CircularProgress
                    size={15}
                    sx={{ color: "#68F3CB" }}
                    color="success"
                  />
                </IconButton>
              )
            }
          >
            Add League
          </Button>
          <ErrorMessageSlide showError={showSlideError} />
        </Stack>

        <Dialog
          open={!!newLeague}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            setNewLeague(undefined);
          }}
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{
            sx: { backgroundColor: "#fff", minWidth: "400px" },
          }}
        >
          <DialogTitle variant="h4">{"League successfully added!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              onClick={() => {
                setNewLeague(undefined);
              }}
            >
              close
            </Button>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={() => navigate(`/leagues`)}
            >
              Go to league page
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </>
  );
}

import * as React from "react";
import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  MenuItem,
  Skeleton,
  Stack,
  TableRow,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  IOrganization,
  ISearchMatchesParams,
  ITeam,
} from "utils/api.interfaces";
import { rowsPerpageOptions } from "components/Pagination";
import useFootballMatches from "hooks/useMatches";
import { MatchIcon } from "components/brandlibrary/MingleIcons";
import { StyledTableCell, StyledTableRowNoPointer } from "utils/common";
import DownloadIcon from "@mui/icons-material/Download";
import FilterAndPaging from "components/FilterAndPaging";
import { getVotesExport } from "../hooks/fetch";
import ButtonTeam from "../components/ButtonTeam";
import ButtonOverviewDetailLink from "../components/ButtonOverviewDetailLink";
import ActionMenu from "../components/ActionMenu";
import DialogDeleteMatch from "../components/DialogDeleteMatch";
import { nanoid } from "nanoid";
import FilterOrganization from "../components/FilterOrganization";
import FilterTeam from "../components/FilterTeam";
import dayjs from "dayjs";
import DateFromToFilter from "../components/DateFromToFilter";

export default function Matches() {
  let [queryParams, setQueryParams] = useSearchParams();

  const initialSearchParams: ISearchMatchesParams = {
    page: queryParams.get("page") || "0",
    TeamIdFilter: queryParams.get("TeamIdFilter") || "",
    OrganizationIdFilter: queryParams.get("OrganizationIdFilter") || "",
    pageSize: queryParams.get("pageSize") || rowsPerpageOptions[4].toString(),
    "ScheduledAtFilter.From":
      queryParams.get("ScheduledAtFilter.From") ||
      dayjs().subtract(1, "week").toISOString(),
    "ScheduledAtFilter.To":
      queryParams.get("ScheduledAtFilter.To") ||
      dayjs().add(1, "week").toISOString(),
  };
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const matchfeedURL =
    process.env.REACT_APP_ENV === "PROD"
      ? "https://app.mingle.sport/matchfeed"
      : "https://development.alpha-mingle-sport.pages.dev/matchfeed";
  const [showDeleteDialog, setShowDeleteDialog] =
    React.useState<boolean>(false);

  const [clickedMatchId, setClickedMatchId] = React.useState<
    string | undefined
  >("");

  const { isLoading, dataMatches } = useFootballMatches(searchParams);
  const navigate = useNavigate();

  const handleParamsChange = (updatedParam: any) => {
    const newParams: any = {
      ...Object.fromEntries(new URLSearchParams(queryParams.toString())),
      ...updatedParam,
    };
    console.log(newParams);
    setSearchParams(newParams);
  };

  const handleTeamChange = (event: React.ChangeEvent<unknown>, team: ITeam) => {
    team
      ? handleParamsChange({
          TeamIdFilter: team.id,
          page: "0",
          pageSize: rowsPerpageOptions[1].toString(),
        })
      : handleParamsChange({
          TeamIdFilter: "",

          page: "0",
          pageSize: rowsPerpageOptions[1].toString(),
        });
  };
  const handleOrganizationChange = (
    event: React.ChangeEvent<unknown>,
    club: IOrganization
  ) => {
    club
      ? handleParamsChange({
          OrganizationIdFilter: club.id,
          TeamIdFilter: "",
          page: "0",
          pageSize: rowsPerpageOptions[1].toString(),
        })
      : handleParamsChange({
          OrganizationIdFilter: "",
          TeamIdFilter: "",
          page: "0",
          pageSize: rowsPerpageOptions[1].toString(),
        });
  };
  React.useEffect(() => {
    setQueryParams(searchParams);
  }, [searchParams]);
  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        color="inherit"
        noWrap
        gutterBottom
        sx={{
          textAlign: "center",
          margin: "40px 0px",
        }}
      >
        Matches list & search
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="space-between"
        spacing={2}
      >
        <Button
          component={Link}
          size="small"
          variant="contained"
          color="success"
          to={`/match/add/`}
          startIcon={<MatchIcon />}
        >
          add new match
        </Button>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => {
            getVotesExport()
              .then((res) => {
                const file = new Blob([res], { type: "text/csv" });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
              })
              .catch((error: any) => {});
          }}
          startIcon={<DownloadIcon />}
        >
          Download (*.csv) vote report
        </Button>
      </Stack>
      <FilterAndPaging
        data={dataMatches}
        filterPagingParams={initialSearchParams}
        onParamsChange={(e) => {
          handleParamsChange(e);
        }}
        showSearchField={false}
        TableHeaders={
          <TableRow>
            <StyledTableCell>Match</StyledTableCell>
            <StyledTableCell align="right">Team - opponent</StyledTableCell>
            <StyledTableCell align="right">Actions</StyledTableCell>
          </TableRow>
        }
        filterOptions={
          <>
            <FilterOrganization
              onOrganizationChange={handleOrganizationChange}
              initSelectedOrganization={searchParams.OrganizationIdFilter}
            />
            <div
              style={{
                width: "375px",
                opacity: searchParams.OrganizationIdFilter !== "" ? "1" : ".5",
                pointerEvents:
                  searchParams.OrganizationIdFilter !== "" ? undefined : "none",
              }}
            >
              <FilterTeam
                onTeamChange={handleTeamChange}
                organizationId={searchParams.OrganizationIdFilter}
                initSelectedTeamId={searchParams.TeamIdFilter}
              />
            </div>
            <DateFromToFilter
              dateFrom={dayjs(initialSearchParams["ScheduledAtFilter.From"])}
              dateTo={dayjs(initialSearchParams["ScheduledAtFilter.To"])}
              onDateFromToChange={(dateFromValue, dateToValue) => {
                handleParamsChange({
                  "ScheduledAtFilter.From": dateFromValue?.toISOString(),
                  "ScheduledAtFilter.To": dateToValue?.toISOString(),
                  page: "0",
                  pageSize: rowsPerpageOptions[1].toString(),
                });
              }}
            />
          </>
        }
      >
        {!isLoading ? (
          <>
            {dataMatches?.matches?.map((row) => (
              <StyledTableRowNoPointer key={nanoid(6)} hover>
                <StyledTableCell>
                  {new Date(row.scheduledAtUtc).toLocaleDateString([], {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                  <ButtonOverviewDetailLink
                    link={`/match/${row.id}`}
                    title={`${row.team.name} - ${row.opponent.name}`}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <ButtonTeam team={row.team} variant="outlined" />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Button
                    color={"secondary"}
                    sx={{
                      cursor: "pointer",
                      fontSize: "12px",
                      height: "42px",
                      pointerEvents: "none",
                      whiteSpace: "nowrap",
                    }}
                    disabled={true}
                    size="small"
                    startIcon={
                      <Avatar
                        sx={{
                          backgroundColor: "#fff",
                          marginRight: "6px",
                          width: "35px",
                          height: "35px",
                        }}
                        src={row.opponent.logoUrl}
                      ></Avatar>
                    }
                    variant="outlined"
                  >
                    {row.opponent.name}
                  </Button>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <ActionMenu
                    itemId={row?.id}
                    showEditButton={false}
                    handleNewClick={(e) => {
                      navigate(`/match/add/`);
                    }}
                    handleEditClick={(e, id) => {
                      // navigate(`/activity/edit/${id}`);
                    }}
                    handleDeleteClick={(e, id) => {
                      setClickedMatchId(id);
                      setShowDeleteDialog(true);
                    }}
                  >
                    <MenuItem
                      component="a"
                      href={`${matchfeedURL}/${row.id}-${row?.team.id}`}
                      target="_blank"
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      ⚽ Matchfeed for {row.team.league?.name} {row.team.name}
                    </MenuItem>

                    <Divider />
                  </ActionMenu>
                </StyledTableCell>
              </StyledTableRowNoPointer>
            ))}
            <DialogDeleteMatch
              id={clickedMatchId}
              isOpen={showDeleteDialog}
              onIsOpenChange={(value) => {
                setShowDeleteDialog(value);
              }}
            />
          </>
        ) : (
          <StyledTableRowNoPointer key={nanoid(6)} hover>
            <StyledTableCell colSpan={4}>
              <Box>
                {Array(Number(initialSearchParams.pageSize))
                  .fill(1)
                  .map((el, i) => (
                    <Skeleton animation="wave" height={60} key={nanoid(6)} />
                  ))}
              </Box>
            </StyledTableCell>
          </StyledTableRowNoPointer>
        )}
      </FilterAndPaging>
    </>
  );
}

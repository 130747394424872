import React from "react";
import { useQuery } from "@tanstack/react-query";
import { IBoostStatus, ITeam, ITeamAdd, ITeamEdit } from "utils/api.interfaces";
import axiosinterceptor from "../utils/api.interceptor";

export const fetchTeam = (teamId: string | undefined): Promise<ITeam> =>
  // axiosinterceptor.get(`v1/Teams/${teamId}`).then<ITeam>((res) => res.data);

  axiosinterceptor.get(`/v1/Teams/${teamId}`).then<ITeam>((res) => res.data);

export default function useTeam(teamId: string | undefined) {
  const queryTeam = useQuery(["team", teamId], () => fetchTeam(teamId), {
    enabled: !!teamId,
  });
  return {
    ...queryTeam,
    dataTeam: queryTeam?.data,
    dataTeamIsLoading: queryTeam?.isLoading,
  };
}
export const postTeam = (team: ITeamAdd): Promise<any> => {
  return axiosinterceptor.post(`/v1/Teams`, team).then((res) => {
    return res.data;
  });
};
export const getTeamBoost = (id: string): Promise<any> => {
  return axiosinterceptor
    .get(`/v1/TeamBoost/team/${id}/booststatus`)
    .then((res) => {
      return res.data;
    });
};
export const getTeamBoostPerUser = (
  userId: string,
  teamId: string
): Promise<any> => {
  return axiosinterceptor

    .get(`v1/TeamBoost/user/${userId}?teamFilter=${teamId}`)
    .then((res) => {
      return res.data;
    });
};

export const postTeamBoostGift = (
  id: string,
  expiresAtUtc: string
): Promise<any> => {
  return axiosinterceptor
    .post(`/v1/TeamBoost/gift`, { teamId: id, expiresAtUtc })
    .then((res) => {
      return res.data;
    });
};
export const putTeamBoostUnassign = (
  teamBoostId: string
): Promise<IBoostStatus> => {
  return axiosinterceptor
    .put(`/v1/TeamBoost/unassign`, { teamBoostId: teamBoostId })
    .then((res) => {
      return res.data;
    });
};

export const putTeam = (team: ITeamEdit): Promise<IBoostStatus> => {
  return axiosinterceptor.put(`/v1/Teams`, team).then((res) => {
    return res.data;
  });
};
export const postTeamImage = (image: File[]): Promise<any> => {
  const formData = new FormData();
  formData.append("imageFile", image[0]);

  return axiosinterceptor.post(`/v1/Teams/logo`, formData).then((res) => {
    return res.data;
  });
};
export const putArchiveTeam = (id: string): Promise<any> => {
  return axiosinterceptor.put(`/v1/Teams/archive`, { id }).then((res) => {
    return res.data;
  });
};
export const putUnArchiveTeam = (id: string): Promise<any> => {
  return axiosinterceptor.put(`/v1/Teams/unarchive`, { id }).then((res) => {
    return res.data;
  });
};
export const putBulkArchiveTeam = (seasonId: string): Promise<any> => {
  return axiosinterceptor
    .put(`/v1/Teams/bulkarchive`, { seasonId })
    .then((res) => {
      return res.data;
    });
};

import * as React from "react";
import { useState } from "react";
import { getCountries, IFilterPagingParams } from "../utils/api.interfaces";
import useOrganizations from "../hooks/useOrganizations";
import AutocompleteDebounce from "./AutocompleteDebounce";
import { rowsPerpageOptions } from "./Pagination";
import { useLeagues } from "../hooks/useLeague";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { MenuItem } from "@mui/material";

interface CountryFilterProps {
  initSelectedCountry?: string | null;
  onCountryChange: (event: SelectChangeEvent<string | null>) => void;
}
const CountryFilter: React.FC<CountryFilterProps> = ({
  onCountryChange,
  initSelectedCountry,
}) => {
  return (
    <div
      style={{
        width: "260px",
        minWidth: "260px",
      }}
    >
      <Select
        labelId="countryCode"
        id="countryCode"
        name="countryCode"
        value={initSelectedCountry}
        label="countryCode"
        onChange={(e) => onCountryChange(e)}
      >
        {getCountries().map((country: any) => (
          <MenuItem key={country.code} value={country.code}>
            {country.name} ({country.code})
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default CountryFilter;

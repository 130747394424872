import React from "react";
import { useQuery } from "@tanstack/react-query";
import { filterparams } from "utils/common";
import { IFilterPagingParams, IUsersApiCall } from "utils/api.interfaces";
import { useAuth } from "./useAuth";
import axiosinterceptor from "../utils/api.interceptor";

const fetchUsers = (
  params: IFilterPagingParams,
  token: string
): Promise<IUsersApiCall> => {
  return axiosinterceptor
    .get(`/v1/Users`, {
      params: filterparams(params),
    })
    .then<IUsersApiCall>((res) => res.data);
};
export default function useUsers(params: IFilterPagingParams) {
  const { token } = useAuth();
  const querySearch = useQuery(
    ["users", params],
    () => fetchUsers(params, token),
    {
      keepPreviousData: true,
    }
  );

  return querySearch;
}

import { Container } from "@mui/material";
import * as React from "react";
import { Outlet } from "react-router-dom";

export default function LeagueContainer() {
  return (
    <Container maxWidth="lg">
      <div style={{ textAlign: "center" }}></div>
      <Outlet />
    </Container>
  );
}

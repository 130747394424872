import { Avatar, Button, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import * as React from "react";
import { ITeam, ITeamInMatch } from "../utils/api.interfaces";
import useTeam from "../hooks/useTeam";
import useMousePosition from "../hooks/UseMousePosition";
import { useState } from "react";
import { postActivity } from "../hooks/useActivity";
import { getAccuracy } from "../hooks/useMiniGameAttempt";

interface BalPositionProps {
  x: string;
  y: string;
}
interface GoalProps {
  setAccuracyScore: (score: number) => void;
  setAccuracyLevelProp: ({ x, y }: BalPositionProps) => void;
}
const VerifyGoal: React.FC<GoalProps> = ({
  setAccuracyScore,
  setAccuracyLevelProp,
}) => {
  const goal = React.useRef() as React.RefObject<HTMLDivElement>;

  const balImage = React.useRef() as React.RefObject<HTMLImageElement>;
  const [score, setScore] = useState<number>(0);

  const mousePosition = useMousePosition(goal);
  const [accuracyLevel, setAccuracyLevel] = useState<BalPositionProps>({
    x: "0.00",
    y: "0.00",
  });
  React.useEffect(() => {
    getAccuracy({
      GoalPositionX: Number(accuracyLevel.x),
      GoalPositionY: Number(accuracyLevel.y),
    })
      .then((res) => {
        setAccuracyLevelProp({
          x: accuracyLevel.x,
          y: accuracyLevel.y,
        });
        setScore(res.accuracyScore);
        setAccuracyScore(res.accuracyScore);
      })
      .catch((e: any) => {
        console.log(e);
      });
  }, [accuracyLevel]);
  return (
    <>
      {/*{mousePosition?.x} - {mousePosition?.y}*/}
      {/*<Typography variant={"h6"}>*/}
      {/*  {accuracyLevel?.x}, {accuracyLevel?.y}*/}
      {/*</Typography>*/}
      <div style={{ position: "relative", perspective: "135px" }}>
        <div
          ref={goal}
          style={{
            width: "300px",
            height: "100px",
            boxShadow: "0px 0px 0px 5px rgba(0,0,0,0.75)",
            // border: "outset 3px black",
            backgroundColor: "rgba(0,0,0,0.01)",
            // borderBottom: "outset 1px black",
            backgroundSize: "10px 10px",
            backgroundImage:
              "linear-gradient(to right, grey 1px, transparent 1px), linear-gradient(to bottom, grey 1px, transparent 1px)",
          }}
          onClick={(e) => {
            const rect = goal?.current?.getBoundingClientRect();
            if (balImage?.current && rect) {
              balImage.current.style.left = `${mousePosition.x}px`;
              balImage.current.style.top = `${mousePosition.y}px`;
              setAccuracyLevel({
                x: (Number(mousePosition.x) / rect?.width).toFixed(2),
                y: (
                  (rect?.height - Number(mousePosition.y)) /
                  rect?.height
                ).toFixed(2),
              });
            }
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            width: "115%",
            left: "-7%",
            bottom: "-1px",
            height: "38px",

            transform: "rotateX(238deg) rotateY(0deg)",
            transformStyle: "preserve-3d",

            // borderColor: "#47b93b transparent transparent transparent",
            background: "linear-gradient( white,#bfea7f)",
          }}
        ></div>
        <div
          ref={balImage}
          style={{
            position: "absolute",
            zIndex: 100,
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            margin: "-12px 0 0 -12px",
            background: "#fff",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#fff"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.1501 3.65017L12.5194 1.80389L14.4806 2.19613L13.9806 4.69613C13.9014 5.09199 13.592 5.40142 13.1961 5.48059L10.6961 5.98059C10.3295 6.05391 9.95265 5.91663 9.71912 5.62471L7.71912 3.12471L9.28085 1.87532L10.9007 3.90007L12.1501 3.65017ZM17.7152 7.08175L19.2809 5.12471L17.7191 3.87532L15.7191 6.37532C15.458 6.70171 15.4274 7.15608 15.6425 7.51451L17.1425 10.0145C17.3495 10.3595 17.7431 10.5469 18.1414 10.49L21.6414 9.98996L21.3586 8.01006L18.5159 8.41616L17.7152 7.08175Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.7191 14.8753C16.0137 14.5071 16.5255 14.3947 16.9472 14.6056L20.9472 16.6056L20.0528 18.3944L16.7759 16.756L15.6806 18.1251L16.8944 20.5528L15.1056 21.4472L13.6056 18.4472C13.4311 18.0983 13.4755 17.6799 13.7191 17.3753L15.7191 14.8753Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.40002 14.7C4.72529 14.4561 5.16588 14.4333 5.51452 14.6425L8.01452 16.1425C8.36679 16.3539 8.55396 16.7592 8.48642 17.1644L7.98642 20.1644L6.01363 19.8356L6.4017 17.5072L5.06211 16.7034L3.60002 17.8L2.40002 16.2L4.40002 14.7Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.49998 8.21922V5.5H5.49998V9C5.49998 9.45887 5.18768 9.85885 4.74252 9.97014L2.74252 10.4701L2.25745 8.52986L3.49998 8.21922Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.30908 10.3291L9.14178 11.8348L10.6051 12.5665L11.6752 11.4964L11.1677 10.1432L9.30908 10.3291ZM8.30504 8.41949C7.83556 8.46644 7.46276 8.83516 7.41066 9.3041L7.07701 12.307C7.03074 12.7234 7.24895 13.1245 7.62368 13.3118L10.3553 14.6777C10.7403 14.8702 11.2053 14.7947 11.5097 14.4903L13.5447 12.4553C13.822 12.178 13.9116 11.7643 13.7739 11.3971L12.7702 8.72047C12.6109 8.29575 12.1857 8.03142 11.7344 8.07656L8.30504 8.41949Z"
              fill="black"
            />
          </svg>
        </div>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          alignContent="center"
          justifyContent="center"
          sx={{ marginTop: "6px" }}
        >
          <Typography variant={"h6"}>
            {accuracyLevel?.x}, {accuracyLevel?.y}
          </Typography>
          {/*<Typography variant={"h1"}>{score}</Typography>*/}
        </Stack>
      </div>
    </>
  );
};

export default VerifyGoal;

import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Delete } from "@mui/icons-material";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
interface ActionMenuProps {
  itemId?: string;
  showDeleteButton?: boolean;
  showEditButton?: boolean;
  handleNewClick: (event: React.ChangeEvent<unknown>) => void;
  handleEditClick: (
    event: React.ChangeEvent<unknown>,
    id: string | undefined
  ) => void;
  handleDeleteClick?: (
    event: React.ChangeEvent<unknown>,
    id: string | undefined
  ) => void;
}
export const ActionMenu: React.FC<ActionMenuProps> = ({
  children,
  showDeleteButton = true,
  showEditButton = true,
  handleNewClick,
  handleEditClick,
  handleDeleteClick,
  itemId,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="text"
        size="small"
        disableElevation
        onClick={handleClick}
        sx={{
          padding: "4px",
          minWidth: "25px",
        }}
      >
        <MoreVertIcon />
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {children}

        <MenuItem
          onClick={(e) => {
            handleNewClick(e);
            handleClose();
          }}
          disableRipple
        >
          <AddCircleOutlineIcon />
          Add new
        </MenuItem>

        {showEditButton && (
          <MenuItem
            onClick={(e) => {
              handleEditClick(e, itemId);
              handleClose();
            }}
            disableRipple
          >
            <EditIcon />
            Edit
          </MenuItem>
        )}
        {showDeleteButton && (
          <MenuItem
            onClick={(e) => {
              handleDeleteClick ? handleDeleteClick(e, itemId) : "";
              handleClose();
            }}
            disableRipple
          >
            <Delete />
            Delete
          </MenuItem>
        )}
      </StyledMenu>
    </div>
  );
};
export default ActionMenu;

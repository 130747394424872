import React from "react";
import { useQuery } from "@tanstack/react-query";
import { IMatches } from "utils/api.interfaces";
import { useAuth } from "./useAuth";
import axiosinterceptor from "../utils/api.interceptor";

export const fetchVideo = (videoId: string | undefined): Promise<IMatches> =>
  axiosinterceptor
    .get(
      `https://intense-journey-25438.herokuapp.com/https://api-videoservice-tst.azurewebsites.net/api/v1/Video/${videoId}`
    )
    .then<IMatches>((res) => res.data);

export default function useVideo(videoId: string | undefined) {
  const { token } = useAuth();

  const queryVideo = useQuery(["video", videoId], () => {
    if (videoId) {
      fetchVideo(videoId);
    }
  });

  return {
    ...queryVideo,
    dataVideo: queryVideo.data,
    dataVideoIsLoading: queryVideo.isLoading,
  };
}

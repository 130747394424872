import * as React from "react";
import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  Divider,
  MenuItem,
  Skeleton,
  Stack,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  IOrganization,
  ISearchMatchesParams,
  ISearchMiniGamesAttemptsParams,
  ISearchMiniGamesParams,
  ITeam,
  MiniGamesStatusEnum,
} from "utils/api.interfaces";
import { rowsPerpageOptions } from "components/Pagination";
import useFootballMatches from "hooks/useMatches";
import { MatchIcon } from "components/brandlibrary/MingleIcons";
import { StyledTableCell, StyledTableRowNoPointer } from "utils/common";
import DownloadIcon from "@mui/icons-material/Download";
import FilterAndPaging from "components/FilterAndPaging";
import { getVotesExport } from "../hooks/fetch";
import ButtonTeam from "../components/ButtonTeam";
import ButtonOverviewDetailLink from "../components/ButtonOverviewDetailLink";
import ActionMenu from "../components/ActionMenu";
import DialogDeleteMatch from "../components/DialogDeleteMatch";
import { nanoid } from "nanoid";
import FilterOrganization from "../components/FilterOrganization";
import FilterTeam from "../components/FilterTeam";
import dayjs from "dayjs";
import DateFromToFilter from "../components/DateFromToFilter";
import useMiniGames from "../hooks/useMiniGames";
import useMiniGamesAttempts from "../hooks/useMiniGameAttempts";
import MiniGameStatus from "../components/MiniGamesStatus";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export default function MiniGamesAttempts() {
  let [queryParams, setQueryParams] = useSearchParams();
  const { sessionId } = useParams();
  const initialSearchParams: ISearchMiniGamesAttemptsParams = {
    sessionId: sessionId || "",
    page: queryParams.get("page") || "0",
    pageSize: queryParams.get("pageSize") || rowsPerpageOptions[4].toString(),
  };
  const [searchParams, setSearchParams] = useState(initialSearchParams);

  const { isLoading, dataMiniGamesAttempts } =
    useMiniGamesAttempts(searchParams);
  const navigate = useNavigate();

  // const handleParamsChange = (updatedParam: any) => {
  //   const newParams: any = {
  //     ...Object.fromEntries(new URLSearchParams(queryParams.toString())),
  //     ...updatedParam,
  //   };
  //   console.log(newParams);
  //   setSearchParams(newParams);
  // };
  //
  // React.useEffect(() => {
  //   setQueryParams(searchParams);
  // }, [searchParams]);
  return (
    <Container maxWidth="lg">
      <Button
        size="small"
        variant="outlined"
        onClick={() => navigate(-1)}
        startIcon={<ChevronLeftIcon />}
      >
        back
      </Button>
      <Typography
        component="h1"
        variant="h1"
        color="inherit"
        noWrap
        gutterBottom
        sx={{
          textAlign: "center",
          margin: "40px 0px",
        }}
      >
        MiniGames attempts list
      </Typography>

      <FilterAndPaging
        data={dataMiniGamesAttempts}
        filterPagingParams={initialSearchParams}
        onParamsChange={(e) => {
          // handleParamsChange(e);
        }}
        showSearchField={false}
        TableHeaders={
          <TableRow>
            <StyledTableCell align="right">Attempt Id</StyledTableCell>
            <StyledTableCell align="right">Session Id</StyledTableCell>
            <StyledTableCell align="right">Creator</StyledTableCell>
            <StyledTableCell align="right">Date</StyledTableCell>

            <StyledTableCell align="right">isGoal</StyledTableCell>
            <StyledTableCell align="right">round</StyledTableCell>
            <StyledTableCell align="right" width={150}>
              score
            </StyledTableCell>

            <StyledTableCell align="right">penaltyStatus</StyledTableCell>
          </TableRow>
        }
        filterOptions={<></>}
      >
        {!isLoading ? (
          <>
            {dataMiniGamesAttempts?.attempts?.map((row) => (
              <StyledTableRowNoPointer key={nanoid(6)} hover>
                <StyledTableCell align="right">
                  <ButtonOverviewDetailLink
                    link={`/minigames/attempt/${row.sessionId}/${row.id}`}
                    title={`${row.id}`}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">{row.sessionId}</StyledTableCell>{" "}
                <StyledTableCell align="right">
                  {row.user.firstName} {row.user.lastName}
                </StyledTableCell>
                <StyledTableCell>
                  {new Date(row.dateTimeUtc).toLocaleDateString([], {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.isGoal.toString()}
                </StyledTableCell>
                <StyledTableCell align="left">{row.round}</StyledTableCell>
                <StyledTableCell align="left">
                  <pre>
                    Speed = {row.score?.speed}
                    <br />
                    Accuracy = {row.score?.accuracy}
                    <br />
                    Points = {row.score?.points}
                  </pre>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <MiniGameStatus status={row.penaltyStatus} />
                </StyledTableCell>
              </StyledTableRowNoPointer>
            ))}
          </>
        ) : (
          <StyledTableRowNoPointer key={nanoid(6)} hover>
            <StyledTableCell colSpan={8}>
              <Box>
                {Array(Number(initialSearchParams.pageSize))
                  .fill(1)
                  .map((el, i) => (
                    <Skeleton animation="wave" height={60} key={nanoid(6)} />
                  ))}
              </Box>
            </StyledTableCell>
          </StyledTableRowNoPointer>
        )}
      </FilterAndPaging>
    </Container>
  );
}

import * as React from "react";
import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Skeleton,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  ILeague,
  IOrganization,
  ISearchTeamsParams,
  ManagementRolesEnum,
  RoleEnum,
} from "utils/api.interfaces";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { rowsPerpageOptions } from "components/Pagination";
import { StyledTableCell, StyledTableRowNoPointer } from "utils/common";
import useTeams from "hooks/useTeams";
import { TeamIcon } from "components/brandlibrary/MingleIcons";
import FilterAndPaging from "components/FilterAndPaging";
import ButtonOrganization from "../components/ButtonOrganization";
import ButtonOverviewDetailLink from "../components/ButtonOverviewDetailLink";
import ActionMenu from "../components/ActionMenu";
import { TeamUsersAmount } from "../components/TeamUsersAmount";
import { nanoid } from "nanoid";
import CircularProgress from "@mui/material/CircularProgress";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterOrganization from "../components/FilterOrganization";
import FilterLeague from "../components/FilterLeague";

export default function Teams() {
  const navigate = useNavigate();
  let [queryParams, setQueryParams] = useSearchParams();

  const initialSearchParams: ISearchTeamsParams = {
    page: queryParams.get("page") || "0",
    TeamNameFilter: queryParams.get("TeamNameFilter") || "",
    OrganizationIdFilter: queryParams.get("OrganizationIdFilter") || "",
    LeagueIdFilter: queryParams.get("LeagueIdFilter") || "",
    pageSize: queryParams.get("pageSize") || rowsPerpageOptions[2].toString(),
  };

  const [searchParams, setSearchParams] =
    useState<ISearchTeamsParams>(initialSearchParams);
  const { isLoading, dataTeams } = useTeams(searchParams);
  const handleParamsChange = (updatedParam: any) => {
    const newParams: any = {
      ...Object.fromEntries(new URLSearchParams(initialSearchParams)),
      ...updatedParam,
    };
    setSearchParams(newParams);
  };

  const handleTeamNameFilterChangeFilter = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleParamsChange({
      TeamNameFilter: event.target.value,
      page: "0",
      pageSize: rowsPerpageOptions[2].toString(),
    });
  };
  const handleOrganizationChange = (
    event: React.ChangeEvent<unknown>,
    organization: IOrganization
  ) => {
    organization
      ? handleParamsChange({
          OrganizationIdFilter: organization.id,
          page: "0",
          pageSize: rowsPerpageOptions[2].toString(),
        })
      : handleParamsChange({
          OrganizationIdFilter: "",
          page: "0",
          pageSize: rowsPerpageOptions[2].toString(),
        });
  };
  const handleLeagueChange = (
    event: React.ChangeEvent<unknown>,
    league: ILeague
  ) => {
    league
      ? handleParamsChange({
          LeagueIdFilter: league.id,
          page: "0",
          pageSize: rowsPerpageOptions[2].toString(),
        })
      : handleParamsChange({
          LeagueIdFilter: "",
          page: "0",
          pageSize: rowsPerpageOptions[2].toString(),
        });
  };

  React.useEffect(() => {
    setQueryParams(searchParams);
  }, [searchParams]);
  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        color="inherit"
        noWrap
        gutterBottom
        sx={{
          textAlign: "center",
          margin: "40px 0px",
        }}
      >
        Teams list & search
      </Typography>
      {!isLoading ? (
        <>
          <Button
            component={Link}
            size="small"
            variant="contained"
            color="success"
            to={`/teams/add/`}
            startIcon={<TeamIcon />}
          >
            add new team
          </Button>

          <FilterAndPaging
            data={dataTeams}
            filterPagingParams={initialSearchParams}
            onParamsChange={handleParamsChange}
            showSearchField={false}
            filterOptions={
              <>
                <TextField
                  margin="dense"
                  id="filter"
                  sx={{
                    minWidth: "300px",
                  }}
                  label="Search team name"
                  variant="standard"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleTeamNameFilterChangeFilter(event);
                  }}
                  defaultValue={initialSearchParams.TeamNameFilter}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="" edge="end">
                          {isLoading ? (
                            <CircularProgress size={15} />
                          ) : (
                            <FilterListIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FilterOrganization
                  onOrganizationChange={handleOrganizationChange}
                  initSelectedOrganization={searchParams.OrganizationIdFilter}
                />
                <FilterLeague
                  onLeagueChange={handleLeagueChange}
                  initSelectedLeague={searchParams.LeagueIdFilter}
                />
              </>
            }
            TableHeaders={
              <TableRow>
                <StyledTableCell>Team</StyledTableCell>
                <StyledTableCell>Players</StyledTableCell>
                <StyledTableCell>Trainers</StyledTableCell>
                <StyledTableCell>Supporters</StyledTableCell>
                <StyledTableCell align="right">Organization</StyledTableCell>
                <StyledTableCell align="right">Action</StyledTableCell>
              </TableRow>
            }
          >
            {dataTeams?.teams?.map((team) => (
              <StyledTableRowNoPointer key={nanoid(6)} hover>
                <StyledTableCell>
                  <ButtonOverviewDetailLink
                    link={`/teams/${team.id}`}
                    title={team.name ? team.name : "no known name"}
                  />

                  <Typography variant="body2">
                    Invitecode: {team.inviteCode}
                  </Typography>
                </StyledTableCell>

                <TeamUsersAmount
                  players={team?.members?.filter(
                    (member) => member.role === RoleEnum.Player
                  )}
                  trainers={team?.members?.filter(
                    (member) => member.role === RoleEnum.Trainer
                  )}
                  support={team?.members?.filter(
                    (member) => member.role === RoleEnum.Support
                  )}
                />

                <StyledTableCell align="right">
                  <ButtonOrganization organizationId={team?.organization?.id} />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <ActionMenu
                    itemId={team?.id}
                    handleNewClick={(e) => {
                      navigate(`/teams/add/`);
                    }}
                    handleEditClick={(e, id) => {
                      navigate(`/teams/edit/${id}`);
                    }}
                    showDeleteButton={false}
                  />
                </StyledTableCell>
              </StyledTableRowNoPointer>
            ))}
          </FilterAndPaging>
        </>
      ) : (
        <Box sx={{ marginTop: "70px" }}>
          {Array(Number(initialSearchParams.pageSize))
            .fill(1)
            .map((el, i) => (
              <Skeleton animation="wave" height={60} key={nanoid(6)} />
            ))}
        </Box>
      )}
    </>
  );
}

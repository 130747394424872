import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import * as React from "react";

interface ButtonOverviewDetailLinkProps {
  link: string;
  title?: string | null;
}
const ButtonOverviewDetailLink: React.FC<ButtonOverviewDetailLinkProps> = ({
  link,
  title,
}) => {
  return (
    <Typography
      variant="h5"
      color="secondary"
      component={Link}
      to={link}
      sx={{
        display: "block",
        textDecoration: "none",

        "&:hover": {
          textDecoration: "underline",
        },
      }}
    >
      {title}
    </Typography>
  );
};

export default ButtonOverviewDetailLink;

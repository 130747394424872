import * as React from "react";
import BrandButtons from "components/brandlibrary/BrandButtons";
import BrandTypography from "components/brandlibrary/BrandTypography";

export default function Brandlibrary() {
  return (
    <>
      <BrandButtons />
      <BrandTypography />
    </>
  );
}

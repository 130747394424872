import * as React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import {
  ITeamEdit,
  ITeamMember,
  ManagementRolesEnum,
  RoleEnum,
} from "utils/api.interfaces";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate, useParams } from "react-router-dom";
import TransferCards from "components/TransferCards";
import { removeDuplicate, Transition } from "utils/common";
import useTeam, { postTeam, postTeamImage, putTeam } from "hooks/useTeam";

import Select from "@mui/material/Select";
import { nanoid } from "nanoid";
import { ErrorMessageSlide } from "../components/ErrorMessageSlide";
import FormControlLabel from "@mui/material/FormControlLabel";
import UploadImage from "../components/UploadImage";
import AutocompleteDebounce from "../components/AutocompleteDebounce";
import useOrganizations from "../hooks/useOrganizations";
import { useLeague } from "../hooks/useLeague";
import DialogAddOpponent from "../components/DialogAddOpponent";
import useOpponents from "../hooks/useOpponents";
import { useQueryClient } from "@tanstack/react-query";
import CardHeader from "@mui/material/CardHeader";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";
import ButtonArchiveTeam from "../components/ArchiveTeamButton";

export default function TeamEdit() {
  const validationSchema = yup.object({
    discriminator: yup
      .string()
      .max(3, "Maximum of 3 uppercase characters.")
      .matches(/^[A-Z]+$/, "Maximum of 3 uppercase characters."),
  });
  const { teamId } = useParams();

  const { dataTeamIsLoading, dataTeam, refetch } = useTeam(teamId);

  const initTeam: ITeamEdit = {
    id: dataTeam?.id || dataTeam?.teamId || "",
    name: dataTeam?.name || "",
    sportType: "Football",
    nameAbbreviation: dataTeam?.nameAbbreviation || "",
    seasonId: dataTeam?.season?.id || "",
    isGhost: dataTeam?.isGhost || false,
    isHistoric: dataTeam?.isHistoric || false,
    members: dataTeam?.members || [],

    logoUrl: dataTeam?.logoUrl || "",
  };
  const [newOpponentId, setNewOpponentId] = React.useState<string>("");
  const [members, setMembers] = React.useState<ITeamMember[] | undefined>(
    dataTeam?.members || []
  );
  const [membersEdited, setEditedMembers] = React.useState<
    ITeamMember[] | undefined
  >(dataTeam?.members || []);
  const { dataOpponents } = useOpponents(teamId);
  const [showOpponentDialog, setShowOpponentDialog] =
    React.useState<boolean>(false);
  const queryClient = useQueryClient();
  React.useEffect(() => {
    queryClient.invalidateQueries(["opponent"]);
  }, [newOpponentId]);
  const navigate = useNavigate();
  const [acceptedFiles, setAcceptedFiles] = React.useState<File[]>([]);
  const [newTeamUpdated, setNewTeamUpdated] = React.useState<Boolean>(false);
  const [showSlideError, setShowSlideError] = React.useState(false);
  const [showAdminSlideError, setShowAdminSlideError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<Boolean>(false);
  const { dataLeague } = useLeague(dataTeam?.league?.id || "");
  const [newMembers, setNewMembers] = React.useState<ITeamMember[]>(
    initTeam?.members
  );
  const [adminId, setAdminId] = React.useState<string>("");

  const formik = useFormik({
    initialValues: initTeam,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values: ITeamEdit) => {
      // if (values?.admins?.length === 0) {
      //   setShowAdminSlideError(true);
      //   return;
      // }
      if (dataTeam) {
        console.log(values);
        setIsLoading(true);

        if (acceptedFiles && acceptedFiles?.length > 0) {
          postTeamImage(acceptedFiles)
            .then((res) => {
              putTeam({
                ...values,
                logoUrl: res.fileUrl,
              })
                .then((res) => {
                  setIsLoading(false);
                  setNewTeamUpdated(true);
                })
                .catch((e: any) => {
                  setShowSlideError(true);
                  setIsLoading(false);
                });
            })
            .catch((e: any) => {
              setShowSlideError(true);
              setIsLoading(false);
            });
        } else {
          putTeam({
            ...values,
          })
            .then((res) => {
              setIsLoading(false);
              setNewTeamUpdated(true);
            })
            .catch((e: any) => {
              setShowSlideError(true);
              setIsLoading(false);
            });
        }
      }
    },
  });

  React.useEffect(() => {
    if (!formik.isValid && formik.submitCount !== 0 && formik.isSubmitting) {
      if (!formik.isValid) {
        window.scrollTo(0, 0);
      }
    }
  }, [formik.submitCount, formik.isValid, formik.errors, formik.isSubmitting]);
  React.useEffect(() => {
    console.log(newMembers);

    formik.setFieldValue(
      "members",
      newMembers.map((m) => {
        return {
          id: m.id,
          role: m.role,
          teamManagementRoles: m.teamManagementRoles,
        };
      })
    );
  }, [newMembers]);

  const setSelectedTeamManagementRoles = (
    memberId: string,
    managementRole: ManagementRolesEnum,
    checked: boolean
  ) => {
    setNewMembers(
      newMembers?.map((m) => {
        if (m.id === memberId) {
          checked
            ? m.teamManagementRoles?.push(managementRole)
            : m.teamManagementRoles?.splice(
                m.teamManagementRoles.indexOf(managementRole),
                1
              );
        }
        console.log(m.teamManagementRoles);
        return m;
      })
    );
  };
  return (
    <>
      <Stack
        direction="row"
        justifyItems="center"
        alignItems="center"
        spacing={6}
      >
        <Typography
          component="h1"
          variant="h1"
          color="inherit"
          noWrap
          gutterBottom
          sx={{
            textAlign: "center",
            margin: "40px auto 40px",
          }}
        >
          Edit team
        </Typography>
        <ButtonArchiveTeam team={dataTeam} refetch={refetch} />
      </Stack>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          spacing={3}
          sx={{
            margin: "auto",
            opacity: newTeamUpdated || isLoading ? 0.4 : 1,
            pointerEvents: isLoading ? "none" : "auto",
          }}
        >
          <UploadImage
            maxFiles={1}
            onAcceptedFilesChange={(files: File[]) => {
              setAcceptedFiles(files);
            }}
          />
          <TextField
            fullWidth
            id="id"
            name="id"
            label="id"
            type="text"
            disabled={true}
            value={formik.values.id}
            onChange={formik.handleChange}
            error={formik.touched.id && Boolean(formik.errors.id)}
            helperText={formik.touched.id && formik.errors.id}
          />
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            fullWidth
            id="nameAbbreviation"
            name="nameAbbreviation"
            label="Abbreviation"
            type="text"
            value={formik.values.nameAbbreviation}
            onChange={formik.handleChange}
            error={
              formik.touched.nameAbbreviation &&
              Boolean(formik.errors.nameAbbreviation)
            }
            helperText={
              formik.touched.nameAbbreviation && formik.errors.nameAbbreviation
            }
          />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" shrink={true}>
              SportType
            </InputLabel>
            <Select
              labelId="sportType"
              id="sportType"
              value={formik.values.sportType}
              label="sportType"
              onChange={formik.handleChange}
            >
              <MenuItem value="Football">Football</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            sx={{
              margin: " 20px auto",
            }}
            control={
              <Switch
                checked={formik.values.isGhost}
                inputProps={{ "aria-label": "controlled" }}
                value={formik.values.isGhost}
                onChange={(e) => {
                  e.target.checked
                    ? formik.setFieldValue("isGhost", true)
                    : formik.setFieldValue("isGhost", false);
                }}
              />
            }
            label="Team is a ghost team"
          />
          <FormControlLabel
            sx={{
              margin: " 20px auto",
            }}
            control={
              <Switch
                checked={formik.values.isHistoric}
                inputProps={{ "aria-label": "controlled" }}
                value={formik.values.isHistoric}
                onChange={(e) => {
                  e.target.checked
                    ? formik.setFieldValue("isHistoric", true)
                    : formik.setFieldValue("isHistoric", false);
                }}
              />
            }
            label="Team is a Historic team"
          />
          <TextField
            fullWidth
            id="leagueId"
            name="leagueId"
            label="leagueId"
            type="text"
            disabled={true}
            value={dataTeam?.league?.id}
          />
          <TextField
            fullWidth
            id="organizationId"
            name="organizationId"
            label="organizationId"
            type="text"
            disabled={true}
            value={dataTeam?.organization?.id}
          />

          {dataLeague && (
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label" shrink={true}>
                Season
              </InputLabel>
              <Select
                labelId="seasonId"
                id="seasonId"
                name="seasonId"
                defaultValue={dataTeam?.season?.id}
                label="seasonId"
                onChange={formik.handleChange}
              >
                {dataLeague?.seasons?.map((season) => (
                  <MenuItem key={nanoid(6)} value={season.id}>
                    {season.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {dataTeam && (
            <>
              <TransferCards
                initSelectedUsers={dataTeam?.members}
                selectedTitle="members"
                selectedUsers={(members) => {
                  setNewMembers(
                    members.map((m) => {
                      if (!m.teamManagementRoles) {
                        m.teamManagementRoles = [];
                      }
                      return m;
                    })
                  );
                }}
              />
              <Card>
                <CardContent>
                  <Stack
                    spacing={2}
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    alignContent="center"
                  >
                    <table cellPadding={10} cellSpacing={2}>
                      <thead>
                        <tr>
                          <th colSpan={8}>
                            {" "}
                            <Typography
                              variant="h1"
                              sx={{
                                fontWeight: "700",
                                marginBottom: "16px",
                                fontSize: "20px",
                                textAlign: "center",
                              }}
                            >
                              Set the roles & permissions
                            </Typography>
                          </th>
                        </tr>
                        <tr>
                          <th align={"left"}></th>
                          <th>Player</th>
                          <th>Trainer</th>
                          <th style={{ paddingRight: "60px" }}>Support</th>
                          <th>Admin</th>
                          <th>Content Creator</th>
                          <th>Scheduler</th>
                          <th>Scorekeeper</th>
                        </tr>
                      </thead>
                      <tbody>
                        {newMembers?.map((member) => {
                          return (
                            <tr>
                              <td align={"left"}>
                                <Stack
                                  spacing={0}
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="space-between"
                                  alignContent="space-between"
                                  key={member.id}
                                  onClick={() =>
                                    navigate(
                                      !member.id.includes("pre", 0)
                                        ? `/users/${member.id} `
                                        : `/users/pre/${member.id} `
                                    )
                                  }
                                >
                                  <Stack
                                    spacing={2}
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    alignContent="center"
                                    sx={{ width: "320px", cursor: "pointer" }}
                                  >
                                    <Avatar
                                      alt={`${member.firstName} ${member.lastName}`}
                                      src={
                                        member.imageUrl
                                          ? member.imageUrl
                                          : member.profileImageUrl
                                      }
                                      sx={{ width: 24, height: 24 }}
                                    />
                                    {member.isPreUser && <>🥚</>}
                                    <ListItemText
                                      primary={`${member.firstName} ${member.lastName}`}
                                      secondary={`${member.email}`}
                                    />
                                  </Stack>
                                </Stack>
                              </td>
                              <td align={"center"}>
                                <Radio
                                  name={`role-${member.id}`}
                                  value={RoleEnum.Player}
                                  disabled={member.isPreUser}
                                  checked={member.role === RoleEnum.Player}
                                  onChange={(e) => {
                                    setNewMembers(
                                      newMembers?.map((m) => {
                                        if (m.id === member.id) {
                                          m.role = e.target.value as RoleEnum;
                                        }
                                        return m;
                                      })
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <Radio
                                  name={`role-${member.id}`}
                                  checked={member.role === RoleEnum.Trainer}
                                  value={RoleEnum.Trainer}
                                  disabled={member.isPreUser}
                                  onChange={(e) => {
                                    setNewMembers(
                                      newMembers?.map((m) => {
                                        if (m.id === member.id) {
                                          m.role = e.target.value as RoleEnum;
                                        }
                                        return m;
                                      })
                                    );
                                  }}
                                />
                              </td>
                              <td
                                align={"center"}
                                style={{ paddingRight: "60px" }}
                              >
                                <Radio
                                  name={`role-${member.id}`}
                                  value={RoleEnum.Support}
                                  disabled={member.isPreUser}
                                  checked={member.role === RoleEnum.Support}
                                  onChange={(e) => {
                                    setNewMembers(
                                      newMembers?.map((m) => {
                                        if (m.id === member.id) {
                                          m.role = e.target.value as RoleEnum;
                                        }
                                        return m;
                                      })
                                    );
                                  }}
                                />
                              </td>
                              <td align={"center"}>
                                <Checkbox
                                  title={"Admin"}
                                  sx={{}}
                                  checked={member.teamManagementRoles?.includes(
                                    ManagementRolesEnum.admin
                                  )}
                                  disabled={member.isPreUser}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setSelectedTeamManagementRoles(
                                      member.id,
                                      ManagementRolesEnum.admin,
                                      e.target.checked
                                    );
                                  }}
                                />
                              </td>
                              <td align={"center"}>
                                <Checkbox
                                  title={"ContentCreator"}
                                  sx={{}}
                                  checked={member.teamManagementRoles?.includes(
                                    ManagementRolesEnum.contentCreator
                                  )}
                                  disabled={member.isPreUser}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setSelectedTeamManagementRoles(
                                      member.id,
                                      ManagementRolesEnum.contentCreator,
                                      e.target.checked
                                    );
                                  }}
                                />
                              </td>
                              <td align={"center"}>
                                <Checkbox
                                  title={"Scheduler"}
                                  sx={{}}
                                  checked={member.teamManagementRoles?.includes(
                                    ManagementRolesEnum.scheduler
                                  )}
                                  disabled={member.isPreUser}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setSelectedTeamManagementRoles(
                                      member.id,
                                      ManagementRolesEnum.scheduler,
                                      e.target.checked
                                    );
                                  }}
                                />
                              </td>
                              <td align={"center"}>
                                <Checkbox
                                  title={"Scorekeeper"}
                                  checked={member.teamManagementRoles?.includes(
                                    ManagementRolesEnum.scorekeeper
                                  )}
                                  disabled={member.isPreUser}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setSelectedTeamManagementRoles(
                                      member.id,
                                      ManagementRolesEnum.scorekeeper,
                                      e.target.checked
                                    );
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Stack>
                </CardContent>
              </Card>
            </>
          )}
          <Card>
            <CardContent>
              <Typography variant={"h3"} sx={{ marginBottom: "20px" }}>
                Opponents
              </Typography>
              <Stack direction="row" flexWrap="wrap" gap={2}>
                {dataOpponents?.opponents.map((opponent) => {
                  return (
                    <Button
                      color={"secondary"}
                      key={nanoid(6)}
                      sx={{
                        cursor: "pointer",
                        fontSize: "12px",
                        height: "42px",
                        pointerEvents: "none",
                        whiteSpace: "nowrap",
                      }}
                      disabled={true}
                      size="small"
                      startIcon={
                        <Avatar
                          sx={{
                            backgroundColor: "#fff",
                            marginRight: "6px",
                            width: "35px",
                            height: "35px",
                          }}
                          src={opponent.logoUrl}
                        ></Avatar>
                      }
                      variant="outlined"
                    >
                      {opponent.name}
                    </Button>
                  );
                })}
              </Stack>
              <Button
                color="secondary"
                size={"small"}
                variant="outlined"
                onClick={() => setShowOpponentDialog(true)}
                sx={{ marginTop: "20px", marginLeft: "auto" }}
              >
                Add new opponent
              </Button>
            </CardContent>
          </Card>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            endIcon={
              isLoading && (
                <IconButton aria-label="" edge="end">
                  <CircularProgress
                    size={15}
                    sx={{ color: "#68F3CB" }}
                    color="success"
                  />
                </IconButton>
              )
            }
          >
            Update team
          </Button>
          <ErrorMessageSlide showError={showSlideError} />
          <ErrorMessageSlide
            showError={showAdminSlideError}
            errorMessage={"The team needs to have at least 1 Admin role"}
          />
        </Stack>

        <Dialog
          open={!!newTeamUpdated}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            setNewTeamUpdated(false);
          }}
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{
            sx: { backgroundColor: "#fff", minWidth: "400px" },
          }}
        >
          <DialogTitle variant="h4">{"Team successfully updated!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              onClick={() => {
                setNewTeamUpdated(false);
              }}
            >
              close
            </Button>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={() => navigate(`/teams/${dataTeam?.id}`)}
            >
              Go to team page
            </Button>
          </DialogActions>
        </Dialog>
      </form>
      <DialogAddOpponent
        team={dataTeam}
        isOpen={showOpponentDialog}
        onIsOpenChange={(value) => {
          setShowOpponentDialog(value);
        }}
        onIsOpponentAdded={(val, newOpponentId) => {
          setNewOpponentId(newOpponentId);

          setShowOpponentDialog(false);
        }}
      />
    </>
  );
}

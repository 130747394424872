import React from "react";
import { storage } from "utils/api.interfaces";
import axiosinterceptor from "../utils/api.interceptor";

let AuthContext = React.createContext<any | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  let [token, setToken] = React.useState<string>(storage.getToken());

  let value = { token };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a authProvider");
  }
  return context;
};

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  let { token } = useAuth();
  if (token === "") {
    axiosinterceptor.get(`/v1/Security/Login`, {}).then((res) => {
      window.location.href = res.data;
    });
  }
  return children;
};

import React from "react";

export enum PrivacyEnum {
  Private = "Private",
  Public = "Public",
  Followers = "Followers",
}
export enum PremiumLevel {
  Basic = "Basic",
  Pro = "Pro",
  Elite = "Elite",
}
export enum AccountRoleEnum {
  Player = "player",
  Trainer = "trainer",
  Support = "support",
  Fan = "fan",
  undefined = "undefined",
}
export enum HomeAwayEnum {
  Home = "Home",
  Away = "Away",
}
export enum PreferredPositionEnum {
  Goalkeeper = "GK",
  Centreback = "CB",
  Leftback = "LB",
  Rightback = "RB",
  DefensiveMidfielder = "CDM",
  CentralMidfielder = "CM",
  LeftMidfielder = "LM",
  RightMidfielder = "RM",
  AttackingMidfielder = "CAM",
  LeftWinger = "LW",
  RightWinger = "RW",
  Striker = "ST",
}
export enum TeamType {
  knvb = "knvb",
  custom = "custom",
}
export interface IFilterOrganizationParams {
  [key: string]: string;
  page: string;
  LeagueId: string;
  NameFilter: string;
  pageSize: string;
}
export interface IFilterPagingParams {
  [key: string]: string;
  page: string;
  filter: string;
  pageSize: string;
}
export interface ISearchLeaguesParams {
  [key: string]: string;
  page: string;
  CountryCode: string;
  NameFilter: string;
  pageSize: string;
}
export interface ISearchTeamsParams {
  [key: string]: string;
  page: string;
  TeamNameFilter: string;
  OrganizationIdFilter: string;
  LeagueIdFilter: string;
  pageSize: string;
}

export interface IMiniGamesAccuracyParams {
  GoalPositionX: number;
  GoalPositionY: number;
}
export interface IMiniGamesAttemptParams {
  sessionId?: string;
  attemptId?: string;
}
export interface ISearchMiniGamesAttemptsParams {
  [key: string]: string;
  sessionId: string;
  page: string;

  pageSize: string;
}
export interface ISearchMiniGamesParams {
  [key: string]: string;
  page: string;
  status: string;
  pageSize: string;
}
export interface ISearchMatchesParams {
  [key: string]: string;
  page: string;
  TeamIdFilter: string;
  OrganizationIdFilter: string;
  pageSize: string;
  "ScheduledAtFilter.From": string;
  "ScheduledAtFilter.To": string;
}
export interface ISearchActivityParams {
  [key: string]: string;
  page: string;
  filter: string;
  SearchFilter: string;
  pageSize: string;
  TeamIdFilter: string;
  OrganizationIdFilter: string;
  PublishStateFilter: PublishState | string;
  "CreatedAtFilter.From": string;
  "CreatedAtFilter.To": string;
}
// Activity interfaces
export interface IActivitiesApiCall {
  activities: IActivities[];
  page: number;
  total: number;
}

export enum PublishState {
  Published = "published",
  Draft = "draft",
}
export enum ThemeColor {
  Primary = "primary",
  Secondary = "secondary",
}
export enum ActivityAudience {
  All = "all",
  Team = "team",
  Organization = "organization",
}

export enum ManagementRolesEnum {
  "admin" = "admin",
  "scheduler" = "scheduler",
  "scorekeeper" = "scorekeeper",
  "contentCreator" = "contentCreator",
}
export enum RoleEnum {
  "Player" = "Player",
  "Trainer" = "Trainer",
  "Support" = "Support",
}
export enum MiniGamesStatusEnum {
  verified = "verified",
  correct = "correct",
  error = "error",
  disputed = "disputed",
  undefined = "undefined",
  verificationRequired = "verificationRequired",
  verifying = "verifying",
}
export enum OrganizationEnum {
  Default = "default",
  Club = "club",
  HighSchool = "highSchool",
  University = "university",
  College = "college",
}

export interface IExportData {
  organizationId?: string;
  teamId?: string;
  startDate: string;
  endDate: string;
}

export interface IActivityContentButton {
  text: string | null;
  url: string | null;
  style: ThemeColor | null;
}
export interface IActivityMediaContent {
  type: string | null;
  imageName?: string | null;
  imageUrl?: string;
  VideoUrl?: string;
  videoUrl?: string;
  ThumbnailUrl?: string;
  sas?: string;
}
export interface IActivityContent {
  title?: string | null;
  contentTitle?: string | null;
  contentBody?: string | null;
  pushNotificationContentTitle?: string | null;
  pushNotificationContentBody?: string | null;
  button?: IActivityContentButton | null;
  mediaContent?: IActivityMediaContent | null;
}
export interface IActivityAdd {
  teams: string[];
  organizations: string[];
  activityAudience?: ActivityAudience;
  shareWithFollowers: boolean;
  pinned: boolean;
  publishState: PublishState;
  sendPublishNotification: boolean;
  content: {
    nl: IActivityContent;
    en: IActivityContent;
  };
}

export interface IActivities {
  createdAtUtc: string;
  updatedAtUtc: string;
  publishedAtUtc: string;

  id: string;
  teams: ITeam[];
  isRemoved: boolean;
  organizations: IOrganization[];
  shareWithFollowers: boolean;
  pinned: boolean;
  publishState: PublishState;
  sendPublishNotification: boolean;
  content: {
    nl: IActivityContent;
    en: IActivityContent;
  };
}

export interface IActivityEdit {
  teams: string[];
  organizations: string[];
  shareWithFollowers: boolean;
  pinned: boolean;
  publishState: PublishState;
  content: {
    nl: IActivityContent;
    en: IActivityContent;
  };
}
export interface IMiniGamesApiCall {
  sessions: IMiniGames[];
  page: number;
  total: number;
}
export interface IMiniGamesAttemptsApiCall {
  attempts: IMiniGamesAttempts[];
  page: number;
  total: number;
}
// Team interfaces
export interface IMiniGamesAttempt {
  attemptScore: {
    speed: number;
    accuracy: number;
    points: number;
    isGoal: boolean;
    goalPosition: {
      x: number;
      y: number;
    };
    video: {
      videoUrl: string;
      originalVideoUrl: string;
      downloadableVideoUrl: string;
    };
    shotFrame: number;
  };
  verificationScore: {
    speed: number;
    accuracy: number;
    points: number;
    isGoal: boolean;
    goalPosition: {
      x: number;
      y: number;
    };
    video: {
      videoUrl: string;
      originalVideoUrl: string;
      downloadableVideoUrl: string;
    };
    shotFrame: number;
  };
  verificationStatus: string;
  dateTimeUtc: string;
  round: number;
}
export interface IMiniGamesAttempts {
  id: string;
  sessionId: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    imageUrl: string;
  };
  dateTimeUtc: string;
  isGoal: boolean;
  round: number;

  score: {
    speed: 0;
    accuracy: 0;
    points: 0;
  };
  penaltyStatus: MiniGamesStatusEnum;
  verificationStatus: string;
}

export interface IMiniGames {
  id: string;
  name: string;
  type: string;
  dateTimeUtc: string;
  createdBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
  numberOfParticipants: 0;
  numberOfAttempts: 0;
  status: MiniGamesStatusEnum;
}
export interface IMatchesApiCall {
  matches: IMatches[];
  page: number;
  total: number;
}
export interface IMatches {
  id: string;
  team: ITeamInMatch;
  opponent: ITeamInMatch;
  scheduledAtUtc: string;
  arrivalTimeUtc: string;
  arrivalLocation: string;
}
export interface IMatchAdd {
  teamId: string;
  opponentId: string;
  scheduledAtUtc: string;

  isAway: boolean;
  arrivalTimeUtc: string;
  arrivalLocation: string;
}
export interface IMatchEdit {
  matchId: string | undefined;
  homeTeamId: string | undefined;
  scheduledAtUtc: string | undefined;
  arrivalTimeUtc?: string | undefined;
  arrivalLocation?: string;
}
export interface IOpponentAdd {
  id?: string;
  name: string;
  teamId?: string;
  organizationId?: string;
}
export interface IOpponent {
  id: string;
  name: string;
  teamId: string;
  nameAbbreviation: string;
  logoUrl: string;
  organization: {
    id: string;
    name: string;
    shortName: string;
    logoUrl: string;
    countryCode: string;
    type: string;
  };
}
export interface IOpponents {
  opponents: IOpponent[];
}
//Team Interfaces
export interface ITeamsApiCall {
  teams: ITeam[];
  page: number;
  total: number | undefined;
}
export interface ITeamAdd {
  id?: string;
  teamId?: string;
  name?: string;
  nameAbbreviation?: string;
  sportType?: string;
  isGhost?: boolean;
  isHistoric?: boolean;
  organizationId?: string;
  seasonId?: string;
  leagueId?: string;
  logoUrl?: string;
  members: ITeamMember[];
}
export interface ITeamEdit {
  id?: string;
  name?: string;
  nameAbbreviation?: string;
  sportType?: string;
  members: ITeamMember[];
  isGhost?: boolean;
  isHistoric?: boolean;
  logoUrl?: string;
  seasonId?: string;
}
export interface ITeam {
  id: string;
  teamId?: string;
  fullName?: string;
  shortName?: string;
  name?: string;
  nameAbbreviation?: string;
  organizationId?: string;
  leagueId?: string;
  isGhost?: boolean;
  isArchived?: boolean;
  logoUrl?: string;
  isHistoric?: boolean;
  inviteCode: string;
  sportType: string;
  inviteLink: string;
  season: ISeason;
  members: ITeamMember[];
  players: ITeamMember[];
  trainers: ITeamMember[];
  support: ITeamMember[];
  // adminIds: string[];
  // schedulerIds: string[];
  // contentCreatorIds: string[];
  // scorekeeperIds: string[];
  league?: ILeague;

  organization: IOrganization | null;
  type: TeamType;
}

export interface ITeamInMatch {
  id: string;
  name: string;
  nameAbbreviation?: string;
  logoUrl?: string;
  isHome: boolean;
  league?: ILeague;
}

export interface ILeaguesApiCall {
  leagues: ILeague[];
  page: number;
  total: number;
}
export interface ISeason {
  id?: string;
  name: string;
  startsAtUtc: string;
  endsAtUtc: string;
  isActive?: boolean;
}
export interface ILeagueAdd {
  id?: string;
  name: string;
  logoUrl?: string;
  sportType: string;
  countryCode: string;
  isVerified?: boolean;
  isGhost: boolean;
  isHighlighted: boolean;
  isDefault: boolean;
  seasons: ISeason[];
  parentLeagueId?: string;
}
export interface ILeague {
  id: string;
  name: string;
  shortName: string;
  countryCode: string;
  sportType: string;
  isVerified?: boolean;
  isHighlighted: boolean;
  isGhost: boolean;
  isDefault: boolean;
  logoUrl?: string | undefined;
  logoName?: string | undefined;
  parentLeagueId: string;
  seasons: ISeason[];
}

//Organizations Interfaces
export interface IOrganizarionsApiCall {
  organizations: IOrganization[];
  page: number;
  total: number;
}
export interface ITeamBooster {
  userId: string;
  displayName: string;
  expiresAtUtc: string;
  willRenew: boolean;
}
export interface ITeamBoost {
  teamBoostId: string;
  userId: string;
  teamId: string;
  source: string;
  expiresAtUtc: string;
  willRenew: boolean;
}
export interface ITeamBoostPerUser {
  availableBoosts: number;
  teamBoosts: ITeamBoost[];
}

export interface IBoostStatus {
  boostLevel: number;
  teamBoosters: ITeamBooster[];
}
export interface IOrganizationAdd {
  id?: string;
  name: string;
  shortName: string;
  logoUrl?: string | undefined;
  logoName?: string | undefined;
  isGhost: boolean;
  leagueId: string | null;
  countryCode: string | null;
  type?: OrganizationEnum;
  sportType: string | null;
  address: {
    line1: string;
    line2: string;
    zipCode: string;
    city: string;
    region: string;
  };
}
export interface IOrganization {
  id: string;
  name: string;
  shortName: string;
  leagueId: string | null;
  countryCode: string | null;
  logoUrl?: string | undefined;
  logoName?: string | undefined;
  isGhost: boolean;
  type?: OrganizationEnum;
  sportType: string | null;
  address: {
    line1: string;
    line2: string;
    zipCode: string;
    city: string;
    region?: string;
    mapImageUrl?: string;
    coordinates?: {
      lat: number;
      lon: number;
    };
  };
}

//User Interfaces
export interface IUsersApiCall {
  users: IUsers[];
  page: number;
  total: number;
}
export interface IUsers {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isGhost: boolean;
  premiumLevel: PremiumLevel;
  profileImageUrl: string | undefined;
  imageUrl: string | undefined;
  isPreUser: boolean;
}
export interface ITeamMember {
  id: string;
  role: RoleEnum;
  teamManagementRoles?: ManagementRolesEnum[];
  firstName?: string;
  lastName?: string;
  email?: string;
  imageUrl?: string | undefined;
  profileImageUrl?: string | undefined;
  isPreUser?: boolean;
  premiumLevel?: string;
}
export interface IUser {
  id: string;
  email: string;
  dateOfBirth: string;
  isVerified: boolean;
  isGhost?: boolean;
  accountRole?: AccountRoleEnum;
  premiumLevel?: PremiumLevel;
  teams?: [{ team: ITeam; userRole: string }];
  profile: {
    firstName: string;
    lastName: string;
    privacy: PrivacyEnum;
    imageUrl: string | undefined;
    preferredPosition: PreferredPositionEnum | undefined;
  };
  clubAdminOf?: string[];
}
export interface IUserEdit {
  birthday: string;
  email: string;
  isGhost: boolean;
  accountRole?: AccountRoleEnum;
  profile: {
    firstName: string;
    lastName: string;
    privacy: PrivacyEnum;
    imageFileName: string | undefined;
    preferredPosition: PreferredPositionEnum | string;
  };
  clubAdminOf?: string[];
}
export interface IUserAdd {
  password: string;
  email: string;
  birthday: string;
  isGhost: boolean;
  accountRole?: AccountRoleEnum;
  profile: {
    firstName: string;
    lastName: string;
    privacy: PrivacyEnum;
    imageFileName: string | null;
    preferredPosition: PreferredPositionEnum | string;
  };
  clubAdminOf?: string[];
}
export const storage = {
  getToken: () =>
    JSON.parse(window.localStorage.getItem("token") || JSON.stringify("")),
  setToken: (token: string | null) =>
    window.localStorage.setItem("token", JSON.stringify(token)),
  clearToken: () => window.localStorage.removeItem("token"),
};
export function getCountries(lang = "en") {
  const A = 65;
  const Z = 90;
  const countryName = new Intl.DisplayNames([lang], { type: "region" });
  const countries = [];
  for (let i = A; i <= Z; ++i) {
    for (let j = A; j <= Z; ++j) {
      let code = String.fromCharCode(i) + String.fromCharCode(j);
      let name = countryName.of(code);
      if (code !== name) {
        countries.push({ code, name });
      }
    }
  }
  return countries;
}

import * as React from "react";

import { Avatar, Button, CardActions, Stack, Typography } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { UserIcon } from "../components/brandlibrary/MingleIcons";
import OrganizationIcon from "@mui/icons-material/Edit";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import useTeams from "../hooks/useTeams";
import { ILeague, ISearchTeamsParams, ITeam } from "../utils/api.interfaces";
import ButtonTeam from "../components/ButtonTeam";
import { TeamUsers } from "../components/TeamUsers";
import ButtonExportData from "../components/ButtonExportData";
import { useOrganization } from "../hooks/useOrganization";
import { useLeague } from "../hooks/useLeague";
import { rowsPerpageOptions } from "../components/Pagination";
import ButtonLeague from "../components/ButtonLeague";

export default function Organization() {
  const { organizationId } = useParams();
  const { dataOrganization } = useOrganization(organizationId);
  const navigate = useNavigate();
  const initialSearchParams: ISearchTeamsParams = {
    page: "0",
    TeamNameFilter: "",
    OrganizationIdFilter: organizationId || "",
    LeagueIdFilter: "",
    pageSize: "1000",
  };
  const { isLoading, dataTeams } = useTeams(initialSearchParams);
  const { dataLeague } = useLeague(dataOrganization?.leagueId || "");

  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        color="inherit"
        noWrap
        gutterBottom
        sx={{
          textAlign: "center",
          margin: "40px 0px",
        }}
      >
        Organization detail page
      </Typography>
      <Stack
        spacing={3}
        sx={{
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Card sx={{ width: "100%", maxWidth: "850px", margin: "auto" }}>
          <CardContent>
            <Typography
              variant="h1"
              color="text.secondary"
              align="center"
              gutterBottom
            >
              {dataOrganization?.name}
            </Typography>

            <Avatar
              src={dataOrganization?.logoUrl}
              sx={{ width: 175, height: 175, margin: "auto" }}
            />

            <TableContainer>
              <Table aria-label="user details table">
                <TableBody>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      colSpan={2}
                    ></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      id:
                    </TableCell>
                    <TableCell align="right">{dataOrganization?.id}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      shortName:
                    </TableCell>
                    <TableCell align="right">
                      {dataOrganization?.shortName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      League:
                    </TableCell>
                    <TableCell align="right">
                      <ButtonLeague leagueId={dataLeague?.id} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      isGhost:
                    </TableCell>
                    <TableCell align="right">
                      {dataOrganization?.isGhost.toString()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Type:
                    </TableCell>
                    <TableCell align="right">
                      {dataOrganization?.type}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      CountryCode:
                    </TableCell>
                    <TableCell align="right">
                      {dataOrganization?.countryCode}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      SportType:
                    </TableCell>
                    <TableCell align="right">
                      {dataOrganization?.sportType}
                    </TableCell>
                  </TableRow>
                  {/*<TableRow>*/}
                  {/*  <TableCell colSpan={2}>*/}
                  {/*    <Stack direction="column" spacing={2}>*/}
                  {/*      <Typography*/}
                  {/*        variant="h4"*/}
                  {/*        color="text.secondary"*/}
                  {/*        gutterBottom*/}
                  {/*      >*/}
                  {/*        Export organization data*/}
                  {/*      </Typography>*/}
                  {/*      <ButtonExportData*/}
                  {/*        organizationId={dataOrganization?.id}*/}
                  {/*      />*/}
                  {/*    </Stack>*/}
                  {/*  </TableCell>*/}
                  {/*</TableRow>*/}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      address:
                    </TableCell>
                    <TableCell align="right">
                      {dataOrganization?.address?.line1}
                      <br />
                      {dataOrganization?.address?.line2}
                      <br />
                      {dataOrganization?.address?.zipCode}
                      <br />
                      {dataOrganization?.address?.city}
                      <br />
                      {dataOrganization?.address?.region}
                      <br />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      location:
                    </TableCell>
                    <TableCell align="right">
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${dataOrganization?.address?.coordinates?.lat},${dataOrganization?.address?.coordinates?.lon}`}
                        target="_blank"
                      >
                        <img src={dataOrganization?.address?.mapImageUrl} />
                        <br />
                        {dataOrganization?.address?.coordinates?.lat},
                        {dataOrganization?.address?.coordinates?.lon}
                      </a>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography
                        variant="h2"
                        color="text.secondary"
                        gutterBottom
                      >
                        Organization teams
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={1}
                        flexWrap="wrap"
                        justifyContent="flex-end"
                      >
                        {dataTeams?.teams.map((team: ITeam) => {
                          return (
                            <div
                              style={{
                                marginBottom: "8px",
                              }}
                              key={"button" + team.id}
                            >
                              <ButtonTeam team={team} />
                            </div>
                          );
                        })}
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>

          <CardActions>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="space-between"
              spacing={2}
              sx={{ width: "100%", padding: 2 }}
            >
              <Button
                component={Link}
                size="small"
                variant="contained"
                color="success"
                to={`/organizations/add/`}
                startIcon={<UserIcon />}
              >
                add new Organization
              </Button>
              <Button
                component={Link}
                size="small"
                variant="outlined"
                color="secondary"
                to={`/organizations/edit/${dataOrganization?.id}`}
                startIcon={<OrganizationIcon />}
              >
                edit
              </Button>
            </Stack>
          </CardActions>
        </Card>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="space-between"
          spacing={2}
          sx={{ width: "100%", maxWidth: "850px", margin: "auto" }}
        >
          <Button
            size="small"
            variant="outlined"
            onClick={() => navigate(-1)}
            startIcon={<ChevronLeftIcon />}
          >
            back
          </Button>
        </Stack>
      </Stack>
    </>
  );
}

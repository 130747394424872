import * as React from "react";

import { Button, Stack, Typography } from "@mui/material";
import { storage } from "utils/api.interfaces";

import { useSearchParams } from "react-router-dom";

export default function NotLoggedIn() {
  let [searchParams, setSearchParams] = useSearchParams();
  if (searchParams.get("token")) {
    storage.setToken(searchParams.get("token"));
  }
  React.useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = "/";
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      spacing={2}
      sx={{ width: "100%", height: "100vh" }}
    >
      <Typography variant="h1"> You've succesfully logged in!</Typography>
      <Button component="a" href="/" variant="contained" color="secondary">
        Click here to go to the homepage or wait 3 seconds!
      </Button>
    </Stack>
  );
}

import * as React from "react";
import { useMemo } from "react";
import { Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";

interface UploadImageProps {
  maxFiles: number;
  onAcceptedFilesChange: (acceptedFiles: File[]) => void;
}
export const UploadImage: React.FC<UploadImageProps> = ({
  maxFiles,
  onAcceptedFilesChange,
}) => {
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: { "image/*": [".png", ".jpg", ".jpeg"] },
    maxFiles: maxFiles,
  });
  const baseStyle = {
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#8eec7b",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    cursor: "pointer",
    outline: "none",
    transition: "border .24s ease-in-out",
  };
  const activeStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const acceptedFileItems = acceptedFiles.map((file: File) => {
    return (
      <div key={file.name}>
        <img
          src={URL.createObjectURL(file)}
          style={{ width: "200px", height: "auto" }}
        />
        <Typography variant="body2">
          {file.name} - {file.size} bytes
        </Typography>
      </div>
    );
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  React.useEffect(() => {
    onAcceptedFilesChange(acceptedFiles);
  }, [acceptedFileItems]);

  return (
    <div style={{ textAlign: "center" }}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <Typography variant="body1">
          Drag 'n' drop image here, or click to select one
        </Typography>
        <Typography variant="body2" gutterBottom>
          (Only 1 *.jpg or *.png image will be accepted)
        </Typography>
        {acceptedFileItems}
        <ul>{fileRejectionItems}</ul>
      </div>
    </div>
  );
};
export default UploadImage;

import * as React from "react";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  IconButton,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import {
  IActivitiesApiCall,
  IOrganizarionsApiCall,
  ILeaguesApiCall,
  IMatchesApiCall,
  ITeamsApiCall,
  IUsersApiCall,
  IMiniGamesApiCall,
  IMiniGamesAttemptsApiCall,
} from "utils/api.interfaces";
import FilterListIcon from "@mui/icons-material/FilterList";
import Pagination from "components/Pagination";
import useDebounce from "hooks/useDebounce";
import CircularProgress from "@mui/material/CircularProgress";

interface FilterPaginationProps {
  data:
    | IUsersApiCall
    | IActivitiesApiCall
    | ITeamsApiCall
    | IMiniGamesApiCall
    | IMiniGamesAttemptsApiCall
    | ILeaguesApiCall
    | IOrganizarionsApiCall
    | IMatchesApiCall
    | undefined;
  onParamsChange: (newParams: any) => void;
  TableHeaders?: React.ReactNode;
  filterOptions?: React.ReactNode;
  showSearchField?: boolean;
  filterPagingParams: any;
}
export const FilterAndPaging: React.FC<FilterPaginationProps> = ({
  children,
  data,
  onParamsChange,
  TableHeaders,
  filterOptions,
  filterPagingParams,
  showSearchField = true,
}) => {
  const [filterInputLoader, setFilterInputLoader] =
    React.useState<Boolean>(false);

  const theme = useTheme();

  const [filterValue, setFilterValue] = useState<string>(
    filterPagingParams.filter ||
      filterPagingParams.NameFilter ||
      filterPagingParams.SearchFilter
  );
  const debouncedValue = useDebounce<string>(filterValue, 500);

  React.useEffect(() => {
    if (filterValue?.length > 0) {
      onParamsChange({
        ...(filterValue !== undefined && { filter: filterValue }),
        ...(filterValue !== undefined && { NameFilter: filterValue }),
        ...(filterValue !== undefined && { SearchFilter: filterValue }),

        page: "0",
      });
      setFilterInputLoader(false);
    }
  }, [debouncedValue]);

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
    setFilterInputLoader(true);
  };

  return (
    <>
      <TableContainer
        sx={{ marginBottom: theme.spacing(8), marginTop: theme.spacing(3) }}
      >
        <Table
          sx={{ minWidth: 650, marginBottom: theme.spacing(8) }}
          aria-label="data table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingBottom: theme.spacing(4),
                }}
                colSpan={9}
              >
                {showSearchField && (
                  <TextField
                    margin="normal"
                    id="filter"
                    sx={{
                      minWidth: "100%",
                      marginBottom: "32px",
                    }}
                    label="Search"
                    variant="standard"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleChangeFilter(event);
                    }}
                    defaultValue={filterPagingParams.filter}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="" edge="end">
                            {filterInputLoader ? (
                              <CircularProgress size={15} />
                            ) : (
                              <FilterListIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                <Stack
                  spacing={2}
                  direction="row"
                  alignContent="center"
                  alignItems="center"
                >
                  {filterOptions}
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <Pagination
                count={data?.total ? Number(data?.total) : 0}
                page={Number(filterPagingParams.page)}
                rowsPerPage={Number(filterPagingParams.pageSize)}
                colSpan={10}
                onPaginationPageChange={(newPage) =>
                  onParamsChange({ page: newPage.toString() })
                }
                onPaginationChangePageSize={(size) =>
                  onParamsChange({
                    pageSize: size.toString(),
                    page: filterPagingParams.page,
                  })
                }
              />
            </TableRow>
            {TableHeaders}
          </TableHead>
          <TableBody>{children}</TableBody>
          <TableFooter>
            <TableRow>
              <Pagination
                count={data?.total ? Number(data?.total) : 0}
                page={Number(filterPagingParams.page)}
                rowsPerPage={Number(filterPagingParams.pageSize)}
                colSpan={10}
                onPaginationPageChange={(newPage) =>
                  onParamsChange({ page: newPage.toString() })
                }
                onPaginationChangePageSize={(size) =>
                  onParamsChange({
                    pageSize: size.toString(),
                    page: filterPagingParams.page,
                  })
                }
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};
export default FilterAndPaging;

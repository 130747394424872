import * as React from "react";
import {
  Autocomplete,
  Avatar,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";

import useDebounce from "hooks/useDebounce";
import CircularProgress from "@mui/material/CircularProgress";

interface AutocompleteDebounceProps {
  data: any;
  setDefaultValueWithId?: any;
  onFilterChange?: (newFilter: string) => void;
  handleChange: (event: React.ChangeEvent<unknown>, value: any) => void;
  labelTitle: string;

  formikErrors?: any;
  formikTouched?: any;
}
export const AutocompleteDebounce: React.FC<AutocompleteDebounceProps> = ({
  data,
  setDefaultValueWithId,
  onFilterChange,
  handleChange,
  labelTitle,
  formikErrors,
  formikTouched,
}) => {
  const [filterInputLoader, setFilterInputLoader] =
    React.useState<Boolean>(false);

  const [FilterInputValue, setFilterInputValue] = React.useState<string>("");
  const debouncedValue = useDebounce<string>(FilterInputValue, 500);

  React.useEffect(() => {
    if (onFilterChange) {
      onFilterChange(FilterInputValue);
    }
    setFilterInputLoader(false);
  }, [debouncedValue]);

  const handleChangeFilter = (
    event: React.SyntheticEvent<Element, Event>,
    newInputValue: string
  ) => {
    console.log("newInputValue", newInputValue);
    setFilterInputValue(newInputValue);
    setFilterInputLoader(true);
  };

  return (
    <>
      <Autocomplete
        fullWidth
        selectOnFocus
        clearOnBlur
        disablePortal
        defaultValue={data?.find((obj: any) => {
          if (setDefaultValueWithId) {
            return obj.id === setDefaultValueWithId;
          } else return "";
        })}
        id="combo-box-demo"
        // sx={{ maxWidth: "290px" }}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) =>
          option.name
            ? option.name
            : option.fullName === value.name
            ? value.name
            : value.fullName
        }
        filterOptions={(x) => x}
        onInputChange={(event, newInputValue) => {
          handleChangeFilter(event, newInputValue);
        }}
        options={data}
        getOptionLabel={(option) =>
          option.name ? option.name : option.fullName
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={labelTitle}
            error={formikTouched && Boolean(formikErrors)}
            helperText={formikTouched && formikErrors}
            InputProps={{
              ...params.InputProps,
              sx: { backgroundColor: "#fff", borderRadius: "16px" },
              endAdornment: (
                <React.Fragment>
                  {filterInputLoader ? (
                    <InputAdornment position="start">
                      <IconButton aria-label="" edge="end">
                        <CircularProgress size={20} sx={{ margin: "2px" }} />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="start">
                      <IconButton aria-label="" edge="end">
                        <FilterListIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              <Avatar sx={{ backgroundColor: "#fff", marginRight: "6px" }}>
                <img
                  src={
                    option.logoUrl
                      ? option.logoUrl
                      : option.organization?.logoUrl
                  }
                  style={{ width: 20, height: "auto" }}
                />
              </Avatar>
              {option.name ? option.name : option.fullName}
            </li>
          );
        }}
      />
    </>
  );
};
export default AutocompleteDebounce;

import axios from "axios";

import { storage } from "./api.interfaces";

const axiosinterceptor = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
axiosinterceptor.interceptors.request.use(
  (config) => {
    const token = storage.getToken();
    if (config.headers) {
      config.headers["Authorization"] = `${token}`;
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosinterceptor.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;
      try {
        storage.clearToken();
        window.location.href = "/loggedout";
        return axiosinterceptor(originalConfig);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
    // if (originalConfig.url !== "/User/GetAccessToken" && err.response) {
    //   // Access Token was expired
    //   if (err.response.status === 401 && !originalConfig._retry) {
    //     originalConfig._retry = true;
    //     try {
    //       const rs = await axiosinterceptor.post("/User/RefreshAccessToken", {
    //         refreshAccessTokenRequest: storage.getRefreshToken(),
    //       });
    //       const { accessToken } = rs.data;
    //       storage.updateAccessToken(accessToken);
    //       return axiosinterceptor(originalConfig);
    //     } catch (_error) {
    //       return Promise.reject(_error);
    //     }
    //   }
    // }

    return Promise.reject(err);
  }
);
export default axiosinterceptor;

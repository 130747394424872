import * as React from "react";

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
// @ts-ignore
import JWPlayer from "@jwplayer/jwplayer-react";
import UploadImage from "../components/UploadImage";
import FormControlLabel from "@mui/material/FormControlLabel";
import UploadVideo from "./UploadVideo";
import { fetchVideo } from "../hooks/useVideo";
import LinearProgress from "@mui/material/LinearProgress";
import { postActivityImage, postActivityVideo } from "../hooks/useActivity";

interface ActivityFieldsProps {
  onUploadedImageChange: (file: any) => void;
  onUploadedVideoFileChange: (file: any) => void;
  isVideoActivity: (useVideo: boolean) => void;
  resetFields: boolean;
}
export const ActivityImageVideo: React.FC<ActivityFieldsProps> = ({
  onUploadedImageChange,
  onUploadedVideoFileChange,
  isVideoActivity,
  resetFields,
}) => {
  const [acceptedFiles, setAcceptedFiles] = React.useState<any>("");
  const [imageObj, setImageObj] = React.useState<any>(null);

  const [acceptedVideoFiles, setAcceptedVideoFiles] = React.useState<any>("");
  const [videoObj, setVideoObj] = React.useState<any>(null);
  const [videoEncoded, setVideoEncoded] = React.useState<any>(null);
  const [isVideoUploadLoading, setIsVideoUploadLoading] =
    React.useState<boolean>(false);
  const [isVideoEncodingLoading, setIsVideoEncodingLoading] =
    React.useState<boolean>(false);

  const [useVideo, setUseVideo] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsVideoEncodingLoading(true);
    const timer = setInterval(async () => {
      setIsVideoEncodingLoading(true);
      if (videoObj) {
        setVideoEncoded(await fetchVideo(videoObj.id));
        if (videoEncoded?.status === "Encoded") {
          setIsVideoEncodingLoading(false);
          clearInterval(timer);
        }
      }
    }, 3000);
    return () => clearInterval(timer);
  }, [videoObj]);

  React.useEffect(() => {
    if (resetFields) {
      setAcceptedFiles("");
      setAcceptedVideoFiles("");
      setImageObj(null);
      setVideoObj(null);
      setVideoEncoded(null);
      setIsVideoUploadLoading(false);
      setIsVideoEncodingLoading(false);
    }
  }, [resetFields]);

  React.useEffect(() => {
    isVideoActivity(useVideo);
  }, [useVideo]);

  React.useEffect(() => {
    onUploadedVideoFileChange(videoEncoded);
  }, [videoEncoded]);

  return (
    <Stack direction="column" spacing={4} sx={{ width: "100%" }}>
      <FormControlLabel
        sx={{
          margin: " 20px auto 0 0",
        }}
        control={
          <Switch
            checked={useVideo}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              e.target.checked ? setUseVideo(true) : setUseVideo(false);
            }}
          />
        }
        label="Use video?"
      />

      {useVideo && (
        <Typography variant="h4" gutterBottom>
          Upload video
        </Typography>
      )}

      {!videoEncoded && useVideo && (
        <>
          <div>
            <UploadVideo
              maxFiles={1}
              onAcceptedFilesChange={(files: File[]) => {
                setAcceptedVideoFiles(files);
              }}
            />

            <Button
              color="error"
              variant="outlined"
              size="small"
              sx={{ marginTop: "5px" }}
              fullWidth
              disabled={acceptedVideoFiles.length > 0 ? false : true}
              endIcon={
                isVideoUploadLoading && (
                  <IconButton aria-label="" edge="end">
                    <CircularProgress
                      size={15}
                      sx={{ color: "#68F3CB" }}
                      color="success"
                    />
                  </IconButton>
                )
              }
              onClick={() => {
                setIsVideoUploadLoading(true);

                postActivityVideo(acceptedVideoFiles).then((res) => {
                  setVideoObj(res);
                  setIsVideoUploadLoading(false);
                  setIsVideoEncodingLoading(true);
                });
              }}
            >
              Upload video
            </Button>
          </div>
        </>
      )}
      {isVideoEncodingLoading &&
        videoEncoded &&
        videoEncoded?.status !== "Encoded" && (
          <>
            <Typography variant="body1" textAlign="center">
              {videoEncoded.status}
            </Typography>
            <Typography variant="body2" textAlign="center">
              {videoEncoded?.videoEncodingStatus?.status}
            </Typography>
            <Box sx={{ width: "100%" }}>
              <LinearProgress color="success" />
            </Box>
          </>
        )}
      {videoEncoded && videoEncoded.status === "Encoded" && (
        <JWPlayer
          file={`${videoEncoded?.outputContainer.url}/${videoEncoded?.outputs[0].filePath}`}
          library="https://cdn.jwplayer.com/libraries/wyHeWRqG.js"
        />
      )}
      {imageObj ? (
        <img
          src={imageObj?.fileUrl}
          style={{ width: "392px", height: "auto" }}
        />
      ) : (
        <>
          <Typography variant="h4" gutterBottom>
            {!useVideo && <>Upload image </>}
            {useVideo && <>Upload thumbnail for video</>}
          </Typography>
          <div
          // style={{
          //   display:
          //     imageUploadEnabled || videoUploadEnabled ? "block" : "none",
          // }}
          >
            <UploadImage
              maxFiles={2}
              onAcceptedFilesChange={(files: File[]) => {
                setAcceptedFiles(files);
              }}
            />

            <Button
              color="error"
              variant="outlined"
              size="small"
              sx={{ marginTop: "5px" }}
              fullWidth
              disabled={acceptedFiles.length > 0 ? false : true}
              endIcon={
                isLoading && (
                  <IconButton aria-label="" edge="end">
                    <CircularProgress
                      size={15}
                      sx={{ color: "#68F3CB" }}
                      color="success"
                    />
                  </IconButton>
                )
              }
              onClick={() => {
                setIsLoading(true);
                postActivityImage(acceptedFiles).then((res) => {
                  setIsLoading(false);

                  setImageObj(res);
                  onUploadedImageChange(res);
                });
              }}
            >
              {useVideo ? <>Upload thumbnail</> : <>Upload image</>}
            </Button>
          </div>
        </>
      )}
    </Stack>
  );
};
export default ActivityImageVideo;

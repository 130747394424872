import * as React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Avatar,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import {
  IOrganizationAdd,
  ILeagueAdd,
  getCountries,
  ISeason,
} from "utils/api.interfaces";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { Transition } from "utils/common";
import { useNavigate, useParams } from "react-router-dom";
import UploadImage from "../components/UploadImage";
import FormControlLabel from "@mui/material/FormControlLabel";

import Select from "@mui/material/Select";
import { ErrorMessageSlide } from "../components/ErrorMessageSlide";
import League from "./League";
import {
  postLeagueImage,
  putLeague,
  useLeague,
  useLeagues,
} from "../hooks/useLeague";
import AutocompleteDebounce from "../components/AutocompleteDebounce";
import { Delete, Edit } from "@mui/icons-material";
import SeasonFieldsNew from "../components/SeasonFieldsNew";
import SeasonFieldsEdit from "../components/SeasonFieldsEdit";

export default function LeagueEdit() {
  const { leagueId } = useParams();
  const { dataLeague } = useLeague(leagueId);

  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
  });

  const initLeague: ILeagueAdd = {
    id: dataLeague?.id || "",
    name: dataLeague?.name || "",
    isDefault: dataLeague?.isDefault || false,
    logoUrl: dataLeague?.logoUrl || "",
    isHighlighted: dataLeague?.isHighlighted || false,
    sportType: dataLeague?.sportType || "Football",
    countryCode: dataLeague?.countryCode.toUpperCase() || "NL",
    isGhost: dataLeague?.isGhost || false,
    seasons: dataLeague?.seasons || [],
  };

  const navigate = useNavigate();
  const [showSlideError, setShowSlideError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<Boolean>(false);
  const [newLeague, setNewLeague] = React.useState<string | undefined>(
    undefined
  );
  const [acceptedFiles, setAcceptedFiles] = React.useState<File[]>([]);

  const [seasons, setSeasons] = React.useState<ISeason[]>(initLeague.seasons);
  React.useEffect(() => {
    console.log(seasons);
    formik.setFieldValue("seasons", seasons);
  }, [seasons]);
  React.useEffect(() => {
    setSeasons(dataLeague?.seasons || []);
  }, [dataLeague]);
  const formik = useFormik({
    initialValues: initLeague,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      if (acceptedFiles && acceptedFiles?.length > 0) {
        postLeagueImage(acceptedFiles)
          .then((res) => {
            putLeague({
              ...values,
              logoUrl: res.fileUrl,
            })
              .then((res) => {
                setIsLoading(false);
                setNewLeague(leagueId);
              })
              .catch((e: any) => {
                setShowSlideError(true);
                setIsLoading(false);
              });
          })
          .catch((e: any) => {
            setShowSlideError(true);
            setIsLoading(false);
          });
      } else {
        putLeague({
          ...values,
        })
          .then((res) => {
            setIsLoading(false);
            setNewLeague(leagueId);
          })
          .catch((e: any) => {
            setShowSlideError(true);
            setIsLoading(false);
          });
      }
    },
  });

  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        color="inherit"
        noWrap
        gutterBottom
        sx={{
          textAlign: "center",
          margin: "40px 0px",
        }}
      >
        Edit League
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          spacing={3}
          sx={{
            maxWidth: "500px",
            margin: "auto",
            opacity: newLeague || isLoading ? 0.4 : 1,
            pointerEvents: isLoading ? "none" : "auto",
          }}
        >
          <Avatar
            src={dataLeague?.logoUrl}
            sx={{ width: 175, height: 175, margin: "auto" }}
          />
          <UploadImage
            maxFiles={1}
            onAcceptedFilesChange={(files: File[]) => {
              setAcceptedFiles(files);
            }}
          />
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" shrink={true}>
              Sport Type
            </InputLabel>
            <Select
              labelId="sportType"
              id="sportType"
              name="sportType"
              value={formik.values.sportType}
              label="sportType"
              onChange={formik.handleChange}
            >
              <MenuItem value="Football">Football</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" shrink={true}>
              Country code
            </InputLabel>
            <Select
              labelId="countryCode"
              id="countryCode"
              name="countryCode"
              value={formik.values.countryCode}
              label="countryCode"
              onChange={formik.handleChange}
            >
              {getCountries().map((country: any) => (
                <MenuItem key={country.code} value={country.code}>
                  {country.name} ({country.code})
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControlLabel
            sx={{
              margin: " 20px auto",
            }}
            control={
              <Switch
                checked={formik.values.isGhost}
                inputProps={{ "aria-label": "controlled" }}
                value={formik.values.isGhost}
                onChange={(e) => {
                  e.target.checked
                    ? formik.setFieldValue("isGhost", true)
                    : formik.setFieldValue("isGhost", false);
                }}
              />
            }
            label="League is a ghost league?"
          />
          <FormControlLabel
            sx={{
              margin: " 20px auto",
            }}
            control={
              <Switch
                checked={formik.values.isHighlighted}
                inputProps={{ "aria-label": "controlled" }}
                value={formik.values.isHighlighted}
                onChange={(e) => {
                  e.target.checked
                    ? formik.setFieldValue("isHighlighted", true)
                    : formik.setFieldValue("isHighlighted", false);
                }}
              />
            }
            label="League is a highlighted league (shown on top of results/suggestions)?"
          />
          <FormControlLabel
            sx={{
              margin: " 20px auto",
            }}
            control={
              <Switch
                checked={formik.values.isDefault}
                inputProps={{ "aria-label": "controlled" }}
                value={formik.values.isDefault}
                onChange={(e) => {
                  e.target.checked
                    ? formik.setFieldValue("isDefault", true)
                    : formik.setFieldValue("isDefault", false);
                }}
              />
            }
            label="League is a default league?"
          />

          <h3>Season(s)</h3>
          {seasons?.map((season, index) => (
            <div key={index + "seasonedit"} style={{ position: "relative" }}>
              <SeasonFieldsEdit
                initSeason={season}
                onFieldChanges={(startsAtUtc, endsAtUtc) =>
                  console.log(startsAtUtc, endsAtUtc)
                }
                onFieldSubmit={(season) => {
                  let newSeasons = seasons ? [...seasons] : [];
                  newSeasons[index] = season;
                  setSeasons(newSeasons);
                }}
              />
              <Button
                color="error"
                variant="outlined"
                sx={{
                  marginLeft: "auto",
                  position: "absolute",
                  right: 0,
                  top: 0,
                  padding: "4px",
                  width: "10px",
                }}
                onClick={(e) => {
                  if (!confirm("Are you sure you want to delete this season?"))
                    return;
                  let newSeasons = seasons ? [...seasons] : [];
                  newSeasons.splice(index, 1);
                  console.log(newSeasons);
                  setSeasons(newSeasons);
                }}
              >
                <Delete sx={{ width: "10px", height: "10px" }} />
              </Button>
            </div>
          ))}

          <SeasonFieldsNew
            onFieldChanges={(startsAtUtc, endsAtUtc) =>
              console.log(startsAtUtc, endsAtUtc)
            }
            onFieldSubmit={(name, startsAtUtc, endsAtUtc) =>
              setSeasons([...seasons, { name, startsAtUtc, endsAtUtc }])
            }
          />

          <Button
            color="secondary"
            variant="contained"
            fullWidth
            type="submit"
            endIcon={
              isLoading && (
                <IconButton aria-label="" edge="end">
                  <CircularProgress
                    size={15}
                    sx={{ color: "#68F3CB" }}
                    color="success"
                  />
                </IconButton>
              )
            }
          >
            Update league
          </Button>
          <ErrorMessageSlide showError={showSlideError} />
        </Stack>

        <Dialog
          open={!!newLeague}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            setNewLeague(undefined);
          }}
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{
            sx: { backgroundColor: "#fff", minWidth: "400px" },
          }}
        >
          <DialogTitle variant="h4">
            {"League successfully updated!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              onClick={() => {
                setNewLeague(undefined);
              }}
            >
              close
            </Button>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={() => navigate(`/leagues/${leagueId}`)}
            >
              Go to league page
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </>
  );
}

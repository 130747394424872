import * as React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Brandlibrary from "./pages/Brandlibrary";
import { SideBar } from "./components/SideBar";
import UsersContainer from "./pages/UsersContainer";
import Users from "./pages/Users";
import User from "./pages/User";
import UserAdd from "./pages/UserAdd";
import Team from "./pages/Team";
import Teams from "./pages/Teams";
import TeamAdd from "./pages/TeamAdd";
import Organization from "./pages/Organization";
import Organizations from "./pages/Organizations";
import OrganizationAdd from "./pages/OrganizationAdd";
import Match from "./pages/Match";
import Matches from "./pages/Matches";
import MatchAdd from "./pages/MatchAdd";
import MatchEdit from "./pages/MatchEdit";
import TeamsContainer from "./pages/TeamsContainer";
import OrganizationsContainer from "./pages/OrganizationsContainer";
import MatchesContainer from "./pages/MatchesContainer";
import UserEdit from "./pages/UserEdit";
import TeamEdit from "./pages/TeamEdit";
import { AuthProvider, RequireAuth } from "./hooks/useAuth";
import LoggedIn from "./pages/LoggedIn";
import LoggedOut from "./pages/LoggedOut";
import MingleActivityContainer from "./pages/MingleActivityContainer";
import MingleActivity from "./pages/MingleActivity";
import MingleActivityEdit from "./pages/MingleActivityEdit";
import MingleActivityAdd from "./pages/MingleActivityAdd";
import MingleActivities from "./pages/MingleActivities";
import OrganizationEdit from "./pages/OrganizationEdit";

import UserPre from "./pages/UserPre";
import LeagueContainer from "./pages/LeagueContainer";
import League from "./pages/League";
import LeagueEdit from "./pages/LeagueEdit";
import LeagueAdd from "pages/LeagueAdd";
import Leagues from "pages/Leagues";
import MiniGamesAttemptVerify from "./pages/MiniGamesAttemptVerify";
import MiniGamesContainer from "./pages/MiniGamesContainer";
import MiniGames from "./pages/MiniGames";
import MiniGamesAttempts from "./pages/MiniGamesAttempts";

export default function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/loggedin" element={<LoggedIn />} />
          <Route path="/loggedout" element={<LoggedOut />} />
          <Route
            path="/"
            element={
              <RequireAuth>
                <SideBar />
              </RequireAuth>
            }
          >
            <Route index element={<Homepage />} />
            <Route path="organizations" element={<OrganizationsContainer />}>
              <Route path=":organizationId" element={<Organization />} />
              <Route
                path="edit/:organizationId"
                element={<OrganizationEdit />}
              />
              <Route path="add" element={<OrganizationAdd />} />
              <Route index element={<Organizations />} />
            </Route>
            <Route path="leagues" element={<LeagueContainer />}>
              <Route path=":leagueId" element={<League />} />
              <Route path="edit/:leagueId" element={<LeagueEdit />} />
              <Route path="add" element={<LeagueAdd />} />
              <Route index element={<Leagues />} />
            </Route>

            <Route path="teams" element={<TeamsContainer />}>
              <Route path=":teamId" element={<Team />} />
              <Route path="edit/:teamId" element={<TeamEdit />} />
              <Route path="add" element={<TeamAdd />} />
              <Route index element={<Teams />} />
            </Route>
            <Route path="users" element={<UsersContainer />}>
              <Route path=":userId" element={<User />} />
              <Route path="pre/:userId" element={<UserPre />} />
              <Route path="edit/:userId" element={<UserEdit />} />
              <Route path="add" element={<UserAdd />} />
              <Route index element={<Users />} />
            </Route>

            <Route path="match" element={<MatchesContainer />}>
              <Route path=":matchId" element={<Match />} />
              <Route path="edit/:matchId" element={<MatchEdit />} />
              <Route path="add" element={<MatchAdd />} />
              <Route index element={<Matches />} />
            </Route>
            <Route path="minigames" element={<MiniGamesContainer />}>
              <Route index element={<MiniGames />} />
              <Route path=":sessionId" element={<MiniGamesAttempts />} />
              <Route
                path="attempt/:sessionId/:attemptId"
                element={<MiniGamesAttemptVerify />}
              />
            </Route>
            <Route path="activity" element={<MingleActivityContainer />}>
              <Route path=":activityId" element={<MingleActivity />} />
              <Route path="edit/:activityId" element={<MingleActivityEdit />} />
              <Route path="add" element={<MingleActivityAdd />} />
              <Route index element={<MingleActivities />} />
            </Route>

            <Route path="/brand" element={<Brandlibrary />} />
          </Route>
          <Route path="/test" element={<Brandlibrary />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

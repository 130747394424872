import * as React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
import { Chip, Stack, Typography } from "@mui/material";
import { MiniGamesStatusEnum } from "../utils/api.interfaces";
import VerifyGoal from "./VerifyGoal";

interface StatusProps {
  status?: MiniGamesStatusEnum;
}
const MiniGameStatus: React.FC<StatusProps> = ({ status }) => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      alignContent="center"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{ margin: theme.spacing(2) }}
    >
      {status === MiniGamesStatusEnum.verified && (
        <Chip label="Verified" color="info" title={status} />
      )}
      {status === MiniGamesStatusEnum.disputed && (
        <Chip label="Verification Request" color="error" title={status} />
      )}
      {status === MiniGamesStatusEnum.error && (
        <Chip
          label="Verification Needed (error)"
          color="error"
          title={status}
        />
      )}
      {status === MiniGamesStatusEnum.correct && (
        <Chip label="Looks Good" color="success" title={status} />
      )}
      {status === MiniGamesStatusEnum.verifying && (
        <Chip label={status} color="info" title={status} />
      )}{" "}
      {status === MiniGamesStatusEnum.undefined && (
        <Chip label={status} color="warning" title={status} />
      )}{" "}
      {status === MiniGamesStatusEnum.verificationRequired && (
        <Chip label={status} color="error" title="Verification Required" />
      )}
    </Stack>
  );
};
export default MiniGameStatus;

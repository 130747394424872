import {
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  TextField,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import * as React from "react";
import { useOrganization } from "../hooks/useOrganization";
import { useLeague } from "../hooks/useLeague";
import { useFormik } from "formik";
import {
  getTeamBoost,
  getTeamBoostPerUser,
  postTeamBoostGift,
  putArchiveTeam,
  putBulkArchiveTeam,
  putTeamBoostUnassign,
  putUnArchiveTeam,
} from "../hooks/useTeam";
import { IBoostStatus, ITeam, ITeamBoost } from "utils/api.interfaces";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import ArchiveIcon from "@mui/icons-material/Archive";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/nl";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import StarIcon from "@mui/icons-material/Star";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonBoosterUser from "./ButtonBoosterUser";
interface ButtonBoostRemoveProps {
  teamBoostId: string;
  refetch: () => Promise<any>;
}
const ButtonBoostRemove: React.FC<ButtonBoostRemoveProps> = ({
  teamBoostId,
  refetch,
}) => {
  const [isLoading, setIsLoading] = React.useState<Boolean>(false);

  const formik = useFormik({
    initialValues: { teamBoostId },
    enableReinitialize: true,
    onSubmit: (values: any) => {
      if (!confirm("Are you sure you want to remove this boost?")) {
        return;
      }
      setIsLoading(true);
      putTeamBoostUnassign(teamBoostId)
        .then((res) => {
          refetch().then((res) => {
            setIsLoading(false);
          });
        })
        .catch((e: any) => {
          setIsLoading(false);
        });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Button
          color="error"
          variant="contained"
          size="small"
          type="submit"
          startIcon={<DeleteIcon />}
          sx={{ fontSize: "12px", padding: "2px" }}
          endIcon={
            isLoading && (
              <IconButton aria-label="" edge="end">
                <CircularProgress
                  size={15}
                  sx={{ color: "#68F3CB" }}
                  color="success"
                />
              </IconButton>
            )
          }
        ></Button>
      </form>
    </>
  );
};

export default ButtonBoostRemove;

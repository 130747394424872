import React from "react";
interface MousePositionProps {
  object: React.RefObject<HTMLDivElement>;
}

const useMousePosition = (object: React.RefObject<HTMLDivElement>) => {
  const [mousePosition, setMousePosition] = React.useState({
    x: "0",
    y: "0",
  });
  console.log();
  React.useEffect(() => {
    const updateMousePosition = (ev: any) => {
      const rect = object?.current?.getBoundingClientRect();
      if (rect) {
        setMousePosition({
          x: (ev.clientX - rect?.left).toFixed(2),
          y: (ev.clientY - rect?.top).toFixed(2),
        });
      }
    };
    object?.current?.addEventListener("mousemove", updateMousePosition);
    return () => {
      object?.current?.removeEventListener("mousemove", updateMousePosition);
    };
  }, []);
  return mousePosition;
};
export default useMousePosition;

import * as React from "react";
import Container from "@mui/material/Container";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import { ReactComponent as Logo } from "images/logo-notext.svg";
import { ReactComponent as IconCheersHands } from "images/cheers-hand.svg";
import { ReactComponent as IconCheersHandsBlue } from "images/cheers-hand-blue.svg";
import {
  ReactComponent as IconComments,
  ReactComponent as IconChat,
} from "images/bottombar/chat.svg";
import { ReactComponent as IconActivity } from "images/bottombar/activity.svg";
import { ReactComponent as IconEvents } from "images/bottombar/events.svg";
import { ReactComponent as IconPlay } from "images/bottombar/play.svg";
import { ReactComponent as IconProfile } from "images/bottombar/profile.svg";
import { ReactComponent as IconBal } from "images/ball-icon.svg";
import { ReactComponent as IconClub } from "images/club-icon.svg";
import { ReactComponent as IconTeam } from "images/team-icon.svg";
import { ReactComponent as IconUser } from "images/user-icon.svg";
import { ReactComponent as IconMatch } from "images/match-icon.svg";
import { ReactComponent as ChevronLeft } from "images/chevron-left.svg";
import { ReactComponent as JetFighter } from "images/jet-fighter.svg";
import { ReactComponent as LogoOld } from "../../images/logo-old-notext.svg";
import SportsIcon from "@mui/icons-material/Sports";

export function IconJetFighterMingle(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <JetFighter />
    </SvgIcon>
  );
}

export function IconChevronLeftMingle(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <ChevronLeft />
    </SvgIcon>
  );
}

export function LogoMingle(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <Logo />
    </SvgIcon>
  );
}
export function LogoOldMingle(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <LogoOld />
    </SvgIcon>
  );
}
export function OrganizationIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <IconClub />
    </SvgIcon>
  );
}
export function TeamIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <IconTeam />
    </SvgIcon>
  );
}
export function UserIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <IconUser />
    </SvgIcon>
  );
}
export function MatchIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <IconMatch />
    </SvgIcon>
  );
}

export function LeagueIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <SportsIcon />
    </SvgIcon>
  );
}

export function IconProfileMingle(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <IconProfile />
    </SvgIcon>
  );
}
export function IconPlayMingle(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <IconPlay />
    </SvgIcon>
  );
}
export function IconEventsMingle(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <IconEvents />
    </SvgIcon>
  );
}
export function IconActivityMingle(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <IconActivity />
    </SvgIcon>
  );
}
export function IconChatMingle(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <IconChat />
    </SvgIcon>
  );
}
export function IconCommentsMingle(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <IconComments />
    </SvgIcon>
  );
}
export function IconCheersHandsMingle(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <IconCheersHands />
    </SvgIcon>
  );
}
export function IconBalMingle(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <IconBal />
    </SvgIcon>
  );
}
export function IconCheersHandsBlueMingle(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <IconCheersHandsBlue />
    </SvgIcon>
  );
}
export default function MingleIcons() {
  return (
    <Container maxWidth="lg">
      <LogoMingle
        fontSize="large"
        color="secondary"
        sx={{
          width: 100,
          verticalAlign: "middle",
          height: 106,
        }}
      />
    </Container>
  );
}

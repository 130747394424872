import {
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  TextField,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import * as React from "react";
import { useOrganization } from "../hooks/useOrganization";
import { useLeague } from "../hooks/useLeague";
import { useFormik } from "formik";
import {
  postTeamBoostGift,
  putArchiveTeam,
  putBulkArchiveTeam,
  putUnArchiveTeam,
} from "../hooks/useTeam";
import { ITeam } from "utils/api.interfaces";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import ArchiveIcon from "@mui/icons-material/Archive";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/nl";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import StarIcon from "@mui/icons-material/Star";
interface ButtonButtonBoostTeamProps {
  teamId: string;
  refetch: () => Promise<any>;
}
const ButtonBoostTeam: React.FC<ButtonButtonBoostTeamProps> = ({
  teamId,
  refetch,
}) => {
  const [dateValue, setDateValue] = React.useState<Dayjs | null>(
    dayjs(dayjs().add(1, "month").toISOString())
  );
  const [isLoading, setIsLoading] = React.useState<Boolean>(false);
  const formik = useFormik({
    initialValues: { teamId: teamId, expiresAtUtc: dateValue?.toISOString() },
    enableReinitialize: true,
    onSubmit: (values: any) => {
      if (!confirm("Are you sure you want to boost this team?")) {
        return;
      }
      setIsLoading(true);
      postTeamBoostGift(teamId, values.expiresAtUtc)
        .then((res) => {
          refetch().then((res) => {
            setIsLoading(false);
            setIsLoading(false);
          });
        })
        .catch((e: any) => {
          setIsLoading(false);
        });
    },
  });
  const handleDateChange = (newValue: Dayjs | null) => {
    setDateValue(newValue);
    formik.setFieldValue("expiresAtUtc", newValue?.toISOString());
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"nl"}>
        <DatePicker
          label="Choose expirydate date"
          value={dateValue}
          onChange={handleDateChange}
          renderInput={(params) => (
            <TextField
              {...params}
              error={
                formik.touched.expiresAtUtc &&
                Boolean(formik.errors.expiresAtUtc)
              }
              helperText={
                formik.touched.expiresAtUtc && formik.errors.expiresAtUtc
              }
            />
          )}
        />
      </LocalizationProvider>
      <br />
      <br />
      <Button
        color="primary"
        variant="contained"
        size="small"
        type="submit"
        startIcon={<StarIcon />}
        sx={{ fontSize: "12px", padding: "5px 10px" }}
        endIcon={
          isLoading && (
            <IconButton aria-label="" edge="end">
              <CircularProgress
                size={15}
                sx={{ color: "#68F3CB" }}
                color="success"
              />
            </IconButton>
          )
        }
      >
        Boost Team
      </Button>
    </form>
  );
};

export default ButtonBoostTeam;

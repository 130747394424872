import * as React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DateFromToFilter from "./DateFromToFilter";
import dayjs, { Dayjs } from "dayjs";
import { rowsPerpageOptions } from "./Pagination";
import DateFromTo from "./DateFromToFilter";
import { ISeason } from "../utils/api.interfaces";
import { Delete, Edit } from "@mui/icons-material";
interface SeasonFieldsProps {
  initSeason?: ISeason;
  onFieldChanges: (
    name: string,
    startsAtUtc: string,
    endsAtUtc: string
  ) => void;
  onFieldSubmit: (season: ISeason) => void;
}

const SeasonFieldsEdit: React.FC<SeasonFieldsProps> = ({
  initSeason,
  onFieldChanges,
  onFieldSubmit,
}) => {
  const theme = useTheme();

  const [seasonName, setSeasonName] = React.useState<string>(
    initSeason?.name || ""
  );
  const [dateFromValue, setDateFromValue] = React.useState<Dayjs>(
    dayjs(initSeason?.startsAtUtc)
  );
  const [dateToValue, setDateToValue] = React.useState<Dayjs>(
    dayjs(initSeason?.endsAtUtc)
  );
  const [showFields, setShowFields] = React.useState<boolean>(false);
  React.useEffect(() => {
    onFieldChanges(
      seasonName,
      dateFromValue?.toISOString(),
      dateToValue?.toISOString()
    );
  }, [seasonName, dateFromValue, dateToValue]);

  return (
    <>
      {showFields ? (
        <>
          <div
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              background: "rgba(0, 0, 0, 0.1)",
              pointerEvents: "auto",
              top: 0,
              left: 0,
              zIndex: 1000,
            }}
          ></div>
          <Stack
            direction="column"
            alignContent="center"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            style={{
              position: "relative",
              backgroundColor: "#ffffff",
              padding: 18,
              borderRadius: "8px",
              zIndex: 1011,
            }}
          >
            <Button
              size="small"
              onClick={() => {
                setShowFields(false);
              }}
              sx={{ marginLeft: "auto" }}
            >
              x
            </Button>
            <TextField
              fullWidth
              id="name"
              name="name"
              label="Name"
              type="text"
              defaultValue={seasonName}
              onChange={(e) => setSeasonName(e.currentTarget.value)}
            />
            <Stack
              direction="row"
              // alignContent="center"
              // justifyContent="center"
              // alignItems="center"
              spacing={2}
              sx={{
                width: "100%",
                margin: "auto",
              }}
            >
              <DateFromToFilter
                dateFrom={dateFromValue}
                dateTo={dateToValue}
                onDateFromToChange={(dateFromValue, dateToValue) => {
                  setDateFromValue(dateFromValue || dayjs());
                  setDateToValue(dateToValue || dayjs());
                }}
              />
              <div style={{ marginLeft: "auto" }}>
                <Button
                  color="success"
                  variant="contained"
                  size={"small"}
                  sx={{ marginLeft: "auto" }}
                  style={{ marginLeft: "auto" }}
                  onClick={() => {
                    onFieldSubmit({
                      ...initSeason,
                      name: seasonName,
                      startsAtUtc: dateFromValue?.toISOString(),
                      endsAtUtc: dateToValue?.toISOString(),
                    });

                    setShowFields(false);
                  }}
                >
                  set
                </Button>
              </div>
            </Stack>
          </Stack>
        </>
      ) : (
        <>
          <strong>{initSeason?.name}</strong>
          <Stack
            direction="row"
            justifyItems="center"
            alignContent="center"
            alignItems="center"
            gap={3}
            sx={{ width: "100%" }}
          >
            <div>
              {new Date(initSeason?.startsAtUtc as string).toLocaleDateString(
                [],
                {
                  weekday: "short",
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )}
            </div>
            -
            <div>
              {new Date(initSeason?.endsAtUtc as string).toLocaleDateString(
                [],
                {
                  weekday: "short",
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )}
            </div>
            <Button
              color="secondary"
              variant="outlined"
              size={"small"}
              sx={{ marginLeft: "auto" }}
              onClick={() => {
                setShowFields(true);
              }}
            >
              edit
            </Button>
          </Stack>
          <hr />
        </>
      )}
    </>
  );
};
export default SeasonFieldsEdit;

import * as React from "react";
import { Avatar, AvatarGroup, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { StyledTableCell } from "../utils/common";
import { ITeam, ITeamMember } from "../utils/api.interfaces";

interface TeamUsersProps {
  players?: ITeamMember[];
  trainers?: ITeamMember[];
  support?: ITeamMember[];
}
export const TeamUsersAmount: React.FC<TeamUsersProps> = ({
  players,
  trainers,
  support,
}) => {
  return (
    <>
      <StyledTableCell>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {players?.length}
          </Typography>

          <AvatarGroup max={2} total={players?.length}>
            {players?.map((row) => (
              <Avatar
                key={row.id}
                alt={`${row.firstName} ${row.lastName}`}
                src={row.imageUrl}
              />
            ))}
          </AvatarGroup>
        </Stack>
      </StyledTableCell>

      <StyledTableCell>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {trainers?.length}
          </Typography>

          <AvatarGroup max={2} total={trainers?.length}>
            {trainers?.map((row) => (
              <Avatar
                key={row.id}
                alt={`${row.firstName} ${row.lastName}`}
                src={row.imageUrl}
              />
            ))}
          </AvatarGroup>
        </Stack>
      </StyledTableCell>

      <StyledTableCell>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {support?.length}
          </Typography>

          <AvatarGroup max={2} total={support?.length}>
            {support?.map((row) => (
              <Avatar
                key={row.id}
                alt={`${row.firstName} ${row.lastName}`}
                src={row.imageUrl}
              />
            ))}
          </AvatarGroup>
        </Stack>
      </StyledTableCell>
    </>
  );
};

import { createTheme } from "@mui/material/styles";

const mingleSpacing = [0, 4, 8, 12, 16, 20, 24, 28, 32];
// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#0F1841",
      light: "#2A356B",
    },
    secondary: {
      main: "#056CF1",
      light: "#EAF3FF",
      // dark: "",
    },
    error: {
      main: "#E70000",
      light: "#FDE6E6",
      // dark: "",
    },
    warning: {
      main: "#FFDB1D",
      light: "#FFFBE8",
      // dark: "",
    },
    info: {
      main: "#3183FF",
      light: "#EAF3FF",
    },
    success: {
      main: "#68F3CB",
      light: "#E0FCF5",
      dark: "#00B670",
    },
    text: {
      primary: "#18276B",
      secondary: "",
      disabled: "",
    },
    background: {
      paper: "#E9EFFD",
      default: "#F4F6FB",
    },
  },
  typography: {
    fontFamily: [
      "Poppins",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: { fontSize: "2rem" },
    h2: { fontSize: "1.75rem" },
    h3: { fontSize: "1.5rem" },
    h4: { fontSize: "1.25rem" },
    h5: { fontSize: "1.125rem" },
    h6: { fontSize: "1rem" },
    subtitle1: { fontSize: "1.125rem" },
    subtitle2: { fontSize: "1rem" },
    body1: {
      fontFamily: [
        "Open Sans",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      fontSize: "1.125rem",
    },
    body2: {
      fontFamily: [
        "Open Sans",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    caption: { fontSize: "1rem" },
    overline: { fontSize: "0.75", textTransform: "uppercase" },
  },
  // spacing: mingleSpacing,
  components: {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "36px",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: false, // No more ripple!
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          "&.Mui-disabled": {
            opacity: 0.4,
          },
        },
      },
    },
    // MuiSelect: {
    //   styleOverrides: {
    //     root: {
    //       background: "#fff",
    //       borderRadius: 16,
    //     },
    //   },
    // },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiSelect-select ": {
            background: "#fff",
            borderRadius: 16,
          },
          "& input": {
            background: "#fff",
            borderRadius: 16,
          },
          "& fieldset": {
            borderRadius: 16,
          },
          "& textarea": {
            borderRadius: 16,
          },
          "&.Mui-disabled": {
            opacity: 0.4,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: "1rem",
          borderRadius: "30px",

          fontFamily: [
            "Poppins",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(","),
          fontWeight: 700,
          textTransform: "none",
        },
        sizeSmall: {
          fontWeight: 500,
          fontSize: ".875rem",
          padding: `${mingleSpacing[2]}px ${mingleSpacing[4]}px `,
        },
        sizeMedium: {
          padding: `${mingleSpacing[4]}px ${mingleSpacing[8]}px `,
        },
        sizeLarge: {
          borderRadius: "40px",
          fontSize: "1.125rem",
          padding: `${mingleSpacing[6]}px ${mingleSpacing[8]}px `,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: "1px solid #F3F4F6",
          fontSize: "1rem",
          boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.1)",
          background: "#fff",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          borderTop: "1px solid #F3F4F6",
          fontSize: "1rem",
          // padding: `${mingleSpacing[6]}px ${mingleSpacing[8]}px `,
        },
      },
    },
  },
});

export default theme;

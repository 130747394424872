import React from "react";
import { useQuery } from "@tanstack/react-query";
import {
  IOrganization,
  IOrganizationAdd,
  IExportData,
} from "utils/api.interfaces";
import axiosinterceptor from "../utils/api.interceptor";

export const fetchOrganization = (
  organizationId: string | undefined
): Promise<IOrganization> =>
  axiosinterceptor
    .get(`/v1/Organizations/${organizationId}`)
    .then<IOrganization>((res) => res.data);

export function useOrganization(organizationId: string | undefined) {
  const queryOrganization = useQuery(
    ["organization", organizationId],
    () => fetchOrganization(organizationId),
    {
      enabled: !!organizationId,
    }
  );

  return {
    ...queryOrganization,
    dataOrganization: queryOrganization?.data,
    dataOrganizationIsLoading: queryOrganization?.isLoading,
  };
}
export const putOrganization = (
  organization: IOrganizationAdd
): Promise<any> => {
  return axiosinterceptor.put(`/v1/Organizations`, organization).then((res) => {
    return res.data;
  });
};
export const postOrganization = (
  organization: IOrganizationAdd
): Promise<any> => {
  return axiosinterceptor
    .post(`/v1/Organizations`, organization)
    .then((res) => {
      return res.data;
    });
};
export const postExportOrganizationTeamData = (
  exportData: IExportData
): Promise<any> => {
  return axiosinterceptor
    .post(`/v1/Leaderboard/report`, exportData)
    .then((res) => {
      return res.data;
    });
};
export const postOrganizationImage = (image: File[]): Promise<any> => {
  const formData = new FormData();
  formData.append("imageFile", image[0]);

  return axiosinterceptor
    .post(`/v1/Organizations/UploadLogo`, formData)
    .then((res) => {
      return res.data;
    });
};

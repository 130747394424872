import * as React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { IMatchEdit } from "utils/api.interfaces";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate, useParams } from "react-router-dom";
import { Transition } from "utils/common";

import DateTimePicker from "@mui/lab/DateTimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import useMatch, { putMatch } from "hooks/useMatch";
import ButtonTeam from "../components/ButtonTeam";
import { ErrorMessageSlide } from "../components/ErrorMessageSlide";

export default function MatchEdit() {
  const validationSchema = yup.object({
    scheduledAtUtc: yup
      .string()
      .required("A scheduled date & time is required"),
    arrivalTimeUtc: yup.string().required("A arrival date & time is required"),
  });
  const [newMatch, setNewMatch] = React.useState<boolean>(false);

  const [showSlideError, setShowSlideError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { matchId } = useParams();
  const { dataMatch } = useMatch(matchId);

  const initMatch: IMatchEdit = {
    matchId: dataMatch?.id,
    homeTeamId: dataMatch?.team.isHome
      ? dataMatch?.team.id
      : dataMatch?.opponent.id,
    scheduledAtUtc: dataMatch?.scheduledAtUtc,
    arrivalTimeUtc: dataMatch?.arrivalTimeUtc,
    arrivalLocation: dataMatch?.arrivalLocation || "",
  };

  const formik = useFormik({
    initialValues: initMatch,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values: IMatchEdit) => {
      if (dataMatch) {
        setIsLoading(true);
        putMatch(values)
          .then((res) => {
            setNewMatch(true);
            setIsLoading(false);
          })
          .catch((e: any) => {
            setShowSlideError(true);
            setIsLoading(false);
          });
      }
    },
  });

  const [dateValue, setDateValue] = React.useState<Date | null>(
    initMatch.scheduledAtUtc ? new Date(initMatch.scheduledAtUtc) : null
  );
  const [arrivalDateValue, setArrivalDateValue] = React.useState<Date | null>(
    initMatch.arrivalTimeUtc ? new Date(initMatch.arrivalTimeUtc) : null
  );
  const handleDateChange = (newValue: Date | null) => {
    setDateValue(newValue);
    formik.setFieldValue("scheduledAtUtc", newValue?.toISOString());
  };
  const handleArrivalDateChange = (newValue: Date | null) => {
    setArrivalDateValue(newValue);
    formik.setFieldValue("arrivalTimeUtc", newValue?.toISOString());
  };
  React.useEffect(() => {
    if (dataMatch?.scheduledAtUtc) {
      setDateValue(new Date(dataMatch?.scheduledAtUtc));
      setArrivalDateValue(new Date(dataMatch?.arrivalTimeUtc));
    }
  }, [dataMatch]);
  dataMatch;

  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        color="inherit"
        noWrap
        gutterBottom
        sx={{
          textAlign: "center",
          margin: "40px 0px",
        }}
      >
        Edit match
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          spacing={3}
          sx={{
            margin: "auto",
            opacity: newMatch || isLoading ? 0.4 : 1,
            pointerEvents: isLoading ? "none" : "auto",
          }}
        >
          <TextField
            fullWidth
            id="matchId"
            name="matchId"
            label="matchId"
            type="text"
            value={formik.values.matchId}
            disabled={true}
            error={formik.touched.matchId && Boolean(formik.errors.matchId)}
            helperText={formik.touched.matchId && formik.errors.matchId}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="Choose scheduled date & time"
              value={dateValue}
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={
                    formik.touched.scheduledAtUtc &&
                    Boolean(formik.errors.scheduledAtUtc)
                  }
                  helperText={
                    formik.touched.scheduledAtUtc &&
                    formik.errors.scheduledAtUtc
                  }
                />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="Choose Arrival date & time"
              value={arrivalDateValue}
              onChange={handleArrivalDateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={
                    formik.touched.arrivalTimeUtc &&
                    Boolean(formik.errors.arrivalTimeUtc)
                  }
                  helperText={
                    formik.touched.arrivalTimeUtc &&
                    formik.errors.arrivalTimeUtc
                  }
                />
              )}
            />
          </LocalizationProvider>
          <TextField
            fullWidth
            id="arrivalLocation"
            name="arrivalLocation"
            label="Arrival Location"
            type="text"
            value={formik.values.arrivalLocation}
            onChange={formik.handleChange}
            error={
              formik.touched.arrivalLocation &&
              Boolean(formik.errors.arrivalLocation)
            }
            helperText={
              formik.touched.arrivalLocation && formik.errors.arrivalLocation
            }
          />
          {dataMatch && (
            <Stack>
              <Card sx={{ width: "100%", maxWidth: "850px", margin: "auto" }}>
                <CardContent>
                  <Stack
                    direction={"row"}
                    spacing={4}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%" }}
                  >
                    <Stack
                      direction="column"
                      spacing={4}
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <Avatar sx={{ width: 124, height: 124 }}>
                        <img
                          src={dataMatch?.team?.league?.logoUrl}
                          style={{ width: 50, height: "auto" }}
                        />
                      </Avatar>

                      <Typography
                        variant="h3"
                        gutterBottom
                        sx={{ minHeight: "30px" }}
                      >
                        {dataMatch?.team?.name}
                      </Typography>
                      <ButtonTeam team={dataMatch?.team} variant="outlined" />
                    </Stack>

                    <Typography variant="body1">vs.</Typography>
                    <Stack
                      direction="column"
                      spacing={4}
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <Avatar sx={{ width: 124, height: 124 }}>
                        <img
                          src={dataMatch?.opponent?.league?.logoUrl}
                          style={{ width: 50, height: "auto" }}
                        />
                      </Avatar>

                      <Typography
                        variant="h3"
                        gutterBottom
                        sx={{ minHeight: "30px" }}
                      >
                        {dataMatch?.opponent?.name}
                      </Typography>
                      <ButtonTeam
                        team={dataMatch?.opponent}
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </Stack>
          )}

          <Button
            color="secondary"
            variant="contained"
            fullWidth
            type="submit"
            endIcon={
              isLoading && (
                <IconButton aria-label="" edge="end">
                  <CircularProgress
                    size={15}
                    sx={{ color: "#68F3CB" }}
                    color="success"
                  />
                </IconButton>
              )
            }
          >
            Update match
          </Button>
          <ErrorMessageSlide showError={showSlideError} />
        </Stack>

        <Dialog
          open={newMatch}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            setNewMatch(false);
          }}
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{
            sx: { backgroundColor: "#fff", minWidth: "400px" },
          }}
        >
          <DialogTitle variant="h4">{"Match successfully added!"}</DialogTitle>

          <DialogActions>
            <Button
              size="small"
              onClick={() => {
                setNewMatch(false);
              }}
            >
              close
            </Button>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={() => navigate(`/match/${dataMatch?.id}`)}
            >
              Go to match detail page
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </>
  );
}

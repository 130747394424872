import * as React from "react";

import { Avatar, Button, CardActions, Stack, Typography } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useOrganization } from "../hooks/useOrganization";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { UserIcon } from "../components/brandlibrary/MingleIcons";
import OrganizationIcon from "@mui/icons-material/Edit";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import useTeams from "../hooks/useTeams";
import {
  IFilterPagingParams,
  ISearchTeamsParams,
  ITeam,
} from "../utils/api.interfaces";
import ButtonTeam from "../components/ButtonTeam";
import { TeamUsers } from "../components/TeamUsers";
import ButtonExportData from "../components/ButtonExportData";
import { useLeague, useLeagues } from "hooks/useLeague";
import DateFromToFilter from "../components/DateFromToFilter";
import dayjs from "dayjs";
import { Delete } from "@mui/icons-material";
import DialogDeleteMatch from "../components/DialogDeleteMatch";
import DialogDeleteLeague from "components/DialogDeleteLeague";
import ButtonBulkArchiveTeam from "../components/BulkArchiveTeamButton";

export default function League() {
  const { leagueId } = useParams();
  const { dataLeague } = useLeague(leagueId);
  const navigate = useNavigate();
  const [showDeleteDialog, setShowDeleteDialog] =
    React.useState<boolean>(false);

  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        color="inherit"
        noWrap
        gutterBottom
        sx={{
          textAlign: "center",
          margin: "40px 0px",
        }}
      >
        League detail page
      </Typography>
      <Stack
        spacing={3}
        sx={{
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Card sx={{ width: "100%", maxWidth: "850px", margin: "auto" }}>
          <CardContent>
            <Typography
              variant="h1"
              color="text.secondary"
              align="center"
              gutterBottom
            >
              {dataLeague?.name}
            </Typography>

            <Avatar
              src={dataLeague?.logoUrl}
              sx={{ width: 175, height: 175, margin: "auto" }}
            />

            <TableContainer>
              <Table aria-label="user details table">
                <TableBody>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      colSpan={2}
                    ></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      id:
                    </TableCell>
                    <TableCell align="right">{dataLeague?.id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      is Ghost:
                    </TableCell>
                    <TableCell align="right">
                      {dataLeague?.isGhost ? "true" : "false"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      is Highlighted:
                    </TableCell>
                    <TableCell align="right">
                      {dataLeague?.isHighlighted ? "true" : "false"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      is Default:
                    </TableCell>
                    <TableCell align="right">
                      {dataLeague?.isDefault ? "true" : "false"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Country Code:
                    </TableCell>
                    <TableCell align="right">
                      {dataLeague?.countryCode}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Seasons:
                    </TableCell>
                    <TableCell align="right">
                      {dataLeague?.seasons?.map((season) => (
                        <>
                          <Stack
                            direction="row"
                            justifyItems="end"
                            alignContent="space-between"
                            alignItems="center"
                            spacing={6}
                            key={season.id}
                            style={{
                              marginRight: "auto",
                            }}
                          >
                            <div>
                              <strong>{season.name}</strong>
                              <br />
                              <Typography>
                                {/*{season.startsAtUtc}*/}
                                {new Date(
                                  season.startsAtUtc
                                ).toLocaleDateString([], {
                                  weekday: "short",
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}{" "}
                                -{/*{season.endsAtUtc}*/}
                                {new Date(season.endsAtUtc).toLocaleDateString(
                                  [],
                                  {
                                    weekday: "short",
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }
                                )}
                              </Typography>
                            </div>
                            <div style={{ marginLeft: "auto" }}>
                              <ButtonBulkArchiveTeam
                                seasonId={season.id || ""}
                              />
                            </div>
                          </Stack>
                          <hr />
                        </>
                      ))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>

          <CardActions>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="space-between"
              spacing={2}
              sx={{ width: "100%", padding: 2 }}
            >
              <Button
                component={Link}
                size="small"
                variant="contained"
                color="success"
                to={`/leagues/add/`}
                startIcon={<UserIcon />}
              >
                add new League
              </Button>
              <Button
                component={Link}
                size="small"
                variant="outlined"
                color="secondary"
                to={`/leagues/edit/${leagueId}`}
                startIcon={<OrganizationIcon />}
              >
                edit
              </Button>
            </Stack>
          </CardActions>
        </Card>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="space-between"
          spacing={2}
          sx={{ width: "100%", maxWidth: "850px" }}
        >
          <Button
            size="small"
            variant="outlined"
            onClick={() => navigate(-1)}
            startIcon={<ChevronLeftIcon />}
          >
            back
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="error"
            onClick={() => {
              setShowDeleteDialog(true);
            }}
            startIcon={<Delete />}
            sx={{ whiteSpace: "nowrap" }}
          >
            delete
          </Button>
          <DialogDeleteLeague
            id={dataLeague?.id}
            isOpen={showDeleteDialog}
            onIsOpenChange={(value) => {
              setShowDeleteDialog(value);
            }}
          />
        </Stack>
      </Stack>
    </>
  );
}

import * as React from "react";
import { useState } from "react";
import {
  Badge,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Stack,
  Switch,
  TableRow,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  IOrganization,
  ISearchActivityParams,
  ITeam,
  PublishState,
  TeamType,
} from "utils/api.interfaces";
import { rowsPerpageOptions } from "components/Pagination";
import { StyledTableCell, StyledTableRowNoPointer } from "utils/common";
import FilterAndPaging from "components/FilterAndPaging";
import BallotIcon from "@mui/icons-material/Ballot";
import useActivities from "../hooks/useActivities";
import ButtonOrganization from "../components/ButtonOrganization";
import ButtonTeam from "../components/ButtonTeam";
import ButtonOverviewDetailLink from "../components/ButtonOverviewDetailLink";
import ActionMenu from "../components/ActionMenu";
import DialogDeleteActivity from "../components/DialogDeleteActivity";
import useTeams from "../hooks/useTeams";
import { nanoid } from "nanoid";
import FilterOrganization from "../components/FilterOrganization";
import FilterTeam from "../components/FilterTeam";
import DateFromToFilter from "../components/DateFromToFilter";
import dayjs from "dayjs";

// const initialSearchParams: IFilterPagingParams = {
//   page: "0",
//   filter: "",
//   pageSize: rowsPerpageOptions[4].toString(),
// };

export default function MingleActivities() {
  const [showDeleteDialog, setShowDeleteDialog] =
    React.useState<boolean>(false);

  const [clickedActivityId, setClickedActivityId] = React.useState<
    string | undefined
  >("");

  const navigate = useNavigate();

  const { dataTeams } = useTeams({
    OrganizationIdFilter: "",
    LeagueIdFilter: "",
    TeamNameFilter: "",

    page: "0",
    pageSize: "999",
  });

  let [queryParams, setQueryParams] = useSearchParams();
  const initialActivitySearchParams: ISearchActivityParams = {
    page: queryParams.get("page") || "0",
    SearchFilter: queryParams.get("SearchFilter") || "",
    filter: queryParams.get("filter") || "",
    OrganizationIdFilter: queryParams.get("OrganizationIdFilter") || "",
    TeamIdFilter: queryParams.get("TeamIdFilter") || "",
    pageSize: queryParams.get("pageSize") || rowsPerpageOptions[4].toString(),
    PublishStateFilter:
      queryParams.get("PublishStateFilter") || PublishState.Published,
    "CreatedAtFilter.From":
      queryParams.get("CreatedAtFilter.From") ||
      dayjs().subtract(1, "week").toISOString(),
    "CreatedAtFilter.To":
      queryParams.get("CreatedAtFilter.To") ||
      dayjs().add(1, "week").toISOString(),
  };
  const [activitySearchParams, setActivitySearchParams] = useState(
    initialActivitySearchParams
  );

  const { activitiesIsLoading, dataActivities } =
    useActivities(activitySearchParams);

  const [showDeletedItems, setShowDeletedItems] =
    React.useState<boolean>(false);

  const handleShowDeletedItemsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowDeletedItems(event.target.checked);
  };
  const handleTogglePublishedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleParamsChange(
      event.target.checked
        ? { PublishStateFilter: PublishState.Published }
        : { PublishStateFilter: PublishState.Draft }
    );
  };
  const handleParamsChange = (updatedParam: any) => {
    const newParams: any = {
      ...activitySearchParams,
      ...Object.fromEntries(new URLSearchParams(queryParams.toString())),
      ...updatedParam,
    };

    setActivitySearchParams(newParams);
  };
  const handleOrganizationChange = (
    event: React.ChangeEvent<unknown>,
    organization: IOrganization
  ) => {
    organization
      ? handleParamsChange({ OrganizationIdFilter: organization.id, page: "0" })
      : handleParamsChange({ OrganizationIdFilter: "", page: "0" });
  };

  const handleTeamChange = (event: React.ChangeEvent<unknown>, team: ITeam) => {
    team
      ? handleParamsChange({ TeamIdFilter: team.id, page: "0" })
      : handleParamsChange({ TeamIdFilter: "", page: "0" });
  };
  React.useEffect(() => {
    setQueryParams(activitySearchParams);
  }, [activitySearchParams]);
  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        color="inherit"
        noWrap
        gutterBottom
        sx={{
          textAlign: "center",
          margin: "40px 0px",
        }}
      >
        Activities list & search
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="space-between"
        spacing={2}
      >
        <Button
          component={Link}
          size="small"
          variant="contained"
          color="success"
          to={`/activity/add/`}
          startIcon={<BallotIcon />}
        >
          add new activity
        </Button>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={showDeletedItems}
                onChange={handleShowDeletedItemsChange}
              />
            }
            label="Show deleted items"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={
                  activitySearchParams.PublishStateFilter === "published"
                }
                onChange={handleTogglePublishedChange}
              />
            }
            label="Published?"
          />
        </FormGroup>
      </Stack>

      {!activitiesIsLoading ? (
        <>
          <FilterAndPaging
            data={dataActivities}
            filterPagingParams={activitySearchParams}
            onParamsChange={handleParamsChange}
            TableHeaders={
              <TableRow>
                <StyledTableCell width={400}>Activity content</StyledTableCell>
                <StyledTableCell align="right">Audience</StyledTableCell>
                <StyledTableCell align="right">Action</StyledTableCell>
              </TableRow>
            }
            showSearchField={true}
            filterOptions={
              <>
                <FilterOrganization
                  onOrganizationChange={handleOrganizationChange}
                  initSelectedOrganization={
                    activitySearchParams?.OrganizationIdFilter
                  }
                />
                <FilterTeam
                  onTeamChange={handleTeamChange}
                  initSelectedTeamId={activitySearchParams?.TeamIdFilter}
                />

                <DateFromToFilter
                  dateFrom={dayjs(
                    initialActivitySearchParams["CreatedAtFilter.From"]
                  )}
                  dateTo={dayjs(
                    initialActivitySearchParams["CreatedAtFilter.To"]
                  )}
                  onDateFromToChange={(dateFromValue, dateToValue) => {
                    handleParamsChange({
                      "CreatedAtFilter.From": dateFromValue?.toISOString(),
                      "CreatedAtFilter.To": dateToValue?.toISOString(),
                      page: "0",
                      pageSize: rowsPerpageOptions[1].toString(),
                    });
                  }}
                />
              </>
            }
          >
            {dataActivities?.activities?.map((row) => {
              {
                if ((row.isRemoved && showDeletedItems) || !row.isRemoved) {
                  return (
                    <StyledTableRowNoPointer
                      key={nanoid(6)}
                      hover
                      sx={{
                        // pointerEvents: row.isRemoved ? "none" : "inherit",
                        opacity: row.isRemoved ? ".3" : "1",
                      }}
                    >
                      <StyledTableCell>
                        <Badge
                          variant="dot"
                          color={
                            row.publishState === PublishState.Published
                              ? "success"
                              : "info"
                          }
                          sx={{
                            margin: "8px 6px 10px 4px",
                            opacity:
                              row.publishState === PublishState.Published
                                ? "1"
                                : ".4",
                          }}
                        />
                        {"   "}
                        Created on{" "}
                        {new Date(row.createdAtUtc).toLocaleDateString([], {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                        <ButtonOverviewDetailLink
                          link={`/activity/${row.id}`}
                          title={
                            !!row.content?.en?.title
                              ? row.content?.en?.title
                              : row.content?.nl?.title
                          }
                        />
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                          {!!row.content?.en?.contentTitle
                            ? row.content?.en?.contentTitle
                            : row.content?.nl?.contentTitle}
                        </Typography>
                        Published at{" "}
                        {new Date(row.publishedAtUtc).toLocaleDateString([], {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                        {/*<ReactJson src={row as Object} collapsed={true} />*/}
                      </StyledTableCell>
                      {/*<StyledTableCell>{row.publishState}</StyledTableCell>*/}
                      <StyledTableCell>
                        {!row.organizations.length && !row.teams.length && (
                          <>No organization or team</>
                        )}
                        {Array.isArray(row.teams) && row.teams.length > 0 && (
                          <>
                            <Stack
                              direction="row"
                              spacing={1}
                              justifyContent="flex-end"
                              flexWrap="wrap"
                              alignContent="center"
                              alignItems="center"
                            >
                              {row?.teams.map((team: ITeam, index: number) => {
                                if (index < 5) {
                                  return (
                                    <>
                                      <ButtonTeam team={team as ITeam} />
                                    </>
                                  );
                                }
                              })}
                              <Typography variant="body2">
                                {row?.teams.length} total
                              </Typography>
                            </Stack>
                          </>
                        )}
                        {Array.isArray(row.organizations) &&
                          row.organizations.length > 0 && (
                            <>
                              <Stack
                                direction="row"
                                spacing={1}
                                justifyContent="flex-end"
                                flexWrap="wrap"
                                alignContent="center"
                                alignItems="center"
                              >
                                {row?.organizations.map(
                                  (organization: IOrganization, index) => {
                                    if (index < 5) {
                                      return (
                                        <>
                                          <ButtonOrganization
                                            organizationId={organization.id}
                                          />
                                        </>
                                      );
                                    }
                                  }
                                )}
                                <Typography variant="body2">
                                  {row?.organizations.length} total
                                </Typography>
                              </Stack>
                            </>
                          )}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <ActionMenu
                          itemId={row?.id}
                          handleNewClick={(e) => {
                            navigate(`/activity/add/`);
                          }}
                          handleEditClick={(e, id) => {
                            navigate(`/activity/edit/${id}`);
                          }}
                          handleDeleteClick={(e, id) => {
                            setClickedActivityId(id);
                            setShowDeleteDialog(true);
                          }}
                        />
                      </StyledTableCell>
                    </StyledTableRowNoPointer>
                  );
                }
              }
            })}
          </FilterAndPaging>
          <DialogDeleteActivity
            id={clickedActivityId}
            isOpen={showDeleteDialog}
            onIsOpenChange={(value) => {
              setShowDeleteDialog(value);
            }}
          />
        </>
      ) : (
        <Box sx={{ marginTop: "70px" }}>
          {Array(Number(activitySearchParams.pageSize))
            .fill(1)
            .map((el, i) => (
              <Skeleton animation="wave" height={60} key={nanoid(6)} />
            ))}
        </Box>
      )}
    </>
  );
}

import * as React from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
// @ts-ignore
import JWPlayer from "@jwplayer/jwplayer-react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ThemeColor } from "../utils/api.interfaces";
import Select from "@mui/material/Select";

interface ActivityFieldsProps {
  formik: any;
  lang: string;
}
export const ActivityFields: React.FC<ActivityFieldsProps> = ({
  formik,
  lang,
}) => {
  const [showButton, setShowButton] = React.useState<boolean>(false);

  const matchfeedURL =
    process.env.REACT_APP_ENV === "PROD"
      ? "https://app.mingle.sport"
      : "https://development.alpha-mingle-sport.pages.dev";
  React.useEffect(() => {
    if (formik.values.content?.[lang]?.button.text) {
      setShowButton(true);
    }
  }, [formik.values.content?.[lang]?.button]);
  return (
    <Stack direction="column" spacing={4} sx={{ width: "100%" }}>
      <Typography variant="h3">Content for {lang}</Typography>
      <TextField
        fullWidth
        id={`content.${lang}.title`}
        name={`content.${lang}.title`}
        label="Title"
        value={formik.values.content?.[lang].title}
        onChange={formik.handleChange}
        error={
          formik.touched.content?.[lang]?.title &&
          Boolean(formik.errors.content?.[lang]?.title)
        }
        helperText={
          formik.touched.content?.[lang]?.title &&
          formik.errors.content?.[lang]?.title
        }
      />
      <TextField
        fullWidth
        id={`content.${lang}.contentTitle`}
        name={`content.${lang}.contentTitle`}
        label="Content Title"
        value={formik.values.content[lang].contentTitle}
        onChange={formik.handleChange}
        error={
          formik.touched.content?.[lang]?.contentTitle &&
          Boolean(formik.errors.content?.[lang]?.contentTitle)
        }
        helperText={
          formik.touched.content?.[lang]?.contentTitle &&
          formik.errors.content?.[lang]?.contentTitle
        }
      />
      <TextField
        fullWidth
        multiline
        maxRows={8}
        id={`content.${lang}.contentBody`}
        name={`content.${lang}.contentBody`}
        label="Content Body"
        value={formik.values.content[lang].contentBody}
        onChange={formik.handleChange}
        error={
          formik.touched.content?.[lang]?.contentBody &&
          Boolean(formik.errors.content?.[lang]?.contentBody)
        }
        helperText={
          formik.touched.content?.[lang]?.contentBody &&
          formik.errors.content?.[lang]?.contentBody
        }
      />
      <TextField
        fullWidth
        id={`content.${lang}.pushNotificationContentTitle`}
        name={`content.${lang}.pushNotificationContentTitle`}
        label="Content Title for PushNotification"
        value={formik.values.content[lang].pushNotificationContentTitle}
        onChange={formik.handleChange}
        error={
          formik.touched.content?.[lang]?.pushNotificationContentTitle &&
          Boolean(formik.errors.content?.[lang]?.pushNotificationContentTitle)
        }
        helperText={
          formik.touched.content?.[lang]?.pushNotificationContentTitle &&
          formik.errors.content?.[lang]?.pushNotificationContentTitle
        }
      />
      <TextField
        fullWidth
        multiline
        maxRows={8}
        id={`content.${lang}.pushNotificationContentBody`}
        name={`content.${lang}.pushNotificationContentBody`}
        label="Content Body for PushNotification"
        value={formik.values.content[lang].pushNotificationContentBody}
        onChange={formik.handleChange}
        error={
          formik.touched.content?.[lang]?.pushNotificationContentBody &&
          Boolean(formik.errors.content?.[lang]?.pushNotificationContentBody)
        }
        helperText={
          formik.touched.content?.[lang]?.pushNotificationContentBody &&
          formik.errors.content?.[lang]?.pushNotificationContentBody
        }
      />
      <FormControlLabel
        sx={{
          margin: " 20px auto",
        }}
        control={
          <Switch
            checked={showButton}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              e.target.checked ? setShowButton(true) : setShowButton(false);
            }}
          />
        }
        label="Show a button?"
      />
      {showButton && (
        <>
          <TextField
            fullWidth
            id={`content.${lang}.button.text`}
            name={`content.${lang}.button.text`}
            label="Button text"
            value={formik.values.content?.[lang]?.button?.text}
            onChange={formik.handleChange}
            error={
              formik.touched.content?.[lang]?.button?.text &&
              Boolean(formik.errors.content?.[lang]?.button?.text)
            }
            helperText={
              formik.touched.content?.[lang]?.button?.text &&
              formik.errors.content?.[lang]?.button?.text
            }
          />
          <TextField
            fullWidth
            multiline
            id={`content.${lang}.button.url`}
            name={`content.${lang}.button.url`}
            label="Button url"
            value={formik.values.content?.[lang]?.button?.url}
            onChange={formik.handleChange}
            error={
              formik.touched.content?.[lang]?.button?.url &&
              Boolean(formik.errors.content?.[lang]?.button?.url)
            }
            helperText={
              formik.touched.content?.[lang]?.button?.url &&
              formik.errors.content?.[lang]?.button?.url
            }
          />
          <div>
            <Typography variant="h4" gutterBottom>
              For internal linking use the following for the button url:
            </Typography>
            <div
              onClick={() =>
                formik.setFieldValue(
                  `content.${lang}.button.url`,
                  `${matchfeedURL}/user/follower/search`
                )
              }
              style={{ cursor: "pointer" }}
            >
              <Typography variant="body1">
                Search follower <small>(click to prefill)</small>
              </Typography>
              <Typography variant="body2" gutterBottom>
                {`${matchfeedURL}/user/follower/search`}
              </Typography>
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() =>
                formik.setFieldValue(
                  `content.${lang}.button.url`,
                  `${matchfeedURL}/match/join/{MATCHFEEDID}`
                )
              }
            >
              <Typography variant="body1">
                Join matchfeed <small>(click to prefill)</small>
              </Typography>
              <Typography variant="body2" gutterBottom>
                {`${matchfeedURL}/match/join/{MATCHFEEDID}`}
              </Typography>
            </div>
            <div
              onClick={() =>
                formik.setFieldValue(
                  `content.${lang}.button.url`,
                  `${matchfeedURL}/match/rateplayer/{MATCHFEEDID}`
                )
              }
              style={{ cursor: "pointer" }}
            >
              <Typography variant="body1">
                Rate player <small>(click to prefill)</small>
              </Typography>
              <Typography variant="body2" gutterBottom>
                {`${matchfeedURL}/match/rateplayer/{MATCHFEEDID}`}
              </Typography>
            </div>
          </div>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" shrink={true}>
              Button style
            </InputLabel>
            <Select
              labelId={`content.${lang}.button.style.label`}
              id={`content.${lang}.button.style`}
              name={`content.${lang}.button.style`}
              value={formik.values.content?.[lang]?.button?.style}
              label="button.style"
              onChange={formik.handleChange}
            >
              <MenuItem value={ThemeColor.Primary}>
                {ThemeColor.Primary}
              </MenuItem>
              <MenuItem value={ThemeColor.Secondary}>
                {ThemeColor.Secondary}
              </MenuItem>
            </Select>
          </FormControl>
        </>
      )}
      <hr />
    </Stack>
  );
};
export default ActivityFields;

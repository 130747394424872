import React from "react";
import { useQuery } from "@tanstack/react-query";
import {
  ILeague,
  ILeagueAdd,
  ILeaguesApiCall,
  IExportData,
  IFilterPagingParams,
  ISearchLeaguesParams,
} from "utils/api.interfaces";
import axiosinterceptor from "../utils/api.interceptor";
import {
  filterLeagueParams,
  filterOrganizationParams,
  filterparams,
} from "../utils/common";
import axios from "axios";

export const fetchLeague = (leagueId: string | undefined): Promise<ILeague> =>
  axiosinterceptor

    .get(`/v1/Leagues/${leagueId}`)
    .then<ILeague>((res) => res.data);

const fetchLeagues = (
  params: ISearchLeaguesParams
): Promise<ILeaguesApiCall> => {
  return axiosinterceptor
    .get(`/v1/Leagues`, { params: filterLeagueParams(params) })
    .then<ILeaguesApiCall>((res) => res.data);
};
export function useLeagues(params: ISearchLeaguesParams) {
  const queryLeagues = useQuery(
    ["leagues", params],
    () => fetchLeagues(params),
    {
      keepPreviousData: true,
    }
  );

  return {
    ...queryLeagues,
    dataLeagues: queryLeagues.data,
    clubsIsLoading: queryLeagues.isLoading,
  };
}

export function useLeague(leagueId: string | undefined) {
  const queryLeague = useQuery(
    ["league", leagueId],
    () => fetchLeague(leagueId),
    {
      enabled: !!leagueId,
    }
  );

  return {
    ...queryLeague,
    dataLeague: queryLeague?.data,
    dataLeagueIsLoading: queryLeague?.isLoading,
  };
}
export const putLeague = (league: ILeagueAdd): Promise<any> => {
  return axiosinterceptor.put(`/v1/Leagues`, league).then((res) => {
    return res.data;
  });
};
export const postLeague = (league: ILeagueAdd): Promise<any> => {
  return axiosinterceptor.post(`/v1/Leagues`, league).then((res) => {
    return res.data;
  });
};

export const postLeagueImage = (image: File[]): Promise<any> => {
  const formData = new FormData();
  formData.append("imageFile", image[0]);

  return axiosinterceptor
    .post(`/v1/Leagues/UploadLogo`, formData)
    .then((res) => {
      return res.data;
    });
};
export const deleteLeague = (leagueId: string): Promise<any> => {
  return axiosinterceptor.delete(`/v1/Leagues?Id=${leagueId}`).then((res) => {
    return res.data;
  });
};

import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Transition } from "../utils/common";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { useQueryClient } from "@tanstack/react-query";
import { deleteUser } from "../hooks/useUser";

interface DialogDeleteUserProps {
  id?: string;
  isOpen: boolean;
  onIsOpenChange: (isOpen: boolean) => void;
}
const DialogDeleteUser: React.FC<DialogDeleteUserProps> = ({
  id,
  isOpen,
  onIsOpenChange,
}) => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const [showDeleteDialog, setShowDeleteDialog] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    setShowDeleteDialog(isOpen);
  }, [isOpen]);

  React.useEffect(() => {
    onIsOpenChange(showDeleteDialog);
  }, [showDeleteDialog]);
  return (
    <Dialog
      open={showDeleteDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        setShowDeleteDialog(false);
      }}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: { backgroundColor: "#fff", minWidth: "400px" },
      }}
    >
      <DialogTitle>{" Are you sure you want to delete this user?"}</DialogTitle>

      <DialogActions
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          width: "100%",
        }}
      >
        <Button
          size="small"
          color="error"
          variant="outlined"
          onClick={() => {
            if (id) {
              deleteUser(id).then((res) => {
                navigate("/users");
                setShowDeleteDialog(false);
                queryClient.invalidateQueries(["users"]);
              });
            }
          }}
        >
          yes
        </Button>
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          onClick={() => {
            setShowDeleteDialog(false);
          }}
        >
          no
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDeleteUser;

import { Avatar, Button, CircularProgress, IconButton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import * as React from "react";
import { useOrganization } from "../hooks/useOrganization";
import { useLeague } from "../hooks/useLeague";
import { useFormik } from "formik";
import { putArchiveTeam, putUnArchiveTeam } from "../hooks/useTeam";
import { ITeam } from "utils/api.interfaces";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import ArchiveIcon from "@mui/icons-material/Archive";

interface ButtonArchiveProps {
  team?: ITeam;
  refetch: () => Promise<any>;
}
const ButtonArchiveTeam: React.FC<ButtonArchiveProps> = ({ team, refetch }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState<Boolean>(false);
  const formik = useFormik({
    initialValues: { isArchived: team?.isArchived || false },
    enableReinitialize: true,
    onSubmit: (values: any) => {
      setIsLoading(true);
      console.log("values", values);
      if (!values.isArchived && team) {
        putArchiveTeam(team.id)
          .then((res) => {
            refetch().then((res) => {
              setIsLoading(false);
            });
          })
          .catch((e: any) => {
            setIsLoading(false);
          });
      } else if (values.isArchived && team) {
        putUnArchiveTeam(team.id)
          .then((res) => {
            setIsLoading(false);
            refetch().then((res) => {
              setIsLoading(false);
            });
          })
          .catch((e: any) => {
            setIsLoading(false);
          });
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <input type="hidden" name="id" value={team?.teamId} />
      <Button
        color="error"
        variant="outlined"
        size="small"
        type="submit"
        startIcon={team?.isArchived ? <UnarchiveIcon /> : <ArchiveIcon />}
        sx={{ fontSize: "12px", padding: "5px 10px" }}
        endIcon={
          isLoading && (
            <IconButton aria-label="" edge="end">
              <CircularProgress
                size={15}
                sx={{ color: "#68F3CB" }}
                color="success"
              />
            </IconButton>
          )
        }
      >
        {team?.isArchived ? "Unarchive" : "Archive"} team
      </Button>
    </form>
  );
};

export default ButtonArchiveTeam;

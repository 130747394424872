import {
  Button,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Transition } from "../utils/common";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { useQueryClient } from "@tanstack/react-query";
import { deleteUser } from "../hooks/useUser";
import { postOpponent } from "../hooks/useOpponents";
import { IMatchAdd, IOpponentAdd, ITeam } from "../utils/api.interfaces";
import { useFormik } from "formik";
import { postMatch } from "../hooks/useMatch";
import * as yup from "yup";
import DialogContent from "@mui/material/DialogContent";
import { nanoid } from "nanoid";
import useOrganizations from "../hooks/useOrganizations";
import AutocompleteDebounce from "./AutocompleteDebounce";

interface DialogAddOpponentrProps {
  team?: ITeam;
  isOpen: boolean;
  leagueId?: string;
  onIsOpenChange: (isOpen: boolean) => void;
  onIsOpponentAdded: (isAdded: boolean, id: string) => void;
}
const validationSchema = yup.object({
  name: yup.string().required("A name is required"),
});
const DialogAddOpponent: React.FC<DialogAddOpponentrProps> = ({
  team,
  isOpen,
  onIsOpenChange,
  leagueId,
  onIsOpponentAdded,
}) => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const [showOpponent, setShowOpponentDialog] = React.useState<boolean>(false);

  React.useEffect(() => {
    setShowOpponentDialog(isOpen);
  }, [isOpen]);

  React.useEffect(() => {
    onIsOpenChange(showOpponent);
  }, [showOpponent]);
  const [organizationFilter, setOrganizationFilter] =
    React.useState<string>("");
  const initOpponent: IOpponentAdd = {
    id: "",
    name: "",
    teamId: team?.id,
    organizationId: team?.organization?.id,
  };
  const { dataOrganizations } = useOrganizations({
    NameFilter: organizationFilter,
    LeagueId: leagueId ? leagueId : "",
    page: "0",
    pageSize: "99",
  });
  const handleOrganizationChange = (
    event: React.ChangeEvent<unknown>,
    value: any
  ) => {
    console.log(value);
    formik.setFieldValue("organizationId", value.id);
  };

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const formik = useFormik({
    initialValues: initOpponent,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values: IOpponentAdd) => {
      console.log(values);
      if (values.teamId) {
        setIsLoading(true);
        postOpponent(values)
          .then((res) => {
            console.log(res);
            onIsOpponentAdded(true, res);

            setIsLoading(false);
          })
          .catch((e: any) => {
            console.log(e);
            setIsLoading(false);
          });
      }
    },
  });

  return (
    <Dialog
      open={showOpponent}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        setShowOpponentDialog(false);
      }}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: { backgroundColor: "#fff", minWidth: "400px", height: "660px" },
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle variant="h4">{"Add new opponent?"}</DialogTitle>
        <DialogContent>
          <Stack
            direction="column"
            spacing={4}
            justifyContent="center"
            alignItems="flex-start"
            style={{ paddingTop: "10px" }}
          >
            {dataOrganizations && leagueId !== "" && (
              <AutocompleteDebounce
                handleChange={handleOrganizationChange}
                data={dataOrganizations.organizations}
                onFilterChange={(value) => setOrganizationFilter(value)}
                labelTitle="Select an organization"
                formikErrors={formik.errors.organizationId}
                formikTouched={formik.touched.organizationId}
              />
            )}

            <TextField
              fullWidth
              id="name"
              name="name"
              label="name"
              type="text"
              defaultValue={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={() => {
              setShowOpponentDialog(false);
            }}
          >
            close
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="outlined"
            type="submit"
            endIcon={
              isLoading && (
                <IconButton aria-label="" edge="end">
                  <CircularProgress
                    size={15}
                    sx={{ color: "#68F3CB" }}
                    color="success"
                  />
                </IconButton>
              )
            }
          >
            add opponent
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogAddOpponent;

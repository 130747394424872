import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled, TableRow } from "@mui/material";
import {
  IFilterOrganizationParams,
  IFilterPagingParams,
  ISearchActivityParams,
  ISearchLeaguesParams,
  ISearchMatchesParams,
  ISearchMiniGamesAttemptsParams,
  ISearchMiniGamesParams,
  ISearchTeamsParams,
} from "./api.interfaces";
import * as React from "react";
import { TransitionProps } from "@mui/material/transitions";
import Zoom from "@mui/material/Zoom";

export const baseWebApiUrl: string | undefined = process.env.REACT_APP_API_URL;

export const filterparams = (params: IFilterPagingParams) => {
  return Object.fromEntries(
    Object.entries({
      filter: params.filter,
      pageSize: params.pageSize,
      page: params.page,
    }).filter(([_, v]) => v != "")
  );
};

export const filterMatchParams = (params: ISearchMatchesParams) => {
  return Object.fromEntries(
    Object.entries({
      TeamIdFilter: params.TeamIdFilter,
      OrganizationIdFilter: params.OrganizationIdFilter,
      pageSize: params.pageSize,
      "ScheduledAtFilter.From": params["ScheduledAtFilter.From"],
      "ScheduledAtFilter.To": params["ScheduledAtFilter.To"],

      page: params.page,
    }).filter(([_, v]) => v != "")
  );
};
export const filterMiniGamesParams = (params: ISearchMiniGamesParams) => {
  return Object.fromEntries(
    Object.entries({
      pageSize: params.pageSize,
      page: params.page,
      status: params.status,
    }).filter(([_, v]) => v != "")
  );
};
export const filterMiniGamesAttemptsParams = (
  params: ISearchMiniGamesAttemptsParams
) => {
  return Object.fromEntries(
    Object.entries({
      pageSize: params.pageSize,
      page: params.page,
      sessionId: params.sessionId,
    }).filter(([_, v]) => v != "")
  );
};
export const filterLeagueParams = (params: ISearchLeaguesParams) => {
  return Object.fromEntries(
    Object.entries({
      pageSize: params.pageSize,
      page: params.page,
      CountryCode: params.CountryCode,
      NameFilter: params.NameFilter,
    }).filter(([_, v]) => v != "")
  );
};
export const filterOrganizationParams = (params: IFilterOrganizationParams) => {
  return Object.fromEntries(
    Object.entries({
      pageSize: params.pageSize,
      page: params.page,
      LeagueId: params.LeagueId,
      NameFilter: params.NameFilter,
    }).filter(([_, v]) => v != "")
  );
};
export const filterTeamsParams = (params: ISearchTeamsParams) => {
  return Object.fromEntries(
    Object.entries({
      pageSize: params.pageSize,
      page: params.page,
      OrganizationId: params.OrganizationIdFilter,
      LeagueId: params.LeagueIdFilter,
      TeamNameFilter: params.TeamNameFilter,
    }).filter(([_, v]) => v != "")
  );
};
export const filterActivityParams = (params: ISearchActivityParams) => {
  return Object.fromEntries(
    Object.entries({
      SearchFilter: params.SearchFilter,
      pageSize: params.pageSize,
      page: params.page,
      OrganizationIdFilter: params.OrganizationIdFilter,
      TeamIdFilter: params.TeamIdFilter,
      PublishStateFilter: params.PublishStateFilter,
      "CreatedAtFilter.From": params["CreatedAtFilter.From"],
      "CreatedAtFilter.To": params["CreatedAtFilter.To"],
    }).filter(([_, v]) => v != "")
  );
};

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.light,
    fontWeight: 700,
    borderBottom: `2px solid ${theme.palette.primary.light}`,

    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&:hover > td": {
    backgroundColor: theme.palette.secondary.light,
  },
  " > td": {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },

  "&:nth-of-type(odd)": {
    backgroundColor: "#fafafa",
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.background.default,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export const StyledTableRowNoPointer = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fafafa",
  },
  "&:hover > td": {
    backgroundColor: theme.palette.secondary.light,
  },
  "&:hover": {
    backgroundColor: theme.palette.secondary.light,
  },
  " > td": {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.background.default,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export function skipEmpties(dirty: any): any {
  let item: any;
  if (Array.isArray(dirty)) {
    item = dirty
      .map((x) => skipEmpties(x))
      .filter((value) => value !== undefined);
    return item.length ? item : undefined;
  } else if (dirty && typeof dirty === "object") {
    item = {};
    Object.keys(dirty).forEach((key) => {
      const value = skipEmpties(dirty[key]);
      if (value !== "") {
        item[key] = value;
      }
    });
    return Object.keys(item).length ? item : undefined;
  } else {
    return dirty === null ? undefined : dirty;
  }
}

export const BooleanCheck = (value: any) => {
  switch (value) {
    case true:
    case "true":
    case 1:
    case "1":
    case "on":
    case "yes":
      return true;
    default:
      return false;
  }
};

export const removeDuplicate = (a: any, param: string) => {
  if (!a) return [];
  return a.filter(function (item: any, pos: any, array: any) {
    return (
      array
        .map(function (mapItem: any) {
          return mapItem[param];
        })
        .indexOf(item[param]) === pos
    );
  });
};

import * as React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import { generate } from "generate-password-browser";
import {
  AccountRoleEnum,
  IOrganization,
  IUserAdd,
  PreferredPositionEnum,
  PrivacyEnum,
} from "utils/api.interfaces";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Transition } from "utils/common";
import { useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import UploadImage from "../components/UploadImage";
import FormControlLabel from "@mui/material/FormControlLabel";
import AutocompleteDebounce from "../components/AutocompleteDebounce";
import ButtonOrganization from "../components/ButtonOrganization";
import DeleteIcon from "@mui/icons-material/Delete";
import useOrganizations from "../hooks/useOrganizations";
import { ErrorMessageSlide } from "../components/ErrorMessageSlide";
import { postImage, postUser } from "../hooks/useUser";

export default function UserAdd() {
  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string()
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),

    profile: yup.object().shape({
      firstName: yup.string().required("First name is required"),
      lastName: yup.string().required("Last name is required"),
      // imageUrl: yup.string().required("ImageUrl is required"),
    }),
  });

  const initUser: IUserAdd = {
    password: "",
    email: "",
    birthday: "",
    isGhost: false,
    accountRole: AccountRoleEnum.Player,
    profile: {
      firstName: "",
      lastName: "",
      privacy: PrivacyEnum.Private,
      imageFileName: null,
      preferredPosition: PreferredPositionEnum.Striker,
    },
    clubAdminOf: [],
  };
  const [showSlideError, setShowSlideError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<Boolean>(false);

  const navigate = useNavigate();
  const [acceptedFiles, setAcceptedFiles] = React.useState<File[]>([]);
  const [showPassword, setShowPassword] = React.useState(false);
  const [newUser, setNewUser] = React.useState<string | undefined>(undefined);
  const [userClubIds, setUserClubIds] = React.useState<any[]>([]);
  React.useEffect(() => {
    formik.setFieldValue("clubAdminOf", userClubIds);
  }, [userClubIds]);
  const handleOrganizationChange = (
    event: React.ChangeEvent<unknown>,
    club: IOrganization
  ) => {
    if (club) {
      setUserClubIds((userClubIds) => [...userClubIds, club.id]);
    }
  };
  const [clubFilter, setClubFilter] = React.useState<string>("");
  const { dataOrganizations } = useOrganizations({
    NameFilter: clubFilter,
    LeagueId: "",
    page: "0",
    pageSize: "99",
  });
  const deleteUserClubIds = (organizationId: string) => {
    if (organizationId) {
      setUserClubIds(userClubIds.filter((item) => item !== organizationId));
    }
  };
  const formik = useFormik({
    initialValues: initUser,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (acceptedFiles && acceptedFiles?.length > 0) {
        setIsLoading(true);

        postImage(acceptedFiles)
          .then((res) => {
            postUser({
              ...values,
              birthday: values.birthday
                ? new Date(values.birthday).toISOString()
                : "",
              profile: {
                ...values.profile,
                imageFileName: res.fileName,
              },
            })
              .then((res) => {
                setNewUser(res.id);
                setIsLoading(false);
              })
              .catch((e: any) => {
                setIsLoading(false);
                setShowSlideError(true);
              });
          })
          .catch((e: any) => {
            setIsLoading(false);
            setShowSlideError(true);
          });
      } else {
        postUser({
          ...values,
          birthday: values.birthday
            ? new Date(values.birthday).toISOString()
            : "",
          profile: {
            ...values.profile,
            imageFileName: null,
          },
        })
          .then((res) => {
            setNewUser(res.id);
            setIsLoading(false);
          })
          .catch((e: any) => {
            setShowSlideError(true);
            setIsLoading(false);
          });
      }
    },
  });

  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        color="inherit"
        noWrap
        gutterBottom
        sx={{
          textAlign: "center",
          margin: "40px 0px",
        }}
      >
        Add new user
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          spacing={3}
          sx={{
            maxWidth: "500px",
            margin: "auto",
            opacity: newUser || isLoading ? 0.4 : 1,
            pointerEvents: isLoading ? "none" : "auto",
          }}
        >
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            fullWidth
            id="password"
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="generate a new password"
                    title="generate a new password"
                    onClick={() => {
                      formik.setFieldValue(
                        "password",
                        generate({
                          length: 15,
                          numbers: true,
                          symbols: true,
                          lowercase: true,
                          uppercase: true,
                        })
                      );
                    }}
                  >
                    <ShuffleIcon />
                  </IconButton>
                  <IconButton
                    aria-label="toggle password visibilty"
                    title="toggle password visibilty"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            id="firstName"
            name="profile.firstName"
            label="First name"
            value={formik.values.profile.firstName}
            onChange={formik.handleChange}
            error={
              formik.touched.profile?.firstName &&
              Boolean(formik.errors.profile?.firstName)
            }
            helperText={
              formik.touched.profile?.firstName &&
              formik.errors.profile?.firstName
            }
          />
          <TextField
            fullWidth
            id="lastName"
            name="profile.lastName"
            label="Last name"
            value={formik.values.profile.lastName}
            onChange={formik.handleChange}
            error={
              formik.touched.profile?.lastName &&
              Boolean(formik.errors.profile?.lastName)
            }
            helperText={
              formik.touched.profile?.lastName &&
              formik.errors.profile?.lastName
            }
          />
          <TextField
            fullWidth
            id="birthday"
            name="birthday"
            label="Date of Birth"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={formik.values.birthday}
            onChange={formik.handleChange}
            error={formik.touched.birthday && Boolean(formik.errors.birthday)}
            helperText={formik.touched.birthday && formik.errors.birthday}
          />

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" shrink={true}>
              AccountRole
            </InputLabel>
            <Select
              labelId="accountRole"
              id="accountRole"
              name="accountRole"
              value={formik.values.accountRole}
              label="accountRole"
              onChange={formik.handleChange}
            >
              <MenuItem value={AccountRoleEnum.Player}>
                {AccountRoleEnum.Player}
              </MenuItem>
              <MenuItem value={AccountRoleEnum.Trainer}>
                {AccountRoleEnum.Trainer}
              </MenuItem>
              <MenuItem value={AccountRoleEnum.Support}>
                {AccountRoleEnum.Support}
              </MenuItem>
              <MenuItem value={AccountRoleEnum.Fan}>
                {AccountRoleEnum.Fan}
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" shrink={true}>
              Preffered Position
            </InputLabel>
            <Select
              labelId="preferredPosition"
              id="preferredPosition"
              name="profile.preferredPosition"
              value={formik.values.profile.preferredPosition}
              label="preferredPosition"
              onChange={formik.handleChange}
            >
              <MenuItem value={PreferredPositionEnum.Goalkeeper}>
                {PreferredPositionEnum.Goalkeeper} - Goalkeeper
              </MenuItem>
              <MenuItem value={PreferredPositionEnum.Centreback}>
                {PreferredPositionEnum.Centreback} - Centreback
              </MenuItem>
              <MenuItem value={PreferredPositionEnum.Leftback}>
                {PreferredPositionEnum.Leftback} - Leftback
              </MenuItem>
              <MenuItem value={PreferredPositionEnum.Rightback}>
                {PreferredPositionEnum.Rightback} - Rightback
              </MenuItem>
              <MenuItem value={PreferredPositionEnum.DefensiveMidfielder}>
                {PreferredPositionEnum.DefensiveMidfielder} -
                DefensiveMidfielder
              </MenuItem>
              <MenuItem value={PreferredPositionEnum.CentralMidfielder}>
                {PreferredPositionEnum.CentralMidfielder} - CentralMidfielder
              </MenuItem>
              <MenuItem value={PreferredPositionEnum.LeftMidfielder}>
                {PreferredPositionEnum.LeftMidfielder} - LeftMidfielder
              </MenuItem>
              <MenuItem value={PreferredPositionEnum.RightMidfielder}>
                {PreferredPositionEnum.RightMidfielder} - RightMidfielder
              </MenuItem>
              <MenuItem value={PreferredPositionEnum.AttackingMidfielder}>
                {PreferredPositionEnum.AttackingMidfielder} -
                AttackingMidfielder
              </MenuItem>
              <MenuItem value={PreferredPositionEnum.LeftWinger}>
                {PreferredPositionEnum.LeftWinger} - LeftWinger
              </MenuItem>
              <MenuItem value={PreferredPositionEnum.RightWinger}>
                {PreferredPositionEnum.RightWinger} - RightWinger
              </MenuItem>
              <MenuItem value={PreferredPositionEnum.Striker}>
                {PreferredPositionEnum.Striker} - Striker
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" shrink={true}>
              Privacy
            </InputLabel>
            <Select
              labelId="privacy"
              id="privacy"
              name="profile.privacy"
              value={formik.values.profile.privacy}
              label="privacy"
              onChange={formik.handleChange}
            >
              <MenuItem value={PrivacyEnum.Private}>
                {PrivacyEnum.Private}
              </MenuItem>
              <MenuItem value={PrivacyEnum.Public}>
                {PrivacyEnum.Public}
              </MenuItem>
              <MenuItem value={PrivacyEnum.Followers}>
                {PrivacyEnum.Followers}
              </MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            sx={{
              margin: " 20px auto",
            }}
            control={
              <Switch
                checked={formik.values.isGhost}
                inputProps={{ "aria-label": "controlled" }}
                value={formik.values.isGhost}
                onChange={(e) => {
                  e.target.checked
                    ? formik.setFieldValue("isGhost", true)
                    : formik.setFieldValue("isGhost", false);
                }}
              />
            }
            label="User is 👻 user?"
          />
          <AutocompleteDebounce
            handleChange={handleOrganizationChange}
            data={dataOrganizations?.organizations}
            onFilterChange={(value) => setClubFilter(value)}
            labelTitle="Add as admin to club(s)"
            formikErrors={formik.errors.clubAdminOf}
            formikTouched={formik.touched.clubAdminOf}
          />
          <Stack direction="row" spacing={0}>
            {userClubIds.map((organizationId) => {
              return (
                <>
                  <ButtonOrganization organizationId={organizationId} />
                  <IconButton
                    aria-label="delete"
                    color="error"
                    onClick={(e) => {
                      deleteUserClubIds(organizationId);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              );
            })}
          </Stack>

          <UploadImage
            maxFiles={1}
            onAcceptedFilesChange={(files: File[]) => {
              setAcceptedFiles(files);
            }}
          />

          <Button
            color="secondary"
            variant="contained"
            fullWidth
            type="submit"
            endIcon={
              isLoading && (
                <IconButton aria-label="" edge="end">
                  <CircularProgress
                    size={15}
                    sx={{ color: "#68F3CB" }}
                    color="success"
                  />
                </IconButton>
              )
            }
          >
            Add user
          </Button>
          <ErrorMessageSlide showError={showSlideError} />
        </Stack>

        <Dialog
          open={!!newUser}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            setNewUser(undefined);
          }}
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{
            sx: { backgroundColor: "#fff", minWidth: "400px" },
          }}
        >
          <DialogTitle variant="h4">{" User successfully added!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              onClick={() => {
                setNewUser(undefined);
              }}
            >
              close
            </Button>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={() => navigate(`/users/${newUser}`)}
            >
              Go to user page
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </>
  );
}

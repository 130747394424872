import { TextField } from "@mui/material";
import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";

interface DateFilterProps {
  dateFrom?: Dayjs | undefined;
  dateTo?: Dayjs | undefined;
  onDateFromToChange: (
    dateFromValue: Dayjs | undefined,
    dateToValue: Dayjs | undefined
  ) => void;
}
const DateFromTo: React.FC<DateFilterProps> = ({
  dateFrom,
  dateTo,
  onDateFromToChange,
}) => {
  const [dateFromValue, setDateFromValue] = React.useState<Dayjs | undefined>(
    dateFrom?.set("hour", 13).set("minute", 0).set("second", 0)
  );
  const [dateToValue, setDateToValue] = React.useState<Dayjs | undefined>(
    dateTo?.set("hour", 12).set("minute", 59).set("second", 59)
  );

  const handleDateFromChange = (newValue: Dayjs | any) => {
    const newDate = newValue.set("hour", 13).set("minute", 0).set("second", 0);

    setDateFromValue(newDate);
    onDateFromToChange(newDate, dateToValue);
  };
  const handleDateToChange = (newValue: Dayjs | any) => {
    const newDate = newValue
      .set("hour", 12)
      .set("minute", 59)
      .set("second", 59);
    setDateToValue(newDate);
    onDateFromToChange(dateFromValue, newValue);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"nl"}>
      <DatePicker
        label="From"
        value={dateFromValue}
        maxDate={dateToValue}
        onChange={handleDateFromChange}
        renderInput={(params) => <TextField {...params} />}
      />
      <DatePicker
        label="To"
        minDate={dateFromValue}
        value={dateToValue}
        onChange={handleDateToChange}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};

export default DateFromTo;

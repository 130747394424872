import * as React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import {
  getCountries,
  IOrganizationAdd,
  OrganizationEnum,
} from "utils/api.interfaces";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Transition } from "utils/common";
import { useNavigate } from "react-router-dom";
import UploadImage from "../components/UploadImage";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTheme } from "@mui/material/styles";
import Select from "@mui/material/Select";
import { ErrorMessageSlide } from "../components/ErrorMessageSlide";
import {
  postOrganization,
  postOrganizationImage,
} from "../hooks/useOrganization";
import * as net from "net";
import AutocompleteDebounce from "../components/AutocompleteDebounce";
import { useLeagues } from "../hooks/useLeague";

export default function OrganizationAdd() {
  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    shortName: yup.string().required("Name is required"),
  });

  const initOrganization: IOrganizationAdd = {
    name: "",
    shortName: "",
    isGhost: false,
    logoName: "",
    leagueId: "",
    type: OrganizationEnum.Club,
    sportType: "Football",
    countryCode: "NL",
    address: {
      line1: "",
      line2: "",
      zipCode: "",
      city: "",
      region: "",
    },
  };

  const [acceptedFiles, setAcceptedFiles] = React.useState<File[]>([]);
  const navigate = useNavigate();
  const [showSlideError, setShowSlideError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<Boolean>(false);
  const [newOrganization, setNewOrganization] = React.useState<
    string | undefined
  >(undefined);
  const [dateValue, setDateValue] = React.useState<Date | null>(null);

  const [leaguesFilter, setLeaguesFilter] = React.useState<string>("");

  const { dataLeagues } = useLeagues({
    NameFilter: leaguesFilter,
    CountryCode: "",
    page: "0",
    pageSize: "99",
  });
  const handleLeagueChange = (
    event: React.ChangeEvent<unknown>,
    value: any
  ) => {
    formik.setFieldValue("leagueId", value.id);
  };
  const handleDateChange = (newValue: Date | null) => {
    setDateValue(newValue);
    formik.setFieldValue("foundedDate", newValue?.toISOString());
  };
  const theme = useTheme();
  const formik = useFormik({
    initialValues: initOrganization,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (acceptedFiles && acceptedFiles?.length > 0) {
        setIsLoading(true);
        postOrganizationImage(acceptedFiles)
          .then((res) => {
            postOrganization({
              ...values,
              logoUrl: res.fileUrl,
              logoName: res.fileName,
            })
              .then((res) => {
                setIsLoading(false);
                setNewOrganization(res.id);
              })
              .catch((e: any) => {
                setShowSlideError(true);
                setIsLoading(false);
              });
          })
          .catch((e: any) => {
            setShowSlideError(true);
            setIsLoading(false);
          });
      } else {
        postOrganization({
          ...values,
        })
          .then((res) => {
            setIsLoading(false);
            setNewOrganization(res.id);
          })
          .catch((e: any) => {
            setShowSlideError(true);
            setIsLoading(false);
          });
      }
    },
  });

  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        color="inherit"
        noWrap
        gutterBottom
        sx={{
          textAlign: "center",
          margin: "40px 0px",
        }}
      >
        Add new Organization
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          spacing={3}
          sx={{
            maxWidth: "500px",
            margin: "auto",
            opacity: newOrganization || isLoading ? 0.4 : 1,
            pointerEvents: isLoading ? "none" : "auto",
          }}
        >
          <UploadImage
            maxFiles={1}
            onAcceptedFilesChange={(files: File[]) => {
              setAcceptedFiles(files);
            }}
          />

          <TextField
            fullWidth
            id="name"
            name="name"
            label="Name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            fullWidth
            id="shortName"
            name="shortName"
            label="Short name"
            type="text"
            value={formik.values.shortName}
            onChange={formik.handleChange}
            error={formik.touched.shortName && Boolean(formik.errors.shortName)}
            helperText={formik.touched.shortName && formik.errors.shortName}
          />
          {dataLeagues && (
            <>
              <AutocompleteDebounce
                handleChange={handleLeagueChange}
                data={dataLeagues.leagues}
                onFilterChange={(value) => setLeaguesFilter(value)}
                labelTitle="Select a league"
                formikErrors={formik.errors.leagueId}
                formikTouched={formik.touched.leagueId}
              />
            </>
          )}
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" shrink={true}>
              Sport Type
            </InputLabel>
            <Select
              labelId="sportType"
              id="sportType"
              name="sportType"
              value={formik.values.sportType}
              label="sportType"
              onChange={formik.handleChange}
            >
              <MenuItem value="Football">Football</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" shrink={true}>
              Type
            </InputLabel>
            <Select
              labelId="type"
              id="type"
              name="type"
              value={formik.values.type}
              label="sportType"
              onChange={formik.handleChange}
            >
              <MenuItem value={OrganizationEnum.Default}>
                {OrganizationEnum.Default}
              </MenuItem>
              <MenuItem value={OrganizationEnum.Club}>
                {OrganizationEnum.Club}
              </MenuItem>
              <MenuItem value={OrganizationEnum.HighSchool}>
                {OrganizationEnum.HighSchool}
              </MenuItem>
              <MenuItem value={OrganizationEnum.University}>
                {OrganizationEnum.University}
              </MenuItem>
              <MenuItem value={OrganizationEnum.College}>
                {OrganizationEnum.College}
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" shrink={true}>
              Country code
            </InputLabel>

            <Select
              labelId="countryCode"
              id="countryCode"
              name="countryCode"
              value={formik.values.countryCode}
              label="countryCode"
              onChange={formik.handleChange}
            >
              {getCountries().map((country: any) => (
                <MenuItem key={country.code} value={country.code}>
                  {country.name} ({country.code})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography component="h2" variant="h4">
            Address
          </Typography>
          <TextField
            fullWidth
            id="addressLine1"
            name="address.line1"
            label="Addres line 1"
            type="text"
            value={formik.values.address.line1}
            onChange={formik.handleChange}
            error={
              formik.touched.address?.line1 &&
              Boolean(formik.errors.address?.line1)
            }
            helperText={
              formik.touched.address?.line1 && formik.errors.address?.line1
            }
          />
          <TextField
            fullWidth
            id="addressLine2"
            name="address.line2"
            label="Addres line 2"
            type="text"
            value={formik.values.address.line2}
            onChange={formik.handleChange}
            error={
              formik.touched.address?.line2 &&
              Boolean(formik.errors.address?.line2)
            }
            helperText={
              formik.touched.address?.line2 && formik.errors.address?.line2
            }
          />
          <TextField
            fullWidth
            id="addressZipCode"
            name="address.zipCode"
            label="Addres zipCode"
            type="text"
            value={formik.values.address.zipCode}
            onChange={formik.handleChange}
            error={
              formik.touched.address?.zipCode &&
              Boolean(formik.errors.address?.zipCode)
            }
            helperText={
              formik.touched.address?.zipCode && formik.errors.address?.zipCode
            }
          />
          <TextField
            fullWidth
            id="addressCity"
            name="address.city"
            label="Addres city"
            type="text"
            value={formik.values.address.city}
            onChange={formik.handleChange}
            error={
              formik.touched.address?.city &&
              Boolean(formik.errors.address?.city)
            }
            helperText={
              formik.touched.address?.city && formik.errors.address?.city
            }
          />
          <TextField
            fullWidth
            id="addressRegion"
            name="address.region"
            label="Addres region"
            type="text"
            value={formik.values.address.region}
            onChange={formik.handleChange}
            error={
              formik.touched.address?.region &&
              Boolean(formik.errors.address?.region)
            }
            helperText={
              formik.touched.address?.region && formik.errors.address?.region
            }
          />
          <FormControlLabel
            sx={{
              margin: " 20px auto",
            }}
            control={
              <Switch
                checked={formik.values.isGhost}
                inputProps={{ "aria-label": "controlled" }}
                value={formik.values.isGhost}
                onChange={(e) => {
                  e.target.checked
                    ? formik.setFieldValue("isGhost", true)
                    : formik.setFieldValue("isGhost", false);
                }}
              />
            }
            label="Organization is a ghost organization?"
          />
          <Button
            color="secondary"
            variant="contained"
            fullWidth
            type="submit"
            endIcon={
              isLoading && (
                <IconButton aria-label="" edge="end">
                  <CircularProgress
                    size={15}
                    sx={{ color: "#68F3CB" }}
                    color="success"
                  />
                </IconButton>
              )
            }
          >
            Add Organization
          </Button>
          <ErrorMessageSlide showError={showSlideError} />
        </Stack>

        <Dialog
          open={!!newOrganization}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            setNewOrganization(undefined);
          }}
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{
            sx: { backgroundColor: "#fff", minWidth: "400px" },
          }}
        >
          <DialogTitle variant="h4">
            {"Organization successfully added!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              onClick={() => {
                setNewOrganization(undefined);
              }}
            >
              close
            </Button>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={() => navigate(`/organizations/${newOrganization}`)}
            >
              Go to Organization page
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </>
  );
}

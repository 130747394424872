import * as React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  getCountries,
  ILeague,
  IMatchAdd,
  IOpponent,
  IOrganization,
  ITeam,
} from "utils/api.interfaces";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { Transition } from "utils/common";
import useTeams from "hooks/useTeams";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import "dayjs/locale/nl";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AutocompleteDebounce from "components/AutocompleteDebounce";
import { ErrorMessageSlide } from "../components/ErrorMessageSlide";
import { postMatch } from "../hooks/useMatch";
import useOpponents, { postOpponent } from "../hooks/useOpponents";
import { useLeagues } from "../hooks/useLeague";
import FormControlLabel from "@mui/material/FormControlLabel";
import DialogDeleteActivity from "../components/DialogDeleteActivity";
import DialogAddOpponent from "../components/DialogAddOpponent";
import { useQueryClient } from "@tanstack/react-query";
import Select from "@mui/material/Select";

export default function MatchAdd() {
  const validationSchema = yup.object({
    scheduledAtUtc: yup
      .string()
      .required("A scheduled date & time is required"),
    teamId: yup.string().required("Please select team"),
    opponentId: yup.string().required("Please select opponent team"),
  });
  const [newMatch, setNewMatch] = React.useState(undefined);
  const navigate = useNavigate();
  const [teamFilter, setTeamFilter] = React.useState<string>("");
  const [newOpponentId, setNewOpponentId] = React.useState<string>("");
  const [leagueIdFilter, setLeagueIdFilter] = React.useState<string>("");

  const [team, setTeam] = React.useState<ITeam | undefined>(undefined);
  const [opponent, setOpponent] = React.useState<IOpponent | undefined>(
    undefined
  );

  const queryClient = useQueryClient();
  const { dataLeagues } = useLeagues({
    NameFilter: "",
    CountryCode: "",
    page: "0",
    pageSize: "99",
  });
  const { dataTeams } = useTeams({
    OrganizationIdFilter: "",
    LeagueIdFilter: leagueIdFilter,
    TeamNameFilter: teamFilter,
    page: "0",
    pageSize: "999",
  });
  // console.log(dataTeams);
  const { dataOpponents } = useOpponents(team?.id);
  React.useEffect(() => {
    formik.setFieldValue("opponentId", newOpponentId);
  }, [dataOpponents]);

  React.useEffect(() => {
    queryClient.invalidateQueries(["opponent"]);
  }, [newOpponentId]);

  const [showSlideError, setShowSlideError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showOpponentDialog, setShowOpponentDialog] =
    React.useState<boolean>(false);

  const initMatch: IMatchAdd = {
    scheduledAtUtc: "",
    arrivalTimeUtc: "",
    arrivalLocation: "",
    teamId: "",
    opponentId: "",
    isAway: false,
  };

  const formik = useFormik({
    initialValues: initMatch,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values: IMatchAdd) => {
      if (values.teamId && values.opponentId) {
        setIsLoading(true);
        postMatch(values)
          .then((res) => {
            setNewMatch(res.id);
            setIsLoading(false);
          })
          .catch((e: any) => {
            setShowSlideError(true);
            setIsLoading(false);
          });
      }
    },
  });

  const [dateValue, setDateValue] = React.useState<Dayjs | null>(null);
  const [arrivalDateValue, setArrivalDateValue] = React.useState<Dayjs | null>(
    null
  );

  const handleDateChange = (newValue: Dayjs | null) => {
    setDateValue(newValue);
    formik.setFieldValue("scheduledAtUtc", newValue?.toISOString());
  };
  const handleArrivalDateChange = (newValue: Dayjs | null) => {
    setArrivalDateValue(newValue);
    formik.setFieldValue("arrivalTimeUtc", newValue?.toISOString());
  };
  const handleTeamOneChange = (
    event: React.ChangeEvent<unknown>,
    team: ITeam
  ) => {
    if (team) {
      formik.setFieldValue("teamId", team.id);
      setTeam(team);
    }
  };

  const handleOpponentChange = (
    event: React.ChangeEvent<unknown>,
    opponent: IOpponent
  ) => {
    if (team) {
      formik.setFieldValue("opponentId", opponent.id);
      setOpponent(opponent);
    }
  };

  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        color="inherit"
        noWrap
        gutterBottom
        sx={{
          textAlign: "center",
          margin: "40px 0px",
        }}
      >
        Add new match
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          spacing={3}
          sx={{
            margin: "auto",
            opacity: newMatch || isLoading ? 0.4 : 1,
            pointerEvents: isLoading ? "none" : "auto",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"nl"}>
            <DateTimePicker
              label="Choose scheduled date & time"
              value={dateValue}
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={
                    formik.touched.scheduledAtUtc &&
                    Boolean(formik.errors.scheduledAtUtc)
                  }
                  helperText={
                    formik.touched.scheduledAtUtc &&
                    formik.errors.scheduledAtUtc
                  }
                />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"nl"}>
            <DateTimePicker
              label="Choose Arrival date & time"
              value={arrivalDateValue}
              onChange={handleArrivalDateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={
                    formik.touched.scheduledAtUtc &&
                    Boolean(formik.errors.scheduledAtUtc)
                  }
                  helperText={
                    formik.touched.scheduledAtUtc &&
                    formik.errors.scheduledAtUtc
                  }
                />
              )}
            />
          </LocalizationProvider>
          <TextField
            fullWidth
            id="arrivalLocation"
            name="arrivalLocation"
            label="Arrival Location"
            type="text"
            value={formik.values.arrivalLocation}
            onChange={formik.handleChange}
            error={
              formik.touched.arrivalLocation &&
              Boolean(formik.errors.arrivalLocation)
            }
            helperText={
              formik.touched.arrivalLocation && formik.errors.arrivalLocation
            }
          />

          {dataTeams && (
            <Stack>
              <Card
                sx={{ width: "100%", maxWidth: "850px", margin: "20px auto" }}
              >
                <CardContent>
                  <Stack
                    direction="row"
                    spacing={4}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Stack
                      direction="column"
                      spacing={4}
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <Avatar
                        sx={{
                          width: 124,
                          height: 124,
                        }}
                      >
                        <img
                          src={team?.organization?.logoUrl}
                          style={{ width: 50, height: "auto" }}
                        />
                      </Avatar>

                      <Typography
                        variant="h3"
                        gutterBottom
                        sx={{ minHeight: "30px" }}
                      >
                        {team?.name}
                      </Typography>
                    </Stack>

                    <Typography variant="body1">vs.</Typography>
                    <Stack
                      direction="column"
                      spacing={4}
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <Avatar
                        sx={{
                          width: 124,
                          height: 124,
                        }}
                      >
                        <img
                          src={opponent?.logoUrl}
                          style={{ width: 50, height: "auto" }}
                        />
                      </Avatar>

                      <Typography
                        variant="h3"
                        gutterBottom
                        sx={{ minHeight: "30px" }}
                      >
                        {opponent?.name}
                      </Typography>
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
              <AutocompleteDebounce
                handleChange={(event: any, league: ILeague) => {
                  console.log({ league });
                  setLeagueIdFilter(league.id);
                }}
                data={dataLeagues?.leagues}
                onFilterChange={() => {}}
                labelTitle="Select a league"
              />
              <br></br>
              <Stack
                direction="row"
                spacing={4}
                justifyContent="center"
                alignItems="flex-start"
              >
                <div
                  style={{
                    width: "50%",
                    opacity: leagueIdFilter ? "1" : ".4",
                    pointerEvents: leagueIdFilter ? "auto" : "none",
                  }}
                >
                  <AutocompleteDebounce
                    handleChange={handleTeamOneChange}
                    data={dataTeams.teams}
                    onFilterChange={(value) => setTeamFilter(value)}
                    labelTitle="Select First team"
                    formikErrors={formik.errors.teamId}
                    formikTouched={formik.touched.teamId}
                  />
                  <FormControlLabel
                    sx={{
                      margin: " 20px auto",
                    }}
                    control={
                      <Switch
                        checked={formik.values.isAway}
                        inputProps={{ "aria-label": "controlled" }}
                        value={formik.values.isAway}
                        onChange={(e) => {
                          e.target.checked
                            ? formik.setFieldValue("isAway", true)
                            : formik.setFieldValue("isAway", false);
                        }}
                      />
                    }
                    label="Team is playing away?"
                  />
                </div>
                <div style={{ width: "50%" }}>
                  {dataOpponents?.opponents && team && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label" shrink={true}>
                        Opponent
                      </InputLabel>
                      <Select
                        labelId="opponentId"
                        id="opponentId"
                        name="opponentId"
                        value={formik.values.opponentId}
                        label="opponentId"
                        onChange={formik.handleChange}
                      >
                        {dataOpponents.opponents.map((opponent: IOpponent) => (
                          <MenuItem key={opponent.id} value={opponent.id}>
                            {opponent.organization?.name} - {opponent.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {team && (
                    <>
                      <Button
                        color="secondary"
                        size={"small"}
                        variant="outlined"
                        onClick={() => setShowOpponentDialog(true)}
                        sx={{ marginTop: "20px", marginLeft: "auto" }}
                      >
                        Add new opponent
                      </Button>
                    </>
                  )}
                </div>
              </Stack>
            </Stack>
          )}

          <Button
            color="secondary"
            variant="contained"
            fullWidth
            type="submit"
            endIcon={
              isLoading && (
                <IconButton aria-label="" edge="end">
                  <CircularProgress
                    size={15}
                    sx={{ color: "#68F3CB" }}
                    color="success"
                  />
                </IconButton>
              )
            }
          >
            Add match
          </Button>
          <ErrorMessageSlide showError={showSlideError} />
        </Stack>

        <Dialog
          open={!!newMatch}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            setNewMatch(undefined);
          }}
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{
            sx: { backgroundColor: "#fff", minWidth: "400px" },
          }}
        >
          <DialogTitle variant="h4">{"Match successfully added!"}</DialogTitle>

          <DialogActions>
            <Button
              size="small"
              onClick={() => {
                setNewMatch(undefined);
              }}
            >
              close
            </Button>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={() => navigate(`/match`)}
            >
              Go to matches page
            </Button>
          </DialogActions>
        </Dialog>
      </form>
      <DialogAddOpponent
        team={team}
        leagueId={team?.league?.id ? team?.league?.id : leagueIdFilter}
        isOpen={showOpponentDialog}
        onIsOpenChange={(value) => {
          setShowOpponentDialog(value);
        }}
        onIsOpponentAdded={(val, newOpponentId) => {
          setNewOpponentId(newOpponentId);

          setShowOpponentDialog(false);
        }}
      />
    </>
  );
}

import * as React from "react";
import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Skeleton,
  Stack,
  TableRow,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  IOrganization,
  ISearchMatchesParams,
  ISearchMiniGamesParams,
  ITeam,
  MiniGamesStatusEnum,
} from "utils/api.interfaces";
import { rowsPerpageOptions } from "components/Pagination";
import useFootballMatches from "hooks/useMatches";
import { MatchIcon } from "components/brandlibrary/MingleIcons";
import { StyledTableCell, StyledTableRowNoPointer } from "utils/common";
import DownloadIcon from "@mui/icons-material/Download";
import FilterAndPaging from "components/FilterAndPaging";
import { getVotesExport } from "../hooks/fetch";
import ButtonTeam from "../components/ButtonTeam";
import ButtonOverviewDetailLink from "../components/ButtonOverviewDetailLink";
import ActionMenu from "../components/ActionMenu";
import DialogDeleteMatch from "../components/DialogDeleteMatch";
import { nanoid } from "nanoid";
import FilterOrganization from "../components/FilterOrganization";
import FilterTeam from "../components/FilterTeam";
import dayjs from "dayjs";
import DateFromToFilter from "../components/DateFromToFilter";
import useMiniGames from "../hooks/useMiniGames";
import Select from "@mui/material/Select";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import MiniGameStatus from "../components/MiniGamesStatus";

export default function MiniGames() {
  let [queryParams, setQueryParams] = useSearchParams();

  const initialSearchParams: ISearchMiniGamesParams = {
    page: queryParams.get("page") || "0",
    pageSize: queryParams.get("pageSize") || rowsPerpageOptions[4].toString(),
    status: queryParams.get("status") || "all",
  };
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [clickedMatchId, setClickedMatchId] = React.useState<
    string | undefined
  >("");

  const { isLoading, dataMiniGames } = useMiniGames(searchParams);
  const navigate = useNavigate();
  const handleStatusFilterChangeFilter = (event: SelectChangeEvent) => {
    handleParamsChange({
      status: event.target.value,
      page: "0",
      pageSize: rowsPerpageOptions[2].toString(),
    });
  };
  const handleParamsChange = (updatedParam: any) => {
    console.log(updatedParam);
    const newParams: any = {
      ...Object.fromEntries(new URLSearchParams(queryParams.toString())),
      ...updatedParam,
    };

    setSearchParams(newParams);
  };

  React.useEffect(() => {
    setQueryParams(searchParams);
  }, [searchParams]);
  return (
    <Container maxWidth="lg">
      <Typography
        component="h1"
        variant="h1"
        color="inherit"
        noWrap
        gutterBottom
        sx={{
          textAlign: "center",
          margin: "40px 0px",
        }}
      >
        MiniGames list & filter
      </Typography>

      <FilterAndPaging
        data={dataMiniGames}
        filterPagingParams={initialSearchParams}
        onParamsChange={(e) => {
          handleParamsChange(e);
        }}
        showSearchField={false}
        TableHeaders={
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Type</StyledTableCell>
            <StyledTableCell align="right">Session Id</StyledTableCell>
            <StyledTableCell align="right">Creator</StyledTableCell>
            <StyledTableCell align="right">Date & Time</StyledTableCell>
            <StyledTableCell align="right">participants</StyledTableCell>
            <StyledTableCell align="right">attempts</StyledTableCell>
            <StyledTableCell align="right">Status</StyledTableCell>
          </TableRow>
        }
        filterOptions={
          <>
            <FormControl sx={{ marginLeft: "auto", width: "250px" }}>
              <InputLabel id="demo-simple-select-label" shrink={true}>
                Status
              </InputLabel>
              <Select
                labelId="status"
                id="status"
                name="status"
                defaultValue={initialSearchParams.status}
                label="status"
                onChange={(event: SelectChangeEvent) => {
                  handleStatusFilterChangeFilter(event);
                }}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value={MiniGamesStatusEnum.verified}>
                  {MiniGamesStatusEnum.verified}
                </MenuItem>
                <MenuItem value={MiniGamesStatusEnum.correct}>
                  {MiniGamesStatusEnum.correct}
                </MenuItem>
                <MenuItem value={MiniGamesStatusEnum.error}>
                  {MiniGamesStatusEnum.error}
                </MenuItem>
                <MenuItem value={MiniGamesStatusEnum.disputed}>
                  {MiniGamesStatusEnum.disputed}
                </MenuItem>
              </Select>
            </FormControl>
          </>
        }
      >
        {!isLoading ? (
          <>
            {dataMiniGames?.sessions?.map((row) => (
              <StyledTableRowNoPointer key={nanoid(6)} hover>
                <StyledTableCell align="right">{row.name}</StyledTableCell>
                <StyledTableCell align="right">{row.type}</StyledTableCell>
                <StyledTableCell align="right">
                  <ButtonOverviewDetailLink
                    link={`/minigames/${row.id}`}
                    title={`${row.id}`}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.createdBy.firstName} {row.createdBy.lastName}
                </StyledTableCell>
                <StyledTableCell>
                  {new Date(row.dateTimeUtc).toLocaleDateString([], {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.numberOfParticipants}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.numberOfAttempts}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <MiniGameStatus status={row.status} />
                </StyledTableCell>
              </StyledTableRowNoPointer>
            ))}
          </>
        ) : (
          <StyledTableRowNoPointer key={nanoid(6)} hover>
            <StyledTableCell colSpan={8}>
              <Box>
                {Array(Number(initialSearchParams.pageSize))
                  .fill(1)
                  .map((el, i) => (
                    <Skeleton animation="wave" height={60} key={nanoid(6)} />
                  ))}
              </Box>
            </StyledTableCell>
          </StyledTableRowNoPointer>
        )}
      </FilterAndPaging>
    </Container>
  );
}

import React from "react";
import { useQuery } from "@tanstack/react-query";
import {
  filterMatchParams,
  filterMiniGamesAttemptsParams,
  filterMiniGamesParams,
} from "utils/common";
import {
  IMatchesApiCall,
  IMiniGamesAccuracyParams,
  IMiniGamesApiCall,
  IMiniGamesAttempt,
  IMiniGamesAttemptParams,
  IMiniGamesAttemptsApiCall,
  IOrganizationAdd,
  ISearchMatchesParams,
  ISearchMiniGamesAttemptsParams,
  ISearchMiniGamesParams,
} from "utils/api.interfaces";
import axiosinterceptor from "../utils/api.interceptor";
import {
  GoalVerifyProps,
  NoGoalVerifyProps,
} from "../pages/MiniGamesAttemptVerify";

const fetchMiniGamesAttempt = (
  params: IMiniGamesAttemptParams
): Promise<IMiniGamesAttempt> => {
  return axiosinterceptor
    .get(`/v1/MiniGames/penaltykick/attempt`, {
      params: params,
    })
    .then<IMiniGamesAttempt>((res) => res.data);
};
export default function useMiniGamesAttempt(params: IMiniGamesAttemptParams) {
  const queryMiniGamesAttempt = useQuery(
    ["minigamesAttempt", params],
    () => fetchMiniGamesAttempt(params),
    {
      keepPreviousData: false,
    }
  );

  return {
    ...queryMiniGamesAttempt,
    dataMiniGamesAttempt: queryMiniGamesAttempt.data,
    miniGamesIsLoadingAttempt: queryMiniGamesAttempt.isLoading,
  };
}
export const getAccuracy = (
  positions: IMiniGamesAccuracyParams
): Promise<any> => {
  return axiosinterceptor
    .get(
      `/v1/MiniGames/accuracy?GoalPositionX=${positions.GoalPositionX}&GoalPositionY=${positions.GoalPositionY}`
    )
    .then((res) => {
      return res.data;
    });
};
export const postNoGoal = (
  noGoalVerifyProps: NoGoalVerifyProps
): Promise<any> => {
  return axiosinterceptor
    .put(`/v1/MiniGames/penaltykick/verify-no-goal`, noGoalVerifyProps)
    .then((res) => {
      return res.data;
    });
};
export const putGoalVerify = (
  goalVerifyProps: GoalVerifyProps
): Promise<any> => {
  return axiosinterceptor
    .put(`v1/MiniGames/penaltykick/verify-goal`, goalVerifyProps)
    .then((res) => {
      return res.data;
    });
};

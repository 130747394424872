import * as React from "react";

import { Avatar, Button, Card, Stack, Typography } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CardContent from "@mui/material/CardContent/CardContent";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import EditIcon from "@mui/icons-material/Edit";
import { MatchIcon } from "components/brandlibrary/MingleIcons";
import useMatch from "hooks/useMatch";
import { Delete } from "@mui/icons-material";
import ButtonTeam from "../components/ButtonTeam";
import DialogDeleteMatch from "../components/DialogDeleteMatch";

export default function Match() {
  const [showDeleteDialog, setShowDeleteDialog] =
    React.useState<boolean>(false);
  const navigate = useNavigate();
  const { matchId } = useParams();

  const { dataMatch } = useMatch(matchId);
  const matchfeedURL =
    process.env.REACT_APP_ENV === "PROD"
      ? "https://app.mingle.sport/matchfeed"
      : "https://develop.app-mingle-sport.pages.dev/matchfeed";

  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        color="inherit"
        noWrap
        gutterBottom
        sx={{
          textAlign: "center",
          margin: "40px 0px",
        }}
      >
        Match detail page
      </Typography>
      <Stack
        spacing={3}
        sx={{
          flexDirection: "column",
          maxWidth: "850px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          margin: "auto",
        }}
      >
        <Card sx={{ width: "100%", maxWidth: "850px", margin: "auto" }}>
          <CardContent>
            <Stack
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              {dataMatch && (
                <>
                  <div>
                    <Typography
                      variant="body1"
                      textAlign="center"
                      sx={{ marginBottom: 0 }}
                    >
                      Scheduled
                    </Typography>
                    <Typography
                      variant="h4"
                      textAlign="center"
                      sx={{ marginBottom: 0 }}
                    >
                      {new Date(dataMatch?.scheduledAtUtc).toLocaleDateString(
                        [],
                        {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      )}
                      <br />
                      <strong>
                        {new Date(dataMatch?.scheduledAtUtc).toLocaleTimeString(
                          [],
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}
                      </strong>
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="body1"
                      textAlign="center"
                      sx={{ marginBottom: 0 }}
                    >
                      Arrival
                    </Typography>
                    <Typography
                      variant="h5"
                      textAlign="center"
                      sx={{ marginBottom: 0 }}
                    >
                      {new Date(dataMatch?.arrivalTimeUtc).toLocaleDateString(
                        [],
                        {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      )}
                      <br />
                      <strong>
                        {new Date(dataMatch?.arrivalTimeUtc).toLocaleTimeString(
                          [],
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}
                      </strong>
                      <br />
                      {dataMatch?.arrivalLocation}
                    </Typography>
                  </div>
                </>
              )}
              <Stack
                direction={"row"}
                spacing={4}
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Stack
                  direction={"column"}
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{ minHeight: "30px" }}
                  >
                    {dataMatch?.team?.name}
                  </Typography>
                  <ButtonTeam team={dataMatch?.team} variant="outlined" />
                </Stack>
                <Typography variant="body1">vs.</Typography>

                <Stack
                  direction={"column"}
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{ minHeight: "30px" }}
                  >
                    {dataMatch?.opponent?.name}
                  </Typography>
                  <Button
                    color={"secondary"}
                    sx={{
                      cursor: "pointer",
                      fontSize: "12px",
                      height: "42px",
                      pointerEvents: "none",
                      whiteSpace: "nowrap",
                    }}
                    disabled={true}
                    size="small"
                    startIcon={
                      <Avatar
                        sx={{
                          backgroundColor: "#fff",
                          marginRight: "6px",
                          width: "35px",
                          height: "35px",
                        }}
                        src={dataMatch?.opponent.logoUrl}
                      ></Avatar>
                    }
                    variant="outlined"
                  >
                    {dataMatch?.opponent.name}
                  </Button>

                  {/*<Button*/}
                  {/*  component="a"*/}
                  {/*  target="_blank"*/}
                  {/*  size="small"*/}
                  {/*  variant="text"*/}
                  {/*  color="info"*/}
                  {/*  href={`${matchfeedURL}/${matchId}-${dataMatch?.opponent.id}`}*/}
                  {/*  sx={{ whiteSpace: "nowrap" }}*/}
                  {/*  startIcon={<>⚽</>}*/}
                  {/*  endIcon={<ChevronRightIcon />}*/}
                  {/*>*/}
                  {/*  Matchfeed*/}
                  {/*</Button>*/}
                </Stack>
              </Stack>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ minHeight: "30px" }}
              >
                Match ID: {dataMatch?.id}
              </Typography>
              <Button
                component="a"
                target="_blank"
                size="small"
                variant="text"
                color="info"
                href={`${matchfeedURL}/${matchId}-${dataMatch?.team.id}`}
                sx={{ whiteSpace: "nowrap" }}
                startIcon={<>⚽</>}
                endIcon={<ChevronRightIcon />}
              >
                Matchfeed
              </Button>
            </Stack>
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "space-between",
                justifyContent: "space-between",
                alignContent: "space-between",
                width: "100%",
                maxWidth: "850px",
              }}
            >
              <Button
                component={Link}
                size="small"
                variant="contained"
                color="success"
                to={`/match/add/`}
                startIcon={<MatchIcon />}
                sx={{ whiteSpace: "nowrap" }}
              >
                add new match
              </Button>
              {/*<Button*/}
              {/*  component={Link}*/}
              {/*  size="small"*/}
              {/*  variant="outlined"*/}
              {/*  color="secondary"*/}
              {/*  to={`/match/edit/${dataMatch?.id}`}*/}
              {/*  startIcon={<EditIcon />}*/}
              {/*>*/}
              {/*  edit*/}
              {/*</Button>*/}
            </Stack>
          </CardContent>
        </Card>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "space-between",
            justifyContent: "space-between",
            alignContent: "space-between",
            width: "100%",
            maxWidth: "850px",
          }}
        >
          <Button
            size="small"
            variant="outlined"
            onClick={() => navigate(-1)}
            startIcon={<ChevronLeftIcon />}
          >
            back
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="error"
            onClick={() => {
              setShowDeleteDialog(true);
            }}
            startIcon={<Delete />}
            sx={{ whiteSpace: "nowrap" }}
          >
            delete
          </Button>
        </Stack>

        <DialogDeleteMatch
          id={dataMatch?.id}
          isOpen={showDeleteDialog}
          onIsOpenChange={(value) => {
            setShowDeleteDialog(value);
          }}
        />
      </Stack>
    </>
  );
}

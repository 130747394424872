import React from "react";
import { useQuery } from "@tanstack/react-query";
import {
  filterMatchParams,
  filterMiniGamesAttemptsParams,
  filterMiniGamesParams,
} from "utils/common";
import {
  IMatchesApiCall,
  IMiniGamesApiCall,
  IMiniGamesAttemptsApiCall,
  ISearchMatchesParams,
  ISearchMiniGamesAttemptsParams,
  ISearchMiniGamesParams,
} from "utils/api.interfaces";
import axiosinterceptor from "../utils/api.interceptor";

const fetchMiniGamesAttempts = (
  params: ISearchMiniGamesAttemptsParams
): Promise<IMiniGamesAttemptsApiCall> => {
  return axiosinterceptor
    .get(`/v1/MiniGames/penaltykick/attempts/${params.sessionId}`, {
      params: filterMiniGamesAttemptsParams(params),
    })
    .then<IMiniGamesAttemptsApiCall>((res) => res.data);
};
export default function useMiniGamesAttempts(
  params: ISearchMiniGamesAttemptsParams
) {
  const queryMiniGamesAttempts = useQuery(
    ["minigamesAttempts", params],
    () => fetchMiniGamesAttempts(params),
    {
      keepPreviousData: false,
    }
  );

  return {
    ...queryMiniGamesAttempts,
    dataMiniGamesAttempts: queryMiniGamesAttempts.data,
    miniGamesIsLoadingAttempts: queryMiniGamesAttempts.isLoading,
  };
}

import * as React from "react";
import { useState } from "react";
import {
  IFilterOrganizationParams,
  IFilterPagingParams,
} from "../utils/api.interfaces";
import useOrganizations from "../hooks/useOrganizations";
import AutocompleteDebounce from "./AutocompleteDebounce";
import { rowsPerpageOptions } from "./Pagination";

interface OrganizationFilterProps {
  initSelectedOrganization?: string | null;
  onOrganizationChange: (
    event: React.ChangeEvent<unknown>,
    newOrganization: any
  ) => void;
}
const FilterOrganization: React.FC<OrganizationFilterProps> = ({
  onOrganizationChange,
  initSelectedOrganization,
}) => {
  const initialSearchParams: IFilterOrganizationParams = {
    page: "0",
    NameFilter: "",
    LeagueId: "",
    pageSize: rowsPerpageOptions[4].toString(),
  };
  const [searchParams, setSearchParams] =
    useState<IFilterOrganizationParams>(initialSearchParams);

  const { dataOrganizations } = useOrganizations(searchParams);

  const handleFilterOrganizationChange = (filterValue: string) => {
    const newParams: any = {
      ...initialSearchParams,
      ...{ NameFilter: filterValue },
    };
    setSearchParams(newParams);
  };
  return (
    <div
      style={{
        width: "260px",
        minWidth: "260px",
      }}
    >
      {dataOrganizations && (
        <AutocompleteDebounce
          setDefaultValueWithId={initSelectedOrganization}
          onFilterChange={(val) => {
            handleFilterOrganizationChange(val);
          }}
          handleChange={onOrganizationChange}
          data={dataOrganizations.organizations}
          labelTitle="Organization"
        />
      )}
    </div>
  );
};

export default FilterOrganization;

import * as React from "react";
import { useState } from "react";
import { ISearchTeamsParams } from "../utils/api.interfaces";
import AutocompleteDebounce from "./AutocompleteDebounce";
import { rowsPerpageOptions } from "./Pagination";
import useTeams from "../hooks/useTeams";

interface TeamFilterProps {
  onTeamChange: (event: React.ChangeEvent<unknown>, newteam: any) => void;
  organizationId?: string;
  leagueId?: string;
  initSelectedTeamId?: string | null;
}
const FilterTeam: React.FC<TeamFilterProps> = ({
  onTeamChange,
  organizationId,
  leagueId,
  initSelectedTeamId,
}) => {
  const initialSearchParams: ISearchTeamsParams = {
    page: "0",
    TeamNameFilter: "",
    AgeFilter: "",
    GenderFilter: "",
    OrganizationIdFilter: organizationId || "",
    LeagueIdFilter: leagueId || "",
    pageSize: rowsPerpageOptions[4].toString(),
  };
  const [searchParams, setSearchParams] =
    useState<ISearchTeamsParams>(initialSearchParams);

  const { dataTeams } = useTeams(searchParams);

  const handleFilterTeamChange = (filterValue: string) => {
    const newParams: any = {
      ...initialSearchParams,
      ...{ TeamNameFilter: filterValue },
    };
    setSearchParams(newParams);
  };

  React.useEffect(() => {
    const newParams: any = {
      ...initialSearchParams,
      ...{ OrganizationIdFilter: organizationId },
    };
    setSearchParams(newParams);
  }, [organizationId]);

  return (
    <div
      style={{
        width: "290px",
      }}
    >
      {dataTeams && (
        <AutocompleteDebounce
          setDefaultValueWithId={initSelectedTeamId}
          onFilterChange={(val) => {
            handleFilterTeamChange(val);
          }}
          handleChange={onTeamChange}
          data={dataTeams.teams}
          labelTitle="Team"
        />
      )}
    </div>
  );
};

export default FilterTeam;

import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function BrandTypography() {
  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 8 }}>
        <Typography variant="h1">
          The quick brown fox jumps over the lazy dog
        </Typography>
        <Typography variant="h2">
          The quick brown fox jumps over the lazy dog
        </Typography>
        <Typography variant="h3">
          The quick brown fox jumps over the lazy dog
        </Typography>
        <Typography variant="h4">
          The quick brown fox jumps over the lazy dog
        </Typography>
        <Typography variant="h5">
          The quick brown fox jumps over the lazy dog
        </Typography>
        <Typography variant="h6">
          The quick brown fox jumps over the lazy dog
        </Typography>
        <Typography component="div" variant="body1">
          <Box sx={{ color: "primary.main" }}>primary.main</Box>
          <Box sx={{ color: "secondary.main" }}>secondary.main</Box>
          <Box sx={{ color: "error.dark" }}>error.dark</Box>
          <Box sx={{ color: "error.main" }}>error.main</Box>
          <Box sx={{ color: "error.light" }}>error.main</Box>
          <Box sx={{ color: "warning.main" }}>warning.main</Box>
          <Box sx={{ color: "info.main" }}>info.main</Box>
          <Box sx={{ color: "success.main" }}>success.main</Box>
          <Box sx={{ color: "text.primary" }}>text.primary</Box>
          <Box sx={{ color: "text.secondary" }}>text.secondary</Box>
          <Box sx={{ color: "text.disabled" }}>text.disabled</Box>
        </Typography>
      </Box>
    </Container>
  );
}

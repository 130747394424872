import * as React from "react";

import Typography from "@mui/material/Typography";

import { Slide, Stack } from "@mui/material";

import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

interface Props {
  showError: boolean;
  errorMessage?: string;
}
export const ErrorMessageSlide: React.FC<Props> = ({
  showError,
  errorMessage = "And error occured please try again later or let the FE and/or BE team know about it!",
}) => {
  const [showSlideError, setShowSlideError] = React.useState(false);

  React.useEffect(() => {
    if (showError) {
      setShowSlideError(true);
      const timer = setTimeout(() => {
        setShowSlideError(false);
      }, 18000);
      return () => clearTimeout(timer);
    }
  }, [showError]);

  return (
    <Slide
      direction="up"
      in={showSlideError}
      mountOnEnter
      unmountOnExit
      timeout={{
        appear: 500,
        enter: 300,
        exit: 500,
      }}
    >
      <div
        style={{
          display: "flex",
          textAlign: "center",
          color: "#4B5270",
          background: "#FDE6E6",
          padding: "17px 26px",
          position: "fixed",

          // height: "100px",
          top: "50%",
          left: "50%",
          marginTop: "-50px",
          marginLeft: "-250px",
          borderRadius: "8px",
        }}
      >
        <Typography
          variant="body2"
          component="span"
          sx={{
            color: "#4B5270",
          }}
        >
          {errorMessage}
        </Typography>
        <ErrorOutlineOutlinedIcon
          sx={{ fontSize: "22px", marginLeft: "10px", color: "#E70000" }}
        />
      </div>
    </Slide>
  );
};

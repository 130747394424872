import * as React from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
} from "@mui/material";
import BallotIcon from "@mui/icons-material/Ballot";
import GamesIcon from "@mui/icons-material/Games";
import {
  OrganizationIcon,
  LeagueIcon,
  MatchIcon,
  TeamIcon,
  UserIcon,
} from "../components/brandlibrary/MingleIcons";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function Homepage() {
  const theme = useTheme();

  return (
    <Container maxWidth="lg">
      <Typography
        component="h1"
        variant="h1"
        color="inherit"
        noWrap
        gutterBottom
        sx={{
          textAlign: "center",
          margin: "40px 0px",
        }}
      >
        Dashboard Mingle Manager
      </Typography>
      <Grid container spacing={2} alignContent="center" alignSelf="center">
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <div style={{ textAlign: "center" }}>
                <LeagueIcon sx={{ fontSize: 100, margin: "auto" }} />
              </div>
              <Typography gutterBottom variant="h5" component="div">
                Leagues
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                The list of the leagues! Add, search, edit or delete a league.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="text"
                size="small"
                component={"a"}
                color="secondary"
                href="/leagues"
                endIcon={<ChevronRightIcon />}
              >
                Leagues list
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card>
            <CardContent>
              <div style={{ textAlign: "center" }}>
                <AccountTreeIcon sx={{ fontSize: 100, margin: "auto" }} />
              </div>
              <Typography gutterBottom variant="h5" component="div">
                Organizations
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                The list of the organizations! Add, search, edit an
                organization.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="text"
                size="small"
                component={"a"}
                color="secondary"
                href="/organizations"
                endIcon={<ChevronRightIcon />}
              >
                Organizations list
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <div style={{ textAlign: "center" }}>
                <UserIcon sx={{ fontSize: 100, margin: "auto" }} />
              </div>
              <Typography gutterBottom variant="h5" component="div">
                Users
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                The list of the users! Add, search, edit or delete a user.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="text"
                size="small"
                component={"a"}
                color="secondary"
                href="/users"
                endIcon={<ChevronRightIcon />}
              >
                Users list
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <div style={{ textAlign: "center" }}>
                <TeamIcon sx={{ fontSize: 100, margin: "auto" }} />
              </div>

              <Typography
                gutterBottom
                variant="h5"
                component="div"
              ></Typography>
              <Typography gutterBottom variant="h5" component="div">
                Teams
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                The list of the teams! Add, search, edit or delete a team.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="text"
                size="small"
                component={"a"}
                color="secondary"
                href="/teams"
                endIcon={<ChevronRightIcon />}
              >
                Teams
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <div style={{ textAlign: "center" }}>
                <MatchIcon sx={{ fontSize: 100, margin: "auto" }} />
              </div>
              <Typography gutterBottom variant="h5" component="div">
                Matches
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                The list of the matches! Add, search, edit or delete a match.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="text"
                size="small"
                component={"a"}
                color="secondary"
                href="/match"
                endIcon={<ChevronRightIcon />}
              >
                Matches
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <div style={{ textAlign: "center" }}>
                <BallotIcon sx={{ fontSize: 100, margin: "auto" }} />
              </div>
              <Typography gutterBottom variant="h5" component="div">
                Content Activity
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                The list of the activities! Add, search, edit or delete a card.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="text"
                size="small"
                component={"a"}
                color="secondary"
                href="/activity"
                endIcon={<ChevronRightIcon />}
              >
                Activities list
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <div style={{ textAlign: "center" }}>
                <GamesIcon sx={{ fontSize: 100, margin: "auto" }} />
              </div>
              <Typography gutterBottom variant="h5" component="div">
                Mini Games (Shot Quality)
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                The list of the sessions!
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="text"
                size="small"
                component={"a"}
                color="secondary"
                href="/minigames"
                endIcon={<ChevronRightIcon />}
              >
                MiniGames Session list
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

import * as React from "react";

import {
  Button,
  Card,
  CardActions,
  Stack,
  Switch,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import BallotIcon from "@mui/icons-material/Ballot";
import CardContent from "@mui/material/CardContent/CardContent";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import EditIcon from "@mui/icons-material/Edit";
import useActivity from "hooks/useActivity";
import { Delete } from "@mui/icons-material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import ButtonTeam from "../components/ButtonTeam";
import ButtonOrganization from "../components/ButtonOrganization";
import DialogDeleteActivity from "../components/DialogDeleteActivity";
// @ts-ignore
import JWPlayer from "@jwplayer/jwplayer-react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ITeam, TeamType } from "../utils/api.interfaces";

export default function MingleActivity() {
  const navigate = useNavigate();
  const { activityId } = useParams();
  const { dataActivity } = useActivity(activityId);

  const [showDeleteDialog, setShowDeleteDialog] =
    React.useState<boolean>(false);
  const [showAllTeams, setShowAllTeams] = React.useState<boolean>(false);
  const [showAllOrganizations, setShowAllOrganizations] =
    React.useState<boolean>(false);

  const hasLength = (arr: any[] | undefined): boolean => {
    if (arr === undefined) return false;
    return arr && arr.length !== 0;
  };
  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        color="inherit"
        noWrap
        gutterBottom
        sx={{
          textAlign: "center",
          margin: "40px 0px",
        }}
      >
        Activity detail page
      </Typography>
      <Stack
        spacing={3}
        sx={{
          flexDirection: "column",
          maxWidth: "850px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          margin: "auto",
        }}
      >
        <Card sx={{ width: "100%", maxWidth: "850px", margin: "auto" }}>
          <CardContent>
            <Stack
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              {dataActivity && (
                <>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ minHeight: "30px" }}
                  >
                    Activity ID: {dataActivity?.id}
                  </Typography>
                </>
              )}
              <Stack
                direction={"row"}
                spacing={4}
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <TableContainer>
                  <Table aria-label="user details table">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Created at:
                        </TableCell>
                        <TableCell align="right">
                          {new Date(
                            String(dataActivity?.createdAtUtc)
                          ).toLocaleDateString([], {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Published at:
                        </TableCell>
                        <TableCell align="right">
                          {new Date(
                            String(dataActivity?.publishedAtUtc)
                          ).toLocaleDateString([], {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Updated at:
                        </TableCell>
                        <TableCell align="right">
                          {new Date(
                            String(dataActivity?.updatedAtUtc)
                          ).toLocaleDateString([], {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Organization:
                        </TableCell>
                        <TableCell align="right">
                          <Stack direction="row" spacing={1} flexWrap="wrap">
                            {dataActivity?.organizations.map(
                              (organization, index) => {
                                if (showAllOrganizations) {
                                  return (
                                    <div
                                      style={{ marginBottom: "5px" }}
                                      key={organization?.id + index}
                                    >
                                      <ButtonOrganization
                                        organizationId={organization.id}
                                      />
                                    </div>
                                  );
                                }
                                if (index < 3 && !showAllOrganizations) {
                                  return (
                                    <div
                                      style={{ marginBottom: "5px" }}
                                      key={organization?.id + index}
                                    >
                                      <ButtonOrganization
                                        organizationId={organization.id}
                                      />
                                    </div>
                                  );
                                }
                                return;
                              }
                            )}
                          </Stack>
                          {dataActivity?.organizations.map(
                            (organization, index) => {
                              if (index === 2) {
                                return (
                                  <FormControlLabel
                                    sx={{
                                      margin: " 20px auto",
                                    }}
                                    key={
                                      organization?.id + index + "organization"
                                    }
                                    control={
                                      <Switch
                                        checked={showAllOrganizations}
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                        value={showAllOrganizations}
                                        onChange={(e) => {
                                          e.target.checked
                                            ? setShowAllOrganizations(true)
                                            : setShowAllOrganizations(false);
                                        }}
                                      />
                                    }
                                    label={`Toggle show all ${dataActivity?.organizations.length} organizations`}
                                  />
                                );
                              }
                            }
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        {hasLength(dataActivity?.teams) && (
                          <>
                            <TableCell component="th" scope="row">
                              team:
                            </TableCell>
                            <TableCell align="right">
                              <Stack
                                direction="row"
                                spacing={1}
                                flexWrap="wrap"
                              >
                                {dataActivity?.teams.map((team, index) => {
                                  if (showAllTeams) {
                                    return (
                                      <ButtonTeam
                                        team={team as ITeam}
                                        key={team?.id + index}
                                      />
                                    );
                                  }
                                  if (index < 3 && !showAllTeams) {
                                    return (
                                      <ButtonTeam
                                        team={team as ITeam}
                                        key={team?.id + index}
                                      />
                                    );
                                  }
                                  return;
                                })}
                              </Stack>
                              {dataActivity?.teams.map((teamId, index) => {
                                if (index === 2) {
                                  return (
                                    <FormControlLabel
                                      sx={{
                                        margin: " 20px auto",
                                      }}
                                      key={"team" + index}
                                      control={
                                        <Switch
                                          checked={showAllTeams}
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                          value={showAllTeams}
                                          onChange={(e) => {
                                            e.target.checked
                                              ? setShowAllTeams(true)
                                              : setShowAllTeams(false);
                                          }}
                                        />
                                      }
                                      label={`Toggle show all ${dataActivity?.teams.length} teams`}
                                    />
                                  );
                                }
                              })}
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Is shared with followers:
                        </TableCell>
                        <TableCell align="right">
                          {dataActivity?.shareWithFollowers.toString()}
                        </TableCell>
                      </TableRow>{" "}
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Notification is send:
                        </TableCell>
                        <TableCell align="right">
                          {dataActivity?.sendPublishNotification.toString()}
                        </TableCell>
                      </TableRow>
                      {/*<TableRow>*/}
                      {/*  <TableCell component="th" scope="row">*/}
                      {/*    isRemoved:*/}
                      {/*  </TableCell>*/}
                      {/*  <TableCell align="right">*/}
                      {/*    {dataActivity?.isRemoved.toString()}*/}
                      {/*  </TableCell>*/}
                      {/*</TableRow>*/}
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Is pinned int the feed:
                        </TableCell>
                        <TableCell align="right">
                          {dataActivity?.pinned.toString()}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          publishState:
                        </TableCell>
                        <TableCell align="right">
                          {dataActivity?.publishState.toString()}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
              <Stack direction={"row"} spacing={4} sx={{ width: "100%" }}>
                <TableContainer>
                  <Table aria-label="user details table">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row" colSpan={2}>
                          <Typography variant="h3" gutterBottom>
                            Card NL
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Title
                        </TableCell>
                        <TableCell align="right">
                          {dataActivity?.content.nl.title}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          contentTitle
                        </TableCell>
                        <TableCell align="right">
                          {dataActivity?.content.nl.contentTitle}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          colSpan={2}
                          sx={{ borderBottom: "none" }}
                        >
                          contentBody
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="right"
                          sx={{ whiteSpace: "pre-line" }}
                          colSpan={2}
                        >
                          {dataActivity?.content.nl.contentBody}
                        </TableCell>
                      </TableRow>
                      {dataActivity?.content.nl.button && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            button
                          </TableCell>
                          <TableCell align="right">
                            <Button
                              variant={
                                dataActivity?.content.nl.button?.style ===
                                "primary"
                                  ? "contained"
                                  : "outlined"
                              }
                              sx={{
                                color:
                                  dataActivity?.content.nl.button?.style ===
                                  "primary"
                                    ? "#68F3CB"
                                    : "#0B1ECF",
                                background:
                                  dataActivity?.content.nl.button?.style ===
                                  "primary"
                                    ? "#0B1ECF"
                                    : "#fff",
                                borderColor:
                                  dataActivity?.content.nl.button?.style ===
                                  "primary"
                                    ? "#0B1ECF"
                                    : "#0B1ECF",
                              }}
                              color={
                                dataActivity?.content.nl.button?.style ||
                                "secondary"
                              }
                            >
                              {dataActivity?.content.nl.button?.text}
                            </Button>
                          </TableCell>
                        </TableRow>
                      )}
                      {dataActivity?.content.nl.mediaContent?.imageUrl && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            image
                            <br />
                            {/*{dataActivity?.content.nl.mediaContent?.imageUrl}*/}
                          </TableCell>
                          <TableCell align="right">
                            <img
                              src={
                                dataActivity?.content.nl.mediaContent?.imageUrl
                              }
                              style={{ width: "100%", height: "auto" }}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                      {dataActivity?.content.nl.mediaContent?.videoUrl && (
                        <>
                          <TableRow>
                            <TableCell component="th" scope="row" colSpan={2}>
                              video
                              <br />
                              {/*{dataActivity?.content.nl.mediaContent?.imageUrl}*/}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="right" colSpan={2}>
                              <JWPlayer
                                file={`${dataActivity?.content.nl.mediaContent?.videoUrl}`}
                                library="https://cdn.jwplayer.com/libraries/wyHeWRqG.js"
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer>
                  <Table aria-label="user details table">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row" colSpan={2}>
                          <Typography variant="h3" gutterBottom>
                            {" "}
                            Card EN
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Title
                        </TableCell>
                        <TableCell align="right">
                          {dataActivity?.content.en.title}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          contentTitle
                        </TableCell>
                        <TableCell align="right">
                          {dataActivity?.content.en.contentTitle}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          colSpan={2}
                          sx={{ borderBottom: "none" }}
                        >
                          contentBody
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="right"
                          sx={{ whiteSpace: "pre-line" }}
                          colSpan={2}
                        >
                          {dataActivity?.content.en.contentBody}
                        </TableCell>
                      </TableRow>
                      {dataActivity?.content.en.button && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            button
                          </TableCell>
                          <TableCell align="right">
                            <Button
                              variant={
                                dataActivity?.content.en.button?.style ===
                                "primary"
                                  ? "contained"
                                  : "outlined"
                              }
                              sx={{
                                color:
                                  dataActivity?.content.en.button?.style ===
                                  "primary"
                                    ? "#68F3CB"
                                    : "#0B1ECF",
                                background:
                                  dataActivity?.content.en.button?.style ===
                                  "primary"
                                    ? "#0B1ECF"
                                    : "#fff",
                                borderColor:
                                  dataActivity?.content.en.button?.style ===
                                  "primary"
                                    ? "#0B1ECF"
                                    : "#0B1ECF",
                              }}
                              color={
                                dataActivity?.content.en.button?.style ||
                                "secondary"
                              }
                            >
                              {dataActivity?.content.en.button?.text}
                            </Button>
                          </TableCell>
                        </TableRow>
                      )}
                      {dataActivity?.content.en.mediaContent?.imageUrl && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            image
                            <br />
                            {/*{dataActivity?.content.nl.mediaContent?.imageUrl}*/}
                          </TableCell>
                          <TableCell align="right">
                            <img
                              src={
                                dataActivity?.content.en.mediaContent?.imageUrl
                              }
                              style={{ width: "100%", height: "auto" }}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                      {dataActivity?.content.en.mediaContent?.videoUrl && (
                        <>
                          <TableRow>
                            <TableCell component="th" scope="row" colSpan={2}>
                              video
                              <br />
                              {/*{dataActivity?.content.nl.mediaContent?.imageUrl}*/}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="right" colSpan={2}>
                              <JWPlayer
                                file={`${dataActivity?.content.en.mediaContent?.videoUrl}`}
                                library="https://cdn.jwplayer.com/libraries/wyHeWRqG.js"
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </Stack>
            {/*<ReactJson src={dataActivity || {}} collapsed={true} />*/}
            <Stack
              direction="column"
              spacing={4}
              sx={{ width: "100%" }}
            ></Stack>
          </CardContent>
          <CardActions>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="space-between"
              spacing={2}
              sx={{ width: "100%", padding: 2 }}
            >
              <Button
                component={Link}
                size="small"
                variant="contained"
                color="success"
                to={`/activity/add/`}
                startIcon={<BallotIcon />}
                sx={{ whiteSpace: "nowrap" }}
              >
                add new activity
              </Button>
              <Button
                component={Link}
                size="small"
                variant="outlined"
                color="secondary"
                to={`/activity/edit/${dataActivity?.id}`}
                startIcon={<EditIcon />}
              >
                edit
              </Button>
            </Stack>
          </CardActions>
        </Card>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "space-between",
            justifyContent: "space-between",
            alignContent: "space-between",
            width: "100%",
            maxWidth: "850px",
          }}
        >
          <Button
            size="small"
            variant="outlined"
            onClick={() => navigate(-1)}
            startIcon={<ChevronLeftIcon />}
          >
            back
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="error"
            onClick={() => {
              setShowDeleteDialog(true);
            }}
            startIcon={<Delete />}
            sx={{ whiteSpace: "nowrap" }}
          >
            delete
          </Button>
        </Stack>

        <DialogDeleteActivity
          id={dataActivity?.id}
          isOpen={showDeleteDialog}
          onIsOpenChange={(value) => {
            setShowDeleteDialog(value);
          }}
        />
      </Stack>
    </>
  );
}

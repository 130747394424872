import * as React from "react";
import { useState } from "react";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import {
  IFilterPagingParams,
  ITeamMember,
  IUser,
  ManagementRolesEnum,
  RoleEnum,
} from "utils/api.interfaces";
import {
  Avatar,
  CardActions,
  CardContent,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import FilterListIcon from "@mui/icons-material/FilterList";
import useDebounce from "hooks/useDebounce";
import useUsers from "hooks/useUsers";
import Pagination from "@mui/material/Pagination";
import { nanoid } from "nanoid";

function not(a: ITeamMember[], b: ITeamMember[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: ITeamMember[], b: ITeamMember[] | undefined) {
  return a.filter((value) => b?.indexOf(value) !== -1);
}

function union(a: ITeamMember[], b: ITeamMember[]) {
  return [...a, ...not(b, a)];
}
interface Props {
  initSelectedUsers?: ITeamMember[];
  initUsers?: ITeamMember[];
  selectedUsers: (players: ITeamMember[]) => void;
  selectedTitle: string;
}

const TransferCards: React.FC<Props> = ({
  selectedUsers,
  initUsers,
  initSelectedUsers,
  selectedTitle,
}) => {
  const [leftChecked, setLeftChecked] = React.useState<ITeamMember[]>([]);
  const [rightChecked, setRightChecked] = React.useState<ITeamMember[]>([]);
  const [leftItems, setLeftItems] = React.useState<ITeamMember[] | undefined>(
    []
  );
  const [rightItems, setRightItems] = React.useState<ITeamMember[]>([]);
  const checkLeftChecked = intersection(
    leftChecked,
    initUsers ? initUsers : leftItems
  );
  const checkRightChecked = intersection(rightChecked, rightItems);
  const initialSearchParams: IFilterPagingParams = {
    page: "0",
    filter: "",
    pageSize: "100",
  };

  const [filterInputLoader, setFilterInputLoader] =
    React.useState<Boolean>(false);

  const [filterValue, setFilterValue] = useState<string>(
    initialSearchParams.filter
  );
  const [page, setPage] = React.useState<string>("0");
  const debouncedValue = useDebounce<string>(filterValue, 500);
  const { isLoading, data } = useUsers({
    filter: filterValue,
    page,
    pageSize: initialSearchParams.pageSize,
  });
  React.useEffect(() => {
    setFilterInputLoader(false);
  }, [debouncedValue, page]);

  React.useEffect(() => {
    if (initSelectedUsers) {
      if (initSelectedUsers?.length > 0) {
        setRightItems(initSelectedUsers);
      }
    }
  }, []);
  React.useEffect(() => {
    const filteredUsers = data?.users.filter((user) => {
      return (
        rightItems.filter((rightUser) => {
          return rightUser.id == user.id;
        }).length === 0
      );
    }) as unknown as ITeamMember[];
    filteredUsers?.map((user) => {
      user.role = RoleEnum.Player;
    });
    selectedUsers(rightItems);
    setLeftItems(filteredUsers);
    setLeftChecked([]);
  }, [data?.users, rightItems]);

  const handleToggle = (value: ITeamMember, isLeft: boolean) => () => {
    if (isLeft) {
      const currentIndex = leftChecked.indexOf(value);
      const newChecked = [...leftChecked];
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setLeftChecked(newChecked);
    } else if (!isLeft) {
      const currentIndex = rightChecked.indexOf(value);
      const newChecked = [...rightChecked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setRightChecked(newChecked);
    }
  };

  const numberOfChecked = (items: ITeamMember[], isLeft: boolean) =>
    intersection(isLeft ? leftChecked : rightChecked, items).length;

  const handleToggleAll = (items: ITeamMember[], isLeft: boolean) => () => {
    if (isLeft) {
      if (numberOfChecked(items, isLeft) === items.length) {
        setLeftChecked(not(leftChecked, items));
      } else {
        setLeftChecked(union(leftChecked, items));
      }
    } else {
      if (numberOfChecked(items, isLeft) === items.length) {
        setRightChecked(not(rightChecked, items));
      } else {
        setRightChecked(union(rightChecked, items));
      }
    }
  };

  const addRightItems = () => {
    setRightItems(rightItems.concat(leftChecked));
  };

  const emptyRightItems = () => {
    setRightItems(not(rightItems, rightChecked));
    setRightChecked([]);
  };
  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
    setFilterInputLoader(true);
  };
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    // setFilterValue(value.toString());
    setPage(value.toString());
  };
  const customList = (
    title: React.ReactNode,
    items: ITeamMember[] | undefined,
    isLeft: boolean
  ) => {
    const selected = isLeft
      ? checkLeftChecked.length
      : checkRightChecked.length;
    const total = isLeft
      ? `${leftItems?.length} selected of a total  ${data?.total}`
      : rightItems.length;
    const subHeader = `${selected} / ${total}`;
    return (
      <>
        {items && (
          <Card
            sx={{
              width: "100%",
            }}
          >
            {!initUsers && (
              <>
                <CardHeader
                  sx={{ px: 2, py: 1 }}
                  avatar={
                    <Checkbox
                      onClick={handleToggleAll(items, isLeft)}
                      checked={
                        numberOfChecked(items, isLeft) === items.length &&
                        items.length !== 0
                      }
                      indeterminate={
                        numberOfChecked(items, isLeft) !== items.length &&
                        numberOfChecked(items, isLeft) !== 0
                      }
                      disabled={items.length === 0}
                      inputProps={{
                        "aria-label": "all items selected",
                      }}
                    />
                  }
                  title={title}
                  subheader={subHeader}
                />
                <Divider />
              </>
            )}
            <List
              sx={{
                width: "100%",
                height: 330,
                bgcolor: isLeft ? "background.default" : "background.paper",
                overflow: "auto",
              }}
              dense
              component="div"
              role="list"
            >
              {items.map((member: ITeamMember) => {
                const labelId = `transfer-list-all-item-${member}-label`;
                return (
                  <ListItem
                    key={nanoid(6)}
                    role="listitem"
                    button
                    onClick={handleToggle(member, isLeft)}
                    sx={{
                      pointerEvents: isLeft
                        ? rightItems.filter((rightItem) => {
                            return member.id === rightItem.id;
                          }).length > 0
                          ? "none"
                          : "inherit"
                        : "inherit",
                      opacity: isLeft
                        ? rightItems.filter((rightItem) => {
                            return member.id === rightItem.id;
                          }).length > 0
                          ? ".4"
                          : "1"
                        : "1",
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={
                          isLeft
                            ? leftChecked.indexOf(member) !== -1
                            : rightChecked.indexOf(member) !== -1
                        }
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </ListItemIcon>
                    <Stack
                      spacing={2}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      alignContent="center"
                    >
                      <Avatar
                        alt={`${member.firstName} ${member.lastName}`}
                        src={
                          member.imageUrl
                            ? member.imageUrl
                            : member.profileImageUrl
                        }
                        sx={{ width: 24, height: 24 }}
                      />

                      <ListItemText
                        id={labelId}
                        primary={`${member.firstName} ${member.lastName}`}
                        secondary={`${member.email}`}
                      />
                    </Stack>
                  </ListItem>
                );
              })}
              <ListItem />
            </List>
          </Card>
        )}
      </>
    );
  };

  return (
    <>
      <Card>
        <CardHeader title={`Choose this team's ${selectedTitle}`} />
        <CardContent>
          {initUsers && initUsers.length > 0 ? (
            <></>
          ) : (
            <TextField
              margin="dense"
              id="filter"
              label="Filter users"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeFilter(event);
              }}
              sx={{ width: "40%", padding: "0 0 10px 0" }}
              defaultValue=""
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="" edge="end">
                      {filterInputLoader ? (
                        <CircularProgress size={15} />
                      ) : (
                        <FilterListIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            {customList(
              "Current page selection:",
              initUsers && initUsers.length > 0 ? initUsers : leftItems,
              true
            )}
            <Stack spacing={1} justifyContent="center" alignItems="center">
              <Button
                sx={{ my: 0.5, whiteSpace: "nowrap" }}
                variant="outlined"
                size="small"
                color="secondary"
                fullWidth
                onClick={addRightItems}
                disabled={checkLeftChecked.length === 0}
                aria-label="move selected right"
                endIcon={<ChevronRightIcon />}
              >
                add to list
              </Button>
              <Button
                sx={{ my: 0.5, whiteSpace: "nowrap" }}
                variant="outlined"
                size="small"
                color="error"
                onClick={emptyRightItems}
                disabled={checkRightChecked.length === 0}
                aria-label="move selected left"
                startIcon={<DeleteIcon />}
              >
                remove selected
              </Button>
            </Stack>
            {customList(`Your team ${selectedTitle}`, rightItems, false)}
          </Stack>
        </CardContent>
        {!initUsers && (
          <CardActions sx={{ justifyContent: "center", maxWidth: "40%" }}>
            <Pagination
              count={
                data?.total
                  ? Math.floor(
                      Number(data?.total / Number(initialSearchParams.pageSize))
                    )
                  : 0
              }
              page={Number(page)}
              onChange={handlePageChange}
            />
          </CardActions>
        )}
      </Card>
    </>
  );
};
export default TransferCards;

import { Avatar, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import * as React from "react";
import { useOrganization } from "../hooks/useOrganization";

interface ButtonOrganizationProps {
  organizationId?: string;
}
const ButtonOrganization: React.FC<ButtonOrganizationProps> = ({
  organizationId,
}) => {
  const navigate = useNavigate();

  const { dataOrganization } = useOrganization(organizationId);
  return (
    <Button
      component={Link}
      color={"primary"}
      to={`/organizations/${dataOrganization?.id}`}
      sx={{
        cursor: "pointer",
        fontSize: "12px",
        height: "42px",
        whiteSpace: "nowrap",
      }}
      size="small"
      startIcon={
        <Avatar
          sx={{
            backgroundColor: "#fff",
            marginRight: "6px",
            width: "35px",
            height: "35px",
          }}
          src={dataOrganization?.logoUrl}
        ></Avatar>
      }
      // endIcon={<OrganizationIcon />}
      variant="outlined"
    >
      {dataOrganization?.name}
    </Button>
  );
};

export default ButtonOrganization;

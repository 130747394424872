import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export default function BrandButtons() {
  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 8 }}>
        <Typography variant="h2" component="h2" gutterBottom>
          Button Examples
        </Typography>
        <Link href="https://mui.com/api/button/">
          https://mui.com/api/button/
        </Link>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>text</TableCell>
                <TableCell>medium</TableCell>
                <TableCell>outlined</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Button variant="text">text</Button>
                </TableCell>
                <TableCell>
                  <Button variant="contained">Normal</Button>
                </TableCell>

                <TableCell>
                  <Button variant="outlined">Outlined</Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Button variant="text" color="secondary">
                    text
                  </Button>
                </TableCell>
                <TableCell>
                  <Button variant="contained" color="secondary">
                    Normal
                  </Button>
                </TableCell>
                <TableCell>
                  <Button variant="outlined" color="secondary">
                    Outlined
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Button variant="text" color="error">
                    text
                  </Button>
                </TableCell>
                <TableCell>
                  <Button variant="contained" color="error">
                    Normal
                  </Button>
                </TableCell>
                <TableCell>
                  <Button variant="outlined" color="error">
                    Outlined
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Button variant="text" color="warning">
                    text
                  </Button>
                </TableCell>
                <TableCell>
                  <Button variant="contained" color="warning">
                    Normal
                  </Button>
                </TableCell>
                <TableCell>
                  <Button variant="outlined" color="warning">
                    Outlined
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Button variant="text" color="info">
                    text info
                  </Button>
                </TableCell>
                <TableCell>
                  <Button variant="contained" color="info">
                    Normal info
                  </Button>
                </TableCell>
                <TableCell>
                  <Button variant="outlined" color="info">
                    Outlined
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Button variant="text" color="success">
                    text
                  </Button>
                </TableCell>
                <TableCell>
                  <Button variant="contained" color="success">
                    Normal
                  </Button>
                </TableCell>
                <TableCell>
                  <Button variant="outlined" color="success">
                    Outlined
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ my: 8 }}>
        <Typography variant="h2" component="h2" gutterBottom>
          Button options to set
        </Typography>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Button size="small" color="secondary" variant="contained">
                    small
                  </Button>
                </TableCell>
                <TableCell>
                  <Button size="large" color="secondary" variant="contained">
                    large
                  </Button>
                </TableCell>

                <TableCell>
                  <Button disabled color="secondary" variant="contained">
                    disabled
                  </Button>
                </TableCell>
                <TableCell>
                  <Button fullWidth color="secondary" variant="contained">
                    full width
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    color="secondary"
                    variant="contained"
                    startIcon={<DeleteIcon />}
                  >
                    start icon
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    color="secondary"
                    variant="contained"
                    endIcon={<DeleteIcon />}
                  >
                    end icon
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
}

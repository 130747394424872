import * as React from "react";
import { useState } from "react";

import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Skeleton,
  Stack,
  TableRow,
  TextField,
} from "@mui/material";
import {
  IFilterOrganizationParams,
  IFilterPagingParams,
  ILeague,
  ISearchLeaguesParams,
} from "utils/api.interfaces";
import { rowsPerpageOptions } from "components/Pagination";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useLeague, useLeagues } from "hooks/useLeague";
import { StyledTableCell, StyledTableRowNoPointer } from "utils/common";
import FilterAndPaging from "components/FilterAndPaging";
import { LeagueIcon } from "../components/brandlibrary/MingleIcons";
import ButtonOverviewDetailLink from "../components/ButtonOverviewDetailLink";
import ActionMenu from "../components/ActionMenu";
import { nanoid } from "nanoid";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import FilterListIcon from "@mui/icons-material/FilterList";
import CountryFilter from "../components/FilterCountry";
import { SelectChangeEvent } from "@mui/material/Select";

export default function Leagues() {
  const navigate = useNavigate();

  let [queryParams, setQueryParams] = useSearchParams();
  const initialSearchParams: ISearchLeaguesParams = {
    page: queryParams.get("page") || "0",
    NameFilter: queryParams.get("NameFilter") || "",
    CountryCode: queryParams.get("CountryCode") || "NL",
    pageSize: queryParams.get("pageSize") || rowsPerpageOptions[2].toString(),
  };
  const [searchParams, setSearchParams] =
    useState<ISearchLeaguesParams>(initialSearchParams);

  const { isLoading, dataLeagues } = useLeagues(searchParams);
  const handleLeagueNameFilterChangeFilter = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleParamsChange({
      NameFilter: event.target.value,
      page: "0",
      pageSize: rowsPerpageOptions[2].toString(),
    });
  };
  const handleCountryChange = (event: SelectChangeEvent<string | null>) => {
    event.target.value
      ? handleParamsChange({
          CountryCode: event.target.value,
          page: "0",
          pageSize: rowsPerpageOptions[2].toString(),
        })
      : handleParamsChange({
          CountryCode: "",
          page: "0",
          pageSize: rowsPerpageOptions[2].toString(),
        });
  };
  const handleParamsChange = (updatedParam: any) => {
    const newParams: any = {
      ...Object.fromEntries(new URLSearchParams(queryParams.toString())),
      ...updatedParam,
    };
    setSearchParams(newParams);
  };
  React.useEffect(() => {
    setQueryParams(searchParams);
  }, [searchParams]);
  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        color="inherit"
        noWrap
        gutterBottom
        sx={{
          textAlign: "center",
          margin: "40px 0px",
        }}
      >
        Leagues list & search
      </Typography>
      {!isLoading ? (
        <>
          <Button
            component={Link}
            size="small"
            variant="contained"
            color="success"
            to={`/leagues/add/`}
            startIcon={<LeagueIcon />}
          >
            add new League
          </Button>
          <FilterAndPaging
            data={dataLeagues}
            filterPagingParams={initialSearchParams}
            onParamsChange={handleParamsChange}
            showSearchField={false}
            filterOptions={
              <>
                <TextField
                  margin="dense"
                  id="filter"
                  sx={{
                    minWidth: "300px",
                  }}
                  label="Search team name"
                  variant="standard"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleLeagueNameFilterChangeFilter(event);
                  }}
                  defaultValue={initialSearchParams.NameFilter}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="" edge="end">
                          {isLoading ? (
                            <CircularProgress size={15} />
                          ) : (
                            <FilterListIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <CountryFilter
                  onCountryChange={handleCountryChange}
                  initSelectedCountry={searchParams.CountryCode}
                />
              </>
            }
            TableHeaders={
              <TableRow>
                <StyledTableCell colSpan={8}>League</StyledTableCell>
                <StyledTableCell align="right">Action</StyledTableCell>
              </TableRow>
            }
          >
            {dataLeagues?.leagues?.map((row) => (
              <StyledTableRowNoPointer key={nanoid(6)} hover>
                <StyledTableCell colSpan={8}>
                  <Stack
                    direction="row"
                    alignContent="center"
                    justifyContent="flex-start"
                    alignItems="center"
                    gap={4}
                  >
                    <Avatar
                      sx={{
                        backgroundColor: "#fff",
                        marginRight: "6px",
                        width: "60px",
                        height: "60px",
                      }}
                      src={row.logoUrl}
                    ></Avatar>
                    <div>
                      <ButtonOverviewDetailLink
                        link={`/leagues/${row.id}`}
                        title={row.name}
                      />
                    </div>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <ActionMenu
                    itemId={row?.id}
                    handleNewClick={(e) => {
                      navigate(`/leagues/add/`);
                    }}
                    handleEditClick={(e, id) => {
                      navigate(`/leagues/edit/${id}`);
                    }}
                    showDeleteButton={false}
                  />
                </StyledTableCell>
              </StyledTableRowNoPointer>
            ))}
          </FilterAndPaging>
        </>
      ) : (
        <Box sx={{ marginTop: "70px" }}>
          {Array(Number(initialSearchParams.pageSize))
            .fill(1)
            .map((el, i) => (
              <Skeleton animation="wave" height={60} key={nanoid(6)} />
            ))}
        </Box>
      )}
    </>
  );
}

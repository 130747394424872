import * as React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DateFromToFilter from "./DateFromToFilter";
import dayjs, { Dayjs } from "dayjs";
import { rowsPerpageOptions } from "./Pagination";
import DateFromTo from "./DateFromToFilter";
import { ISeason } from "../utils/api.interfaces";
interface SeasonFieldsProps {
  initValues?: ISeason;
  onFieldChanges: (
    name: string,
    startsAtUtc: string,
    endsAtUtc: string
  ) => void;
  onFieldSubmit: (name: string, startsAtUtc: string, endsAtUtc: string) => void;
}

const SeasonFieldsNew: React.FC<SeasonFieldsProps> = ({
  initValues,
  onFieldChanges,
  onFieldSubmit,
}) => {
  const theme = useTheme();

  const [seasonName, setSeasonName] = React.useState<string>(
    initValues?.name || ""
  );
  const [dateFromValue, setDateFromValue] = React.useState<Dayjs>(
    dayjs(initValues?.startsAtUtc)
      .set("hour", 13)
      .set("minute", 0)
      .set("second", 0)
  );
  const [dateToValue, setDateToValue] = React.useState<Dayjs>(
    dayjs(initValues?.endsAtUtc)
      .set("hour", 12)
      .set("minute", 59)
      .set("second", 59)
  );
  const [showFields, setShowFields] = React.useState<boolean>(false);
  React.useEffect(() => {
    onFieldChanges(
      seasonName,
      dateFromValue?.toISOString(),
      dateToValue?.toISOString()
    );
  }, [seasonName, dateFromValue, dateToValue]);

  return (
    <>
      {showFields ? (
        <>
          <div
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              background: "rgba(0, 0, 0, 0.1)",
              pointerEvents: "auto",
              top: 0,
              left: 0,
              zIndex: 1000,
            }}
          ></div>
          <Stack
            direction="column"
            alignContent="center"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            style={{
              backgroundColor: "#ffffff",
              padding: 18,
              borderRadius: "8px",
              zIndex: 1001,
            }}
          >
            <Button
              size="small"
              onClick={() => {
                setShowFields(false);
              }}
              sx={{ marginLeft: "auto" }}
            >
              x
            </Button>
            <TextField
              fullWidth
              id="name"
              name="name"
              label="Name"
              type="text"
              defaultValue={seasonName}
              onChange={(e) => setSeasonName(e.currentTarget.value)}
            />
            <Stack
              direction="row"
              alignContent="center"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{
                width: "100%",

                margin: "auto",
              }}
            >
              <DateFromToFilter
                dateFrom={dayjs(dayjs().subtract(1, "week").toISOString())}
                dateTo={dayjs(dayjs().add(1, "week").toISOString())}
                onDateFromToChange={(dateFromValue, dateToValue) => {
                  setDateFromValue(dateFromValue || dayjs());
                  setDateToValue(dateToValue || dayjs());
                }}
              />
              <Button
                color="success"
                variant="contained"
                size={"small"}
                onClick={() => {
                  onFieldSubmit(
                    seasonName,
                    dateFromValue?.toISOString(),
                    dateToValue?.toISOString()
                  );
                  setShowFields(false);
                }}
              >
                add
              </Button>
            </Stack>
          </Stack>
        </>
      ) : (
        <Button
          color="secondary"
          variant="outlined"
          size={"small"}
          onClick={() => {
            setShowFields(true);
          }}
        >
          add season
        </Button>
      )}
    </>
  );
};
export default SeasonFieldsNew;

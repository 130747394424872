import React from "react";
import { useQuery } from "@tanstack/react-query";
import { IOpponentAdd, IOpponents, ITeam } from "utils/api.interfaces";
import axiosinterceptor from "../utils/api.interceptor";
import { nanoid } from "nanoid";

export const fetchOpponents = (
  teamId: string | undefined
): Promise<IOpponents> =>
  // axiosinterceptor.get(`v1/Teams/${teamId}`).then<ITeam>((res) => res.data);

  axiosinterceptor
    .get(`/v1/FootballMatches/football/opponents/${teamId}`)
    .then<IOpponents>((res) => res.data);

export default function useOpponents(teamId: string | undefined) {
  const queryOpponent = useQuery(
    ["opponent", teamId],
    () => fetchOpponents(teamId),
    {
      enabled: !!teamId,
    }
  );
  return {
    ...queryOpponent,
    dataOpponents: queryOpponent?.data,
    dataOpponentsIsLoading: queryOpponent?.isLoading,
  };
}
export const postOpponent = (opponent: IOpponentAdd): Promise<any> => {
  const opponentId = nanoid();
  return axiosinterceptor
    .post(`/v1/FootballMatches/football/opponents`, {
      ...opponent,
      id: opponentId,
    })
    .then((res) => {
      return opponentId;
    });
};

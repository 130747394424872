import { Avatar, Button, CircularProgress, IconButton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import * as React from "react";
import { useOrganization } from "../hooks/useOrganization";
import { useLeague } from "../hooks/useLeague";
import { useFormik } from "formik";
import {
  putArchiveTeam,
  putBulkArchiveTeam,
  putUnArchiveTeam,
} from "../hooks/useTeam";
import { ITeam } from "utils/api.interfaces";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import ArchiveIcon from "@mui/icons-material/Archive";

interface ButtonBulkArchiveProps {
  seasonId: string;
}
const ButtonBulkArchiveTeam: React.FC<ButtonBulkArchiveProps> = ({
  seasonId,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState<Boolean>(false);
  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    onSubmit: (values: any) => {
      if (
        !confirm(
          "Are you sure you want to bulk archive all teams for this season? This cannot be undone."
        )
      ) {
        return;
      }
      setIsLoading(true);
      putBulkArchiveTeam(seasonId)
        .then((res) => {
          setIsLoading(false);
          setIsLoading(false);
        })
        .catch((e: any) => {
          setIsLoading(false);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Button
        color="error"
        variant="outlined"
        size="small"
        type="submit"
        startIcon={<ArchiveIcon />}
        sx={{ fontSize: "12px", padding: "5px 10px" }}
        endIcon={
          isLoading && (
            <IconButton aria-label="" edge="end">
              <CircularProgress
                size={15}
                sx={{ color: "#68F3CB" }}
                color="success"
              />
            </IconButton>
          )
        }
      >
        Bulk archive teams
      </Button>
    </form>
  );
};

export default ButtonBulkArchiveTeam;

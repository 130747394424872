import * as React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import {
  ITeamAdd,
  ITeamMember,
  ManagementRolesEnum,
  RoleEnum,
} from "utils/api.interfaces";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import TransferCards from "components/TransferCards";

import { Transition } from "utils/common";

import useOrganizations from "hooks/useOrganizations";
import AutocompleteDebounce from "components/AutocompleteDebounce";

import { ErrorMessageSlide } from "../components/ErrorMessageSlide";
import { postTeam, postTeamImage } from "../hooks/useTeam";
import FormControlLabel from "@mui/material/FormControlLabel";
import UploadImage from "../components/UploadImage";
import { postLeague, postLeagueImage, useLeagues } from "../hooks/useLeague";
import CardHeader from "@mui/material/CardHeader";
import Radio from "@mui/material/Radio";
import ListItemText from "@mui/material/ListItemText";

export default function TeamAdd() {
  const navigate = useNavigate();
  const [acceptedFiles, setAcceptedFiles] = React.useState<File[]>([]);
  const [newTeam, setNewTeam] = React.useState(undefined);
  const [showSlideError, setShowSlideError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<Boolean>(false);
  const [newMembers, setNewMembers] = React.useState<ITeamMember[]>([]);

  const initTeam: ITeamAdd = {
    sportType: "Football",
    name: "",
    nameAbbreviation: "",
    organizationId: "",
    leagueId: "",
    isGhost: false,
    isHistoric: false,
    members: [],
  };

  const validationSchema = yup.object({
    sportType: yup.string().required("Sport type is required"),
    organizationId: yup.string().required("Organization is required"),
  });

  const [leagueFilter, setLeagueFilter] = React.useState<string>("");
  const [adminId, setAdminId] = React.useState<string>("");

  const { dataLeagues } = useLeagues({
    NameFilter: leagueFilter,
    CountryCode: "",
    page: "0",
    pageSize: "99",
  });
  const handleLeagueChange = (
    event: React.ChangeEvent<unknown>,
    value: any
  ) => {
    formik.setFieldValue("leagueId", value.id);
    setLeagueIdFilter(value.id);
  };

  const [organizationFilter, setOrganizationFilter] =
    React.useState<string>("");

  const [leagueIdFilter, setLeagueIdFilter] = React.useState<string>("");

  const { dataOrganizations } = useOrganizations({
    NameFilter: organizationFilter,
    LeagueId: leagueIdFilter,
    page: "0",
    pageSize: "99",
  });
  const handleOrganizationChange = (
    event: React.ChangeEvent<unknown>,
    value: any
  ) => {
    console.log(value);
    formik.setFieldValue("organizationId", value.id);
  };
  const formik = useFormik({
    initialValues: initTeam,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values: ITeamAdd) => {
      setIsLoading(true);
      if (acceptedFiles && acceptedFiles?.length > 0) {
        postTeamImage(acceptedFiles)
          .then((res) => {
            postTeam({
              ...values,
              logoUrl: res.fileUrl,
            })
              .then((res) => {
                setIsLoading(false);
                setNewTeam(res.id);
              })
              .catch((e: any) => {
                setShowSlideError(true);
                setIsLoading(false);
              });
          })
          .catch((e: any) => {
            setShowSlideError(true);
            setIsLoading(false);
          });
      } else {
        postTeam({
          ...values,
        })
          .then((res) => {
            setIsLoading(false);
            setNewTeam(res.id);
          })
          .catch((e: any) => {
            setShowSlideError(true);
            setIsLoading(false);
          });
      }
    },
  });

  React.useEffect(() => {
    console.log({ adminId });
    formik.setFieldValue("adminId", adminId);
  }, [adminId]);

  React.useEffect(() => {
    console.log({ newMembers });
    formik.setFieldValue(
      "members",
      newMembers.map((m) => {
        return { id: m.id, role: m.role };
      })
    );
  }, [newMembers]);

  // const setSelectedMemberRoles = (
  //   members: ITeamMember[],
  //   roleType: RoleEnum
  // ) => {
  //   const newMembersUpdate = members.map((member) => {
  //     return { id: member.id, role: roleType };
  //   });
  //   // console.log({ newMembers });
  //   // console.log({ newMembersUpdate });
  //   setNewMembers((newMembers) => [...newMembers, ...newMembersUpdate]);
  // };

  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        color="inherit"
        noWrap
        gutterBottom
        sx={{
          textAlign: "center",
          margin: "40px 0px",
        }}
      >
        Add new team
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          spacing={3}
          sx={{
            margin: "auto",
            opacity: newTeam || isLoading ? 0.4 : 1,
            pointerEvents: isLoading ? "none" : "auto",
          }}
        >
          <UploadImage
            maxFiles={1}
            onAcceptedFilesChange={(files: File[]) => {
              setAcceptedFiles(files);
            }}
          />
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            fullWidth
            id="nameAbbreviation"
            name="nameAbbreviation"
            label="Abbreviation"
            type="text"
            value={formik.values.nameAbbreviation}
            onChange={formik.handleChange}
            error={
              formik.touched.nameAbbreviation &&
              Boolean(formik.errors.nameAbbreviation)
            }
            helperText={
              formik.touched.nameAbbreviation && formik.errors.nameAbbreviation
            }
          />
          <FormControlLabel
            sx={{
              margin: " 20px auto",
            }}
            control={
              <Switch
                checked={formik.values.isGhost}
                inputProps={{ "aria-label": "controlled" }}
                value={formik.values.isGhost}
                onChange={(e) => {
                  e.target.checked
                    ? formik.setFieldValue("isGhost", true)
                    : formik.setFieldValue("isGhost", false);
                }}
              />
            }
            label="Team is a ghost team"
          />
          <FormControlLabel
            sx={{
              margin: " 20px auto",
            }}
            control={
              <Switch
                checked={formik.values.isHistoric}
                inputProps={{ "aria-label": "controlled" }}
                value={formik.values.isHistoric}
                onChange={(e) => {
                  e.target.checked
                    ? formik.setFieldValue("isHistoric", true)
                    : formik.setFieldValue("isHistoric", false);
                }}
              />
            }
            label="Team is a Historic team"
          />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" shrink={true}>
              SportType
            </InputLabel>
            <Select
              labelId="sportType"
              id="sportType"
              value={formik.values.sportType}
              label="sportType"
              onChange={formik.handleChange}
            >
              <MenuItem value="Football">Football</MenuItem>
            </Select>
          </FormControl>
          {dataLeagues && (
            <>
              <AutocompleteDebounce
                handleChange={handleLeagueChange}
                data={dataLeagues.leagues}
                onFilterChange={(value) => setLeagueFilter(value)}
                labelTitle="Select a league"
                formikErrors={formik.errors.leagueId}
                formikTouched={formik.touched.leagueId}
              />
            </>
          )}
          {dataOrganizations && leagueIdFilter !== "" && (
            <>
              <AutocompleteDebounce
                handleChange={handleOrganizationChange}
                data={dataOrganizations.organizations}
                onFilterChange={(value) => setOrganizationFilter(value)}
                labelTitle="Select an organization"
                formikErrors={formik.errors.organizationId}
                formikTouched={formik.touched.organizationId}
              />
              {/*{Boolean(formik.errors.organizationId) && formik.submitCount > 0 && (*/}
              {/*  <div*/}
              {/*    style={{*/}
              {/*      color: "#E70000",*/}
              {/*      margin: "3px 14px 0 14px",*/}
              {/*      fontSize: "1rem",*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    {formik.errors.organizationId}*/}
              {/*  </div>*/}
              {/*)}*/}
            </>
          )}

          <TransferCards
            initSelectedUsers={newMembers}
            selectedTitle="members"
            selectedUsers={(members) => {
              setNewMembers(members);
            }}
          />
          {/*<TransferCards*/}
          {/*  initSelectedUsers={newMembers}*/}
          {/*  selectedTitle="trainers"*/}
          {/*  selectedUsers={(members) =>*/}
          {/*    setSelectedMemberRoles(members, RoleEnum.Trainer)*/}
          {/*  }*/}
          {/*/>*/}
          {/*<TransferCards*/}
          {/*  initSelectedUsers={newMembers}*/}
          {/*  selectedTitle="supporters"*/}
          {/*  selectedUsers={(members) =>*/}
          {/*    setSelectedMemberRoles(members, RoleEnum.Support)*/}
          {/*  }*/}
          {/*/>*/}
          <Card>
            <CardHeader title={`Set the Roles of the members`} />
            <CardContent>
              <Stack
                spacing={2}
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                alignContent="center"
              >
                {newMembers?.map((member) => {
                  return (
                    <Stack
                      spacing={2}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="space-between"
                      alignContent="space-between"
                      sx={{ width: "100%" }}
                      key={member.id}
                    >
                      <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        sx={{ width: "320px", cursor: "pointer" }}
                        onClick={() =>
                          navigate(
                            !member.id.includes("pre", 0)
                              ? `/users/${member.id} `
                              : `/users/pre/${member.id} `
                          )
                        }
                      >
                        <Avatar
                          alt={`${member.firstName} ${member.lastName}`}
                          src={
                            member.imageUrl
                              ? member.imageUrl
                              : member.profileImageUrl
                          }
                          sx={{ width: 24, height: 24 }}
                        />

                        <ListItemText
                          primary={`${member.firstName} ${member.lastName}`}
                          secondary={`${member.email}`}
                        />
                      </Stack>
                      <RadioGroup
                        row
                        aria-labelledby=""
                        name={`isAdmin`}
                        value={adminId}
                        onChange={(e) => {
                          setAdminId(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          value={member.id}
                          control={<Radio />}
                          label="Is Admin"
                        />
                      </RadioGroup>
                      <RadioGroup
                        row
                        aria-labelledby=""
                        name={`role-${member.id}`}
                        value={member.role as RoleEnum}
                        onChange={(e) => {
                          setNewMembers(
                            newMembers?.map((m) => {
                              if (m.id === member.id) {
                                m.role = e.target.value as RoleEnum;
                              }
                              return m;
                            })
                          );
                        }}
                      >
                        <FormControlLabel
                          value={RoleEnum.Player}
                          control={<Radio />}
                          label="Player"
                        />
                        <FormControlLabel
                          value={RoleEnum.Trainer}
                          control={<Radio />}
                          label="trainer"
                        />
                        <FormControlLabel
                          value={RoleEnum.Support}
                          control={<Radio />}
                          label="support"
                        />
                      </RadioGroup>
                    </Stack>
                  );
                })}
              </Stack>
            </CardContent>
          </Card>
          <Button
            color="secondary"
            variant="contained"
            fullWidth
            type="submit"
            endIcon={
              isLoading && (
                <IconButton aria-label="" edge="end">
                  <CircularProgress
                    size={15}
                    sx={{ color: "#68F3CB" }}
                    color="success"
                  />
                </IconButton>
              )
            }
          >
            Add team
          </Button>
          <ErrorMessageSlide showError={showSlideError} />
        </Stack>

        <Dialog
          open={!!newTeam}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            setNewTeam(undefined);
          }}
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{
            sx: { backgroundColor: "#fff", minWidth: "400px" },
          }}
        >
          <DialogTitle variant="h4">{"Team successfully added!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              onClick={() => {
                setNewTeam(undefined);
              }}
            >
              close
            </Button>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={() => navigate(`/teams/${newTeam}`)}
            >
              Go to team page
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </>
  );
}

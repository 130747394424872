import * as React from "react";
import { useState } from "react";
import {
  IFilterPagingParams,
  ISearchLeaguesParams,
} from "../utils/api.interfaces";
import useOrganizations from "../hooks/useOrganizations";
import AutocompleteDebounce from "./AutocompleteDebounce";
import { rowsPerpageOptions } from "./Pagination";
import { useLeagues } from "../hooks/useLeague";

interface LeagueFilterProps {
  initSelectedLeague?: string | null;
  onLeagueChange: (event: React.ChangeEvent<unknown>, newLeague: any) => void;
}
const FilterLeague: React.FC<LeagueFilterProps> = ({
  onLeagueChange,
  initSelectedLeague,
}) => {
  const initialSearchParams: ISearchLeaguesParams = {
    page: "0",
    NameFilter: "",
    CountryCode: "",
    pageSize: rowsPerpageOptions[4].toString(),
  };
  const [searchParams, setSearchParams] =
    useState<ISearchLeaguesParams>(initialSearchParams);

  const { dataLeagues } = useLeagues(searchParams);

  const handleFilterLeagueChange = (filterValue: string) => {
    const newParams: any = {
      ...initialSearchParams,
      ...{ NameFilter: filterValue },
    };
    setSearchParams(newParams);
  };
  return (
    <div
      style={{
        width: "260px",
        minWidth: "260px",
      }}
    >
      {dataLeagues && (
        <AutocompleteDebounce
          setDefaultValueWithId={initSelectedLeague}
          onFilterChange={(val) => {
            handleFilterLeagueChange(val);
          }}
          handleChange={onLeagueChange}
          data={dataLeagues.leagues}
          labelTitle="League"
        />
      )}
    </div>
  );
};

export default FilterLeague;

import * as React from "react";
import { Outlet } from "react-router-dom";
import { Container } from "@mui/material";

export default function MingleActivityContainer() {
  return (
    <Container maxWidth="lg">
      <Outlet />
    </Container>
  );
}
